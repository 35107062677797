const uiSchema = {
    'ui:order': [
      'code',
      'desc',
      'validEntities',
      'prodTypeIncl',
      'prodNameIncl',
      'prodSubTypeIncl',
      'prodGroupIncl',
      'prodBkIncl',

      'operRoleIncl',
      'operRoleExcl',
      'operIncl',
      'operExcl',

      'trnRoleIncl',
      'trnRoleExcl',
      'trnCodeIncl',
      'trnCodeExcl',

      '*',
      'networkReturnCodes',
    ],
    prodNameIncl: {
      'ui:field': 'fxtMultiSelectField',
      options: {
        apis: [
          {
            api: '/model/prodType',
            keyObject: 'prodType',
            valObject: 'prodType',
          },
        ],
      },
    },
    prodTypeIncl: {
      'ui:field': 'fxtMultiSelectField',
      options: {
        apis: [
          {
            api: '/model/prodType',
            keyObject: 'prodType',
            valObject: 'prodType',
          },
        ],
      },
    },
    prodSubTypeIncl: {
      'ui:field': 'fxtMultiSelectField',
      options: {
        apis: [
          {
            api: '/model/prodSubType',
            keyObject: 'prodSubType',
            valObject: 'prodSubType',
            distinct: true,
          },
        ],
      },
    },
    prodGroupIncl: {
      'ui:field': 'fxtMultiSelectField',
      options: {
        apis: [
          {
            api: '/model/prodGroup',
            keyObject: 'prodGroup',
            valObject: 'prodGroup',
            distinct: true,
          },
        ],
      },
    },
    prodBkIncl: {
      'ui:field': 'fxtMultiSelectField',
      options: {
        apis: [
          {
            api: '/model/prod_bk',
            keyObject: 'name',
            valObject: 'name',
          },
        ],
      },
    },
    operRoleIncl: {
      'ui:field': 'fxtMultiSelectField',
      options: {
        apis: [
          {
            api: '/model/role',
            keyObject: 'userRole',
            valObject: 'desc',
          },
        ],
        includeKeyInDesc: true,
        listBox: true,
      },
    },
    operRoleExcl: {
      'ui:field': 'fxtMultiSelectField',
      options: {
        apis: [
          {
            api: '/model/role',
            keyObject: 'userRole',
            valObject: 'desc',
          },
        ],
        includeKeyInDesc: true,
        listBox: true,
      },
    },
    operIncl: {
      'ui:field': 'fxtMultiSelectField',
      options: {
        apis: [
          {
            api: '/system/operations',
            keyObject: 'name',
            valObject: 'name',
            responsePath: 'operations',
            distinct: true,
          },
        ],
        listBox: true,
      },
    },
    operExcl: {
      'ui:field': 'fxtMultiSelectField',
      options: {
        apis: [
          {
            api: '/system/operations',
            keyObject: 'name',
            valObject: 'name',
            responsePath: 'operations',
            distinct: true,
          },
        ],
        listBox: true,
      },
    },
    trnRoleIncl: {
      'ui:field': 'fxtMultiSelectField',
      options: {
        apis: [
          {
            api: '/model/role',
            keyObject: 'userRole',
            valObject: 'desc',
          },
        ],
        includeKeyInDesc: true,
        listBox: true,
      },
    },
    trnRoleExcl: {
      'ui:field': 'fxtMultiSelectField',
      options: {
        apis: [
          {
            api: '/model/role',
            keyObject: 'userRole',
            valObject: 'desc',
          },
        ],
        includeKeyInDesc: true,
        listBox: true,
      },
    },
    trnCodeIncl: {
      'ui:field': 'fxtMultiSelectField',
      options: {
        apis: [
          {
            api: '/model/trnCode',
            keyObject: 'trnCode',
            valObject: 'desc',
          },
        ],
        includeKeyInDesc: true,
        listBox: true,
      },
    },
    trnCodeExcl: {
      'ui:field': 'fxtMultiSelectField',
      options: {
        apis: [
          {
            api: '/model/trnCode',
            keyObject: 'trnCode',
            valObject: 'desc',
          },
        ],
        includeKeyInDesc: true,
        listBox: true,
      },
    },
    networkReturnCodes: {
      'ui:field': 'fxtPatternPropField',
      options: {
        apis: [
          {
            api: '/model/network',
            keyObject: 'network',
            valObject: 'network',
          },
        ],
      },
    },
    validEntities: {
      'ui:field': 'fxtMultiSelectField',
    },
  }

export default uiSchema