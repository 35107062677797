import React, { useEffect, useRef, useState } from "react";
import {
  CurrentRefinements,
  useRefinementList,
  Stats,
} from "react-instantsearch";
import css from "./SearchFilters.module.scss";
import { ALGOLIA_SLUGS } from "../../../constants";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { capitalize } from "lodash-es";
import cx from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import { faChevronUp } from "@fortawesome/pro-regular-svg-icons";

type Props = {
  contextSlug?: string;
};

const SearchFilters = ({ contextSlug }: Props) => {
  const [filterOnMount, setFilterOnMountState] = useState(true);
  const [refinementFilterState, toggleRefinementFilter] = useState(false);
  const { items, refine } = useRefinementList({
    attribute: "slug",
    sortBy: ["count:desc"],
  });

  useEffect(() => {
    if (filterOnMount && items?.length && contextSlug) {
      const filterItems = items.filter((item) => item.value === contextSlug);
      if (filterItems.length) {
        refine(contextSlug);
      }
      setFilterOnMountState(false);
    }
  }, [items, contextSlug]);

  return (
    <div className={css["filters"]}>
      <div className={css["results-count"]}>
        <Stats
          translations={{
            rootElementText({ nbHits }) {
              return `${nbHits.toLocaleString()} results`;
            },
          }}
        />
      </div>

      <div className={css["refinements-container"]}>
        <CurrentRefinements />

        {/* Desktop refinement list */}
        <OverlayTrigger
          rootClose
          trigger="click"
          placement="bottom-end"
          onToggle={(nextShow) => toggleRefinementFilter(nextShow)}
          overlay={(props) => (
            <Tooltip
              className={css["filter-overlay"]}
              {...props}
              arrowProps={{
                style: { display: "none" },
              }}
            >
              <CustomRefinements items={items} refine={refine} />
            </Tooltip>
          )}
        >
          <button
            className={cx({
              [css["filter-by-btn"]]: true,
              [css["open"]]: refinementFilterState,
            })}
          >
            Filter by
            <FontAwesomeIcon
              icon={refinementFilterState ? faChevronUp : faChevronDown}
            />
          </button>
        </OverlayTrigger>

        {/* Mobile refinement list */}
        <div className={css["mobile-refinements"]}>
          <span>Filter By</span>
          <CustomRefinements
            items={items.filter((item) =>
              [ALGOLIA_SLUGS.docs, ALGOLIA_SLUGS.apis].includes(item.value)
            )}
            refine={refine}
          />
        </div>
      </div>
    </div>
  );
};

const CustomRefinements = ({
  items,
  refine,
}: {
  items: ReturnType<typeof useRefinementList>["items"];
  refine: ReturnType<typeof useRefinementList>["refine"];
}) => {
  return (
    <ul className={css["refinements-list"]}>
      {items.map((item) => (
        <li key={item.label} className={css["list-item"]}>
          <label className={css["list-item-label"]}>
            <div className={css["label-container"]}>
              <input
                type="checkbox"
                className={css["checkbox"]}
                checked={item.isRefined}
                onChange={() => refine(item.value)}
              />
              <span className={css["label"]}>
                {item.label === ALGOLIA_SLUGS.apis
                  ? "APIs"
                  : capitalize(item.label)}
              </span>
            </div>
            <span className={css["count"]}>{item.count}</span>
          </label>
        </li>
      ))}
    </ul>
  );
};

export default SearchFilters;
