import React from "react";
import { produce } from "immer";

export type NavState = {
  openGroups: string[]; // E.g. ['architect', 'products']
};

export type Action =
  | {
      type: "TOGGLE_APPEARANCE";
    }
  | {
      type: "TOGGLE_GROUP";
      id: string;
      tier: 1 | 2 | 3 | 4;
    }
  | {
      type: "CLOSE_ALL_GROUPS";
    }
  | {
      type: "SET_GROUPS";
      openGroups: string[];
    };

export function reducer(state: NavState, action: Action): NavState {
  const nextState = produce(state, (draftState) => {
    switch (action.type) {
      // Open or close a navigation group
      case "TOGGLE_GROUP": {
        // There are groups in tier 1 and tier 2, ensure array long enough
        if (draftState.openGroups.length < 2) {
          draftState.openGroups = ["", ""];
        }

        if (draftState.openGroups[action.tier - 1] === action.id) {
          // Close if open
          draftState.openGroups[action.tier - 1] = "";
        } else {
          // Open if closed
          draftState.openGroups[action.tier - 1] = action.id;
        }
        // Anytime tier 1 changes, close tier 2
        if (action.tier === 1) {
          draftState.openGroups[1] = "";
        }

        return draftState;
      }

      // Sets the open groups, eg: set the selected group on page refresh
      case "SET_GROUPS": {
        if (action.openGroups?.length) {
          draftState.openGroups =
            action.openGroups?.length < 2
              ? [...action.openGroups, ""]
              : action.openGroups;
        }
        return draftState;
      }

      // Close all navigation groups
      case "CLOSE_ALL_GROUPS": {
        draftState.openGroups = [];
        return draftState;
      }
    }
  });

  return nextState;
}
