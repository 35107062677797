import {
  faCircle,
  faSparkles,
  faPaperPlaneTop,
  faChevronDown,
  faThumbsDown,
  faThumbsUp,
  faArrowDownLong,
} from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import axios from "axios"
import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
} from "react"
import {
  Accordion,
  Button,
  Form,
  InputGroup,
  ListGroup,
  ToggleButton,
  ToggleButtonGroup,
} from "react-bootstrap"
import { v4 as uuidv4 } from "uuid"
import Markdown, { defaultUrlTransform } from "react-markdown"
import { Light as SyntaxHighlighter } from "react-syntax-highlighter"
import { agate } from "react-syntax-highlighter/dist/esm/styles/hljs"
import styles from "./ChatWindow.module.scss"
import javascript from "react-syntax-highlighter/dist/esm/languages/hljs/javascript"
import json from "react-syntax-highlighter/dist/esm/languages/hljs/json"
import python from "react-syntax-highlighter/dist/esm/languages/hljs/python"
import go from "react-syntax-highlighter/dist/esm/languages/hljs/go"
import cx from "classnames"
import { findLast, flatten, throttle } from "lodash-es"
import { IconCallout } from "@/assets/icons"
import colors from "../../../styles/color.module.scss"
import { constants } from "@/constants"
import { SSE } from "sse.js"

SyntaxHighlighter.registerLanguage("javascript", javascript)
SyntaxHighlighter.registerLanguage("json", json)
SyntaxHighlighter.registerLanguage("python", python)
SyntaxHighlighter.registerLanguage("go", go)

interface FileCitation {
  file_id: string
  title: string
  doc_type: string
}

interface Annotation {
  text?: string
  type: string
  value: string
  file_citation?: FileCitation
}

interface MessageContent {
  text: string
  annotations: Annotation[]
}

export interface Message {
  sender: string
  content: MessageContent[]
  msgId?: string
  forMsg?: string
}

interface ChatWindowProps {
  messages: Message[]
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>
}

interface LastAnnotation {
  baseUrl: string
  title: string
  file_id: string
}

type DocTypes = {
  [key: string]: string | null
}

type SseEvents = {
  data: string;
}

const docTypes: DocTypes = {
  training_document: "/docs/document-detail/training/",
  document: "/docs/document-detail/document/",
  document_center: "/docs/document-detail/document/",
  pattern: "/docs/tutorials/detail-page/",
  code_walkthrough: null,
  api_libray: "/apis/detail-page/api_library/",
  rfp: "rfp",
  swagger: "swagger"
}

const RATINGS = {
  thumbsUp: 1,
  thumbsDown: 0,
}

// Global constant to toggle streaming
const USE_STREAMING = true

export default function ChatWindow({ messages, setMessages }: ChatWindowProps) {
  const [input, setInput] = useState("")
  const [isTyping, setIsTyping] = useState(false)
  const [ratings, setRatings] = useState<{ [key: number]: boolean | null }>({})
  const [commentVisible, setCommentVisible] = useState<{
    [key: number]: boolean
  }>({})
  const [comments, setComments] = useState<{ [key: number]: string }>({})
  const inputRef = useRef<HTMLTextAreaElement>(null)
  const conversationId = useRef<string>(uuidv4())
  const sseRef = useRef<SSE | null>(null)
  const messagesEndRef = useRef<HTMLDivElement>(null)
  const chatBoxRef = useRef<HTMLDivElement>(null)
  const [isScrollAtBottom, setIsBottom] = useState(false);
  const aiTerms = "AI is experimental technology. Finxact Pro is still learning and we encourage you to verify responses. Responses may not to be used to reflect any offer, agreement, or commercial terms between Finxact and you. This chat may be recorded. For more info, see our"

  useEffect(() => {
    if (!isTyping || !messages.length) {
      inputRef.current?.focus()
    }
  }, [isTyping, messages])

  useEffect(() => {
    return () => {
      sseRef.current?.close()
    }
  }, [])

  const scrollToBottom = useMemo(
    () =>
      throttle(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
      }, 1000),
    []
  )

  const handleSend = useCallback(async () => {
    if (input.trim()) {
      const userMessage: Message = {
        sender: "user",
        content: [{ text: input, annotations: [] }],
      }
      setMessages((prevMessages) => [...prevMessages, userMessage])
      setInput("")
      setIsTyping(true)
      setTimeout(() => scrollToBottom() ,1000)

      if (USE_STREAMING) {
        if (sseRef.current) {
          sseRef.current.close()
        }

        const sse = new SSE("/console/chat", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          payload: JSON.stringify({
            prompt: input,
            conversationId: conversationId.current,
            stream: true,
          }),
          start: true,
        })

        sseRef.current = sse

        let assistantMessage: Message | null = null

        sse.addEventListener("thread.message.delta", (e: SseEvents) => {
          const data = JSON.parse(e.data)
          if (!assistantMessage) {
            assistantMessage = {
              sender: "assistant",
              content: [],
              msgId: data.msg_id,
            }
            setMessages((prevMessages) => [...prevMessages, assistantMessage!])
          }

          const annotations = [...assistantMessage.content.map((a: any) => a.annotations).filter((z: any) => z !== undefined).flat(), ...data.content.map((a: any) => a.annotations).filter((z: any) => z !== undefined)]

          const txtContent = assistantMessage!.content.map((c: any) => c.text).join("") + data.content.map((c: any) => c.text?.value || "").join("")
          const newContent = {
            text: txtContent.replace(/【\d+:?\d*†source】/g, ""),
            annotations: annotations,
          }
          assistantMessage!.content = [newContent]

          setMessages((prevMessages) => {
            const updated = [...prevMessages]
            updated[updated.length - 1] = assistantMessage!
            return updated
          })

          handleScroll()
        })

        sse.addEventListener("thread.message.created", (e: SseEvents) => {
          const data = JSON.parse(e.data)
          assistantMessage = {
            sender: data.role,
            content: [],
            msgId: data.msg_id,
          }
          setMessages((prevMessages) => [...prevMessages, assistantMessage!])
        })

        sse.addEventListener("thread.message.completed", (e: SseEvents) => {
          const data = JSON.parse(e.data)
          if (assistantMessage) {
            assistantMessage.content = data.content.map((c: any) => ({ annotations: c.text.annotations, text: c.text.value }))

            assistantMessage.sender = "assistant"
          }
        })

        sse.addEventListener("thread.message.cancelled", () => {
          setMessages((prevMessages) => {
            const newMsg: Message = {
              sender: "system",
              content: [{ text: "The response has been cancelled.", annotations: [] }],
            }
            return [...prevMessages, newMsg]
          })
          setIsTyping(false)
          sse.close()
        })

        sse.addEventListener("completed", () => {
          setIsTyping(false)
          sse.close()
        })

        sse.onerror = (e) => {
          if (e.data === "TIMEOUT") {
            console.error("chatbot stream timeout")

            setMessages((prevMessages) => {
              const newMsg: Message = {
                sender: "system",
                content: [{ text: "The response has timed out.", annotations: [] }],
              }
              return [...prevMessages, newMsg]
            })
          }
          console.error("SSE connection error")
          setIsTyping(false)
          sse.close()
        }

        sse.onabort = () => {
          setIsTyping(false)
          sse.close()
        }

        sse.onreadystatechange = (e) => {
          if (e.readyState === 2) {
            setIsTyping(false)
            sse.close()
          }
        }

        sse.stream()
      } else {
        try {
          const response = await axios.post(
            "/console/chat",
            {
              prompt: input,
              conversationId: conversationId.current,
              stream: false,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )

          const assistantMessages = response.data.messages.map((msg: any) => {
            const content = msg.content.map((c: any) => ({
              text: c.text?.value || "",
              annotations: c.text?.annotations || [],
            }))
            return { sender: "assistant", content, msgId: msg.msg_id }
          })

          setMessages((prevMessages) => [...prevMessages, ...assistantMessages])
        } catch (error) {
          console.error("Error fetching the assistant reply:", error)
        } finally {
          setIsTyping(false)
        }
      }
    }
  }, [input, setMessages])

  const handleCancelConversation = useCallback(async () => {
    try {
      await axios.post(
        "/console/chat/cancel/" + conversationId.current,
        {
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    } catch (e) {
    }
  }, [])

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (event.key === "Enter" && !event.shiftKey && !isTyping) {
        event.preventDefault()
        handleSend()
      } else if (event.key === "Escape" && !event.shiftKey && isTyping) {
        event.preventDefault()
        handleCancelConversation()
      }
    },
    [handleSend, handleCancelConversation, isTyping]
  )

  const handleRatingSave = useCallback(
    async (index: number, isThumbsUp?: true) => {
      try {
        const msgId = messages[index]?.msgId

        await axios.post(
          "/console/rating",
          {
            msgId,
            conversationId: conversationId.current,
            rating: isThumbsUp != null ? isThumbsUp : ratings[index],
            comments: isThumbsUp ? "" : comments[index] ?? "",
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        setCommentVisible((prevMap) => ({ ...prevMap, [index]: false }))
        setMessages((prevMessages) => {
          const msgIdx = prevMessages.findIndex(
            (msg) => msg.forMsg === msgId
          )
          const newMsg: Message = {
            forMsg: msgId,
            sender: "system",
            content: [{ text: "Thank you for your feedback!", annotations: [] }],
          }
          if (msgIdx > -1) {
            return prevMessages
          }
          const updatedMessages = [...prevMessages]
          updatedMessages.splice(index + 1, 0, newMsg)
          return updatedMessages
        })
      } catch (error) {
        console.error("Error saving the rating:", error)
      }
    },
    [messages, ratings, comments, setMessages]
  )

  const handleRatingChange = (index: number) => (value: number) => {
    // Save rating if user clicks thumbs up
    if (value === RATINGS.thumbsUp) {
      handleRatingSave(index, true)
      setCommentVisible((prevMap) => ({ ...prevMap, [index]: false }))
    } else {
      setCommentVisible((prevMap) => ({ ...prevMap, [index]: true }))
    }
    setRatings((prevRatings) => ({
      ...prevRatings,
      [index]: Boolean(value),
    }))
  }

  const removeAnnotations = useCallback((mc: MessageContent): string => {
    return mc.text.replace(/【\d+:?\d*†source】/g, "")
  }, [])

  // remove basepath and construct relative path
  function stripPathName(url: string) {
    //sanitize the url
    const sanitizedUrl = defaultUrlTransform(url);
    const parsedUrl = URL.parse(sanitizedUrl);
    return parsedUrl?.pathname || "";
  }

  const components = useMemo(
    () => ({
      code({ node, inline, className, children, ...props }: any) {
        const match = /language-(\w+)/.exec(className || "")
        return !inline && match ? (
          <div className={styles.syntaxHighlighter}>
            <SyntaxHighlighter
              style={{
                ...agate,
                hljs: {
                  ...agate.hljs,
                  background: colors.synHlBg,
                },
                "hljs-attr": {
                  color: colors.synHlAttr,
                },
                "hljs-string": {
                  color: colors.synHlString,
                },
                "hljs-number": {
                  color: colors.synHlLiteral,
                },
                "hljs-literal": {
                  color: colors.synHlLiteral,
                },
              }}
              language={match[1]}
              showLineNumbers
              {...props}
            >
              {String(children).replace(/\n$/, "")}
            </SyntaxHighlighter>
          </div>
        ) : (
          <code className={className} {...props}>
            {children}
          </code>
        )
      },
      a(props: any) {
        return (
          <a href={props.href} target="_blank">
            {props.children}
          </a>
        );
      },
    }),
    []
  )

  const formatMessage = useCallback(
    (message: Message, index: number) => {
      const { sender, content, msgId } = message
      const isUserMessage = sender === "user"
      const isSystemMessage = sender === "system"

      return (
        <>
          <div
            className={cx({
              [styles.chatMessage]: true,
              [styles.userMessage]: isUserMessage,
              [styles.systemMessage]: isSystemMessage,
              [styles.assistantMessage]: !isSystemMessage && !isUserMessage,
            })}
          >
            <div className={styles.messageBubble}>
              <Markdown components={components} urlTransform={stripPathName}>
                {content.map((c) => removeAnnotations(c)).join("")}
              </Markdown>
            </div>
          </div>
          {!isUserMessage && !isSystemMessage && msgId && (
            <div className={styles.ratingSection}>
              <div className={styles.ratingBtnContainer}>
                <span className={styles.text}>
                  Was this answer helpful?
                </span>
                <ToggleButtonGroup
                  name="ratings-btn-grp"
                  type="radio"
                  style={{ position: "relative" }}
                  value={ratings[index]}
                  onChange={handleRatingChange(index)}
                >
                  <ToggleButton
                    id={`thumbs-up-${index}`}
                    value={RATINGS.thumbsUp}
                    className={cx({
                      [styles.ratingBtn]: true,
                      [styles.active]:
                        ratings[index] === Boolean(RATINGS.thumbsUp),
                    })}
                  >
                    <FontAwesomeIcon icon={faThumbsUp} />
                  </ToggleButton>
                  <ToggleButton
                    id={`thumbs-down-${index}`}
                    value={RATINGS.thumbsDown}
                    className={cx({
                      [styles.ratingBtn]: true,
                      [styles.active]:
                        ratings[index] === Boolean(RATINGS.thumbsDown),
                    })}
                  >
                    <FontAwesomeIcon icon={faThumbsDown} />
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
              {commentVisible[index] && (
                <>
                  <div className={styles.commentDesc}>
                    Why did you choose this rating? Your input will help us
                    improve Pro.
                  </div>
                  <Form.Control
                    as="textarea"
                    className={styles.comments}
                    onChange={(e) =>
                      setComments((prevComments) => ({
                        ...prevComments,
                        [index]: e.target.value,
                      }))
                    }
                    placeholder="Provide additional feedback"
                    rows={2}
                    value={comments[index] || ""}
                  />
                  <div className={styles.feedbackActions}>
                    <Button
                      onClick={() => {
                        setComments((prevComments) => ({
                          ...prevComments,
                          [index]: "",
                        }))
                      }}
                      className={cx(styles.btn, styles.cancel)}
                    >
                      Clear
                    </Button>
                    <Button
                      disabled={comments[index] == null}
                      onClick={() => handleRatingSave(index)}
                      className={cx(styles.btn, styles.submit)}
                    >
                      Save
                    </Button>
                  </div>
                </>
              )}
            </div>
          )}
        </>
      )
    },
    [
      components,
      ratings,
      comments,
      commentVisible,
      removeAnnotations,
      handleRatingSave,
      handleRatingChange,
    ]
  )

  const memoizedMessages = useMemo(() => {
    return messages.map((message, index) => ({
      message,
      index,
      formatted: formatMessage(message, index),
    }))
  }, [messages, formatMessage])

  const lastAnnotationDetails = useMemo(() => {
    const displayedSources = new Set<string>()
    const lastAssistantMsg = findLast(memoizedMessages, ({ message }) => {
      return message.sender === "assistant"
    })
    return flatten(
      lastAssistantMsg?.message.content.map((content) => {
        return content.annotations
          .map((annotation) => {
            if (
              annotation.type === "file_citation" &&
              annotation.file_citation
            ) {
              const { file_id, title, doc_type } = annotation.file_citation
              const baseUrl = docTypes[doc_type]
              if (
                baseUrl &&
                !displayedSources.has(file_id) &&
                !/^\d+\.\d+-/.test(file_id)
              ) {
                displayedSources.add(file_id)
                return {
                  baseUrl,
                  file_id,
                  title,
                }
              }
            }
            return null
          })
          .filter(Boolean) as LastAnnotation[]
      })
    )
  }, [memoizedMessages])

  const handleClearConversation = useCallback(() => {
    setMessages([])
    setComments({})
    setCommentVisible({})
    setRatings({})
    setInput("")
    conversationId.current = uuidv4()
    sseRef.current?.close()
  }, [setMessages])

     // Check if the user is at the bottom
  const handleScroll = () => {
    const chatBox = chatBoxRef.current;
    if (!chatBox) return;
    const computedScrollHeight = chatBox.scrollHeight - chatBox.scrollTop
    const absDiff = computedScrollHeight - chatBox.clientHeight;
    setIsBottom(absDiff < 10);
  };

  useEffect(() => {
    const chatBox = chatBoxRef.current;
    chatBox?.addEventListener("scroll", handleScroll);

    return () => {
      chatBox?.removeEventListener("scroll", handleScroll);
    };
  }, [messages]);

   useEffect(() => {
     if (!isTyping || !messages.length) {
       inputRef.current?.focus()
     }
   }, [isTyping, messages])

   useEffect(() => {
     return () => {
       sseRef.current?.close()
       scrollToBottom.cancel() // Cancel throttled function on unmount
     }
   }, [scrollToBottom])

  return (
    <div className={styles.chatWindow}>
      <div ref={chatBoxRef} className={styles.chatBodyContainer}>
        {memoizedMessages.length ? (
          <ListGroup className={styles.chatBody} variant='flush'>
            {memoizedMessages.map(({ index, formatted }) => (
              <ListGroup.Item className={styles.chatMessage} key={index}>
                {formatted}
              </ListGroup.Item>
            ))}

            {isTyping && (
              <ListGroup.Item
                className={`${styles.chatMessage} ${styles.typingIndicator}`}
              >
                <FontAwesomeIcon className={styles.typingDot} icon={faCircle} />
                <FontAwesomeIcon className={styles.typingDot} icon={faCircle} />
                <FontAwesomeIcon className={styles.typingDot} icon={faCircle} />
              </ListGroup.Item>
            )}
          </ListGroup>
        ) : null}
        <div ref={messagesEndRef}></div>
      </div>

      <div className='position-relative'>
        {memoizedMessages.length > 1 && !isScrollAtBottom ? (
          <div className={styles.scrollDownBtnContainer}>
            <Button onClick={scrollToBottom} className={`btn btn-secondary`}>
              <FontAwesomeIcon icon={faArrowDownLong} />
            </Button>
          </div>
        ) : (
          ''
        )}

        {!isTyping && (
          <InputGroup
            className={cx({
              [styles.inputGroup]: true,
              [styles.followUp]: memoizedMessages.length > 0,
            })}
          >
            <InputGroup.Text className={styles.sparkleIcon}>
              <FontAwesomeIcon icon={faSparkles} />
            </InputGroup.Text>
            <Form.Control
              as='textarea'
              className={styles.inputField}
              disabled={isTyping}
              onChange={(e) => setInput(e.target.value)}
              onKeyDown={handleKeyDown}
              placeholder={
                memoizedMessages.length
                  ? 'Ask Pro a follow up question'
                  : 'How could Finxact Pro help you today?'
              }
              ref={inputRef}
              rows={1}
              value={input}
            />
            <InputGroup.Text className={styles.sendBtnContainer}>
              <Button
                className={styles.sendButton}
                disabled={isTyping || !input}
                onClick={handleSend}
                variant='light'
              >
                <FontAwesomeIcon icon={faPaperPlaneTop} />
              </Button>
            </InputGroup.Text>
          </InputGroup>
        )}

        {!memoizedMessages.length ? (
          <>
            <div className={styles.description}>
              <FontAwesomeIcon icon={faSparkles} className={styles.icon} />
              <span className={styles.text}>
                Pro is your AI-powered assistant to enhance your Finxact
                experience with real-time responses, guidance, and insights.
              </span>
            </div>
            <div className={styles.disclaimer}>
              <div>
                Check-out our
                <a
                  className={styles.chatLinks}
                  href={constants.finxactProQSG}
                  rel='noopener noreferrer'
                  target='_blank'
                >
                  Quick Start Guide
                </a>
                 to help you maximize Pro’s capabilities!
              </div>
              <div className={styles.disclaimerBorder}></div>
              <div>
                {aiTerms}
                <a
                  className={styles.chatLinks}
                  href={constants.privacyPolicy}
                  rel='noopener noreferrer'
                  target='_blank'
                >
                  Privacy Policy
                </a>
              </div>
            </div>
          </>
        ) : null}

        {lastAnnotationDetails.length > 0 && !isTyping && (
          <Accordion className={styles.summary}>
            <Accordion.Item eventKey='1'>
              <Accordion.Header as='h6' className={styles.header}>
                <span>Source content</span>
                <FontAwesomeIcon
                  className={styles.chevron}
                  icon={faChevronDown}
                />
              </Accordion.Header>
              <Accordion.Body>
                <div className={styles.annotationContainer}>
                  {lastAnnotationDetails.map(({ baseUrl, file_id, title }) => (
                    <div key={file_id} className={styles.annotation}>
                      {baseUrl === docTypes.rfp ||
                      baseUrl === docTypes.swagger ? (
                        title
                      ) : (
                        <a
                          href={`${baseUrl}${file_id}/`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {title}
                        </a>
                      )}
                    </div>
                  ))}
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        )}

        {memoizedMessages.length > 1 && (
          <div className={styles.callout}>
            <div>
              <IconCallout />
            </div>
            <span>
              {aiTerms}
              <a 
                className={styles.chatLinks} 
                href={constants.privacyPolicy} 
                rel='noopener noreferrer'
                target='_blank'>
                Privacy Policy
              </a>
            </span>
          </div>
        )}
        {memoizedMessages.length > 1 && (
          <div
            className={`${styles.actionBtnContainer} ${isTyping ? 'justify-content-between' : 'justify-content-end '}`}
          >
            {isTyping ? (
              <Button
                className={`${styles.cancelButton}`}
                disabled={!isTyping}
                onClick={handleCancelConversation}
                variant='link'
              >
                Stop Generating
              </Button>
            ) : (
              ''
            )}
            <Button
              className={`${styles.newConvoBtn}`}
              disabled={isTyping}
              onClick={handleClearConversation}
              variant='outline-secondary'
            >
              Start a new conversation
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}
