import React from "react";
import { useHits } from "react-instantsearch";
import { checkIfRestricted, constructURL } from "../../../utils";
import css from "./Hits.module.scss";
import cx from "classnames";
import { SiteFunction } from "./Search";

type Props = {
  hit: ReturnType<typeof useHits>["items"][number];
  siteFunction: SiteFunction;
  isAuthenticated: boolean;
};

export function Hit(props: Props) {
  const { hit, isAuthenticated, siteFunction } = props;
  const url = constructURL(props.hit.type, props.hit.uid, props.hit.url);

  return (
    <>
      {!isAuthenticated &&
      props?.hit?.tags &&
      checkIfRestricted(props.hit?.tags) ? (
        ""
      ) : (
        <div
          className={cx({
            [css["result"]]: true,
            [css["topmenu"]]: props.siteFunction !== "global",
            [css["mobile"]]: props.siteFunction === "mobile",
          })}
        >
          <div className={css["result-detail"]}>
            <a href={url}>
              <span className={css["title"]}>
                {props.hit.title || "No title found"}
              </span>
            </a>
            <div className={css["description"]}>
              {props.hit.description && props.siteFunction === "global"
                ? props.hit.description
                : props.hit.description && props.hit.description.length > 125
                  ? `${props.hit.description.slice(0, 125)}...`
                  : props.hit.description || "No description found"}
            </div>
          </div>
          <div className={css["result-module"]}>{props.hit.slug}</div>
        </div>
      )}
    </>
  );
}

export function CustomHit(props: {
  slug: string | null;
  siteFunction: SiteFunction;
  isAuthenticated: boolean;
}) {
  const { items } = useHits();

  const displayedHits =
    props.siteFunction !== "global" ? items.slice(0, 5) : items;

  return displayedHits.map((hit) => (
    <Hit
      key={hit.objectID}
      siteFunction={props.siteFunction}
      hit={hit}
      isAuthenticated={props.isAuthenticated}
    />
  ));
}
