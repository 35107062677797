import React from 'react'
import clsn from 'classnames'
import { useEffect, useState } from 'react'
import { Button, ButtonProps, Spinner, SpinnerProps } from 'react-bootstrap'

type Props = ButtonProps & {
  label: string | JSX.Element | null
  loading: boolean
  spinner?: SpinnerProps
}

export default function SpinnerButton({
  label,
  loading,
  spinner,
  disabled,
  ...props
}: Props) {
  const [showLoader, setShowLoader] = useState(false)
  // https://humble.dev/creating-a-nice-loading-button-with-react-hooks
  useEffect(() => {
    if (loading) {
      setShowLoader(true)
    }

    // Show loader a bits longer to avoid loading flash
    if (!loading && showLoader) {
      const timeout = setTimeout(() => {
        setShowLoader(false)
      }, 400)

      // Don’t forget to clear the timeout
      return () => {
        clearTimeout(timeout)
      }
    }
  }, [loading, showLoader])

  return (
    <Button
      disabled={
        /** If disabled isn't set default to disabled during `loading` */
        disabled || loading
      }
      {...props}
    >
      {label}
      {!showLoader ? null : (
        <Spinner
          animation='border'
          className={clsn({ 'ms-2': label })}
          size='sm'
          {...spinner}
        />
      )}
    </Button>
  )
}
