import React from "react";
import s from './DesktopOverlay.module.scss'
import { faDisplay } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { paths } from "../../constants";

export function DesktopOverlay(){
    return (
    <div className={s.desktopRequiredOverlay}>
        <div className={s.desktopRequiredContent}>
            <FontAwesomeIcon
                className={s.desktopRequiredImage}
                icon={faDisplay}
            />
            <h4 className={s.desktopRequiredTitle}>Desktop Recommended</h4>
            <p className={s.desktopRequiredDescription}>
                The Dashboard experience has been optimized for web. To fully
                explore this experience, we recommend accessing it through a
                desktop.
            </p>
            <div>
                <button
                onClick={() => {
                    window.location.href = paths.docs
                }}
                >
                Go to Docs
                </button>
            </div>

            <div>
                <button
                onClick={() => {
                    window.location.href = paths.apis
                }}
                >
                Go to API References
                </button>
            </div>
        </div>
    </div>)
}