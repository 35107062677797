import semver from 'semver'

/**
 * Check if core server's version satisfies the semantic versioning criteria passed in.
 * NOTE: Does not fetch the core server version if it is empty, and relies on it being fetched at app launch or on environment switch.
 *
 * Used to reduce duplication of logic and importing of semver dependency everywhere
 * @author Austin Fletcher
 *
 * @param critera a semantic versioning string eg '> 1.1.5' etc
 * @return whether or not the criteria is met
 *
 */
export function coreServerVersionSatisfies(
    criteria: string | semver.Range,
    coreVersion?: string): boolean {
        
    if (!coreVersion) {
      return false
    }
  
    return semver.satisfies(coreVersion, criteria)
  }