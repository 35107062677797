
  import labels from './foundational-setup-routes-labels.json'
  import type { Route } from '../routes/index'

  export const foundationalSetupRoutes: Record<string, Route> = {
    institutionalConfiguration: {
        id: 'dashboardInstitutionalConfiguration',
        label: labels.institutionalConfiguration,
        path: `/dashboard/institutional-configuration`,
        coreOperation: "BankparamList",
    },
    generalLedger: {
        id: 'dashboardGeneralLedger',
        label: labels.generalLedger,
        path: `/dashboard/general-ledger`,
        coreOperation: "AcctList"
    },
    transactionCodes: {
        id: 'dashboardTransactionCodes',
        label: labels.transactionCodes,
        path: `/dashboard/transaction-codes`,
        coreOperation: "TrnCodeList"
    },
    networkRoute: {
        id: "dashboardNnetwork",
        label: labels.network,
        path: "/network",
        coreOperation: "NetworkList",
    },
    customerRelationshipTypes: {
        id: "dashboardCustomerRelationshipTypes",
        label: labels.customerRelationshipTypes,
        path: "/dashboard/customer-relationship-types",
        coreOperation: "CustRelTypeList",
    }
  }
  
  export default foundationalSetupRoutes