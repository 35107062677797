import React from 'react'
import { faSearch } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsn from 'classnames'
import { useCallback, useMemo } from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import s from './OrgEnvSelector.module.scss'
import OrgRow from './OrgRow'
import OrgRowGroup from './OrgRowGroup'
import {
  addRecentOrg,
  setFavOrgs,
  setGroupingEnabled,
  setOrgSearchTerm,
} from './orgEnvSelectorStore'
import { addFavOrg, removeFavOrg } from './orgEnvSelectorRequests'
import { useOrgEnvDispatch, useOrgEnvSelector } from './orgEnvSelectorHooks'
import { Organization } from './OrgEnvDropdown'
import { ORG_STATUS } from '@/constants'

/**
 * The left hand panel of the OrgEnvSelector, lists organizations the user may access.
 * Allows for selection, favoriting, and filtering.
 * @returns
 */
export default function OrgSelector(props: {
  org: Organization
  orgs: Organization[]
  onOrgSelect: (id: number) => void
}) {
  const parentId = 'parent'
  const { org: selectedOrg, orgs, onOrgSelect } = props
  const state = useOrgEnvSelector((state) => state.orgEnvSelector)
  const orgEnvDispatch = useOrgEnvDispatch()

  // Select handler
  const onOrgSelectHandler = useCallback(
    async (id: number) => {
      orgEnvDispatch(addRecentOrg(id))
      onOrgSelect(id)
      const el = document.getElementById(parentId)
      el?.scrollTo({ top: 0, behavior: 'smooth' })
    },
    [orgEnvDispatch]
  )

  // Search filter
  const filteredOrgs = useMemo(() => {
    // TODO: get rid of the deleted orgs filter once CNSL-9746 is implemented
    const orgsWithoutDeletedAndSelectedOrg = orgs.filter(
      (org) => org.id !== selectedOrg.id && org.status !== ORG_STATUS.Deleted
    )
    if (state.orgSearchTerm) {
      const lowerCaseSearchTerm = state.orgSearchTerm.toLocaleLowerCase()
      return orgsWithoutDeletedAndSelectedOrg.filter((org) =>
        org.name.toLocaleLowerCase().includes(lowerCaseSearchTerm)
      )
    }

    return orgsWithoutDeletedAndSelectedOrg
  }, [orgs, selectedOrg, state.orgSearchTerm])

  // Search handler
  const onOrgSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    orgEnvDispatch(setOrgSearchTerm(e.target.value))
  }

  // Favorites
  const favOrgs = filteredOrgs.filter((org) => {
    return state.favOrgs.includes(org.id)
  })
  // Other orgs
  const nonFavOrgs = filteredOrgs.filter((org) => {
    return !state.favOrgs.includes(org.id)
  })
  const onClickFavOrg = (orgId: number, isFav: boolean) => {
    if (!isFav) {
      // Add Favorite
      addFavOrg(orgId) // In DB

      // And in state
      const favOrgs = JSON.parse(JSON.stringify(state.favOrgs)) ?? []
      favOrgs.push(orgId)
      orgEnvDispatch(setFavOrgs(favOrgs))
    } else {
      // Remove Favorite
      removeFavOrg(orgId) // In DB

      // And in state
      const favOrgs = JSON.parse(JSON.stringify(state.favOrgs)) ?? []
      const index = favOrgs.indexOf(orgId)
      if (index > -1) {
        favOrgs.splice(index, 1)
      }
      orgEnvDispatch(setFavOrgs(favOrgs))
    }
  }

  // Recents
  const recentOrgs = filteredOrgs
    .filter((org) => {
      return (
        state.recentOrgs.includes(org.id) && !state.favOrgs.includes(org.id)
      )
    })
    .sort((a, b) => {
      return state.recentOrgs.indexOf(a.id) - state.recentOrgs.indexOf(b.id)
    })

  // Grouping handler
  const onGroupingChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    orgEnvDispatch(setGroupingEnabled(e.target.checked))
  }

  // Groups
  const internal = filteredOrgs.filter((org) => {
    return org.category === 'Internal'
  })
  const partner = filteredOrgs.filter((org) => {
    return org.category === 'Partner'
  })
  const client = filteredOrgs.filter((org) => {
    return org.category === 'Client'
  })

  // Render
  return (
    <>
      <div className={s.orgContainer} id={parentId}>
        <h1 className={s.heading}>Organization</h1>
        <p className={clsn(s.headingNote, 'invisible')}>.</p>

        <InputGroup>
          <InputGroup.Text className={s.searchBoxIcon} id='orgSearchIcon'>
            <FontAwesomeIcon icon={faSearch} />
          </InputGroup.Text>
          <Form.Control
            aria-describedby='orgSearchIcon'
            aria-label='Search'
            className={s.searchBox}
            onChange={onOrgSearchTermChange}
            placeholder='Search'
            type='text'
            value={state.orgSearchTerm}
          />
        </InputGroup>

        {selectedOrg ? (
          <>
            <h3 className={s.heading}>Organization</h3>
            <div className={s.selectedOrganization}>
              <OrgRow
                isFav={
                  state.favOrgs.find((val) => {
                    return val === selectedOrg.id
                  })
                    ? true
                    : false
                }
                onClickFavOrg={onClickFavOrg}
                org={selectedOrg}
                selected
              />
            </div>
          </>
        ) : null}

        {/* Favorites */}
        {favOrgs && favOrgs.length > 0 ? (
          <div className='my-2'>
            <OrgRowGroup
              favOrgIds={state.favOrgs}
              heading='Favorites'
              onClick={onOrgSelectHandler}
              onClickFav={onClickFavOrg}
              orgs={favOrgs}
              selectedOrgId={selectedOrg?.id}
            />
          </div>
        ) : null}

        {/* Non Favorites */}
        {!state.groupingEnabled && nonFavOrgs && nonFavOrgs.length > 0 && (
          <OrgRowGroup
            favOrgIds={state.favOrgs}
            heading='Other'
            onClick={onOrgSelectHandler}
            onClickFav={onClickFavOrg}
            orgs={nonFavOrgs}
            selectedOrgId={selectedOrg?.id}
          />
        )}


        {!favOrgs.length && !nonFavOrgs.length &&
          (
            <p
              className={clsn(state.groupingEnabled ? 'd-none' : s.emptyMessage)}
            >
              No organizations to show
            </p>
          )}

        {/* Groups */}
        {state.groupingEnabled && recentOrgs && recentOrgs.length > 0 ? (
          <OrgRowGroup
            collapsable={state.orgSearchTerm ? undefined : true}
            favOrgIds={state.favOrgs}
            heading='Recents'
            onClick={onOrgSelectHandler}
            onClickFav={onClickFavOrg}
            orgs={recentOrgs}
            selectedOrgId={selectedOrg?.id}
          />
        ) : null}
        {state.groupingEnabled && internal && internal.length > 0 ? (
          <OrgRowGroup
            collapsable={state.orgSearchTerm ? undefined : true}
            collapsed
            favOrgIds={state.favOrgs}
            heading='Internal'
            onClick={onOrgSelectHandler}
            onClickFav={onClickFavOrg}
            orgs={internal}
            selectedOrgId={selectedOrg?.id}
          />
        ) : null}
        {state.groupingEnabled && partner && partner.length > 0 ? (
          <OrgRowGroup
            collapsable={state.orgSearchTerm ? undefined : true}
            collapsed
            favOrgIds={state.favOrgs}
            heading='Partner'
            onClick={onOrgSelectHandler}
            onClickFav={onClickFavOrg}
            orgs={partner}
            selectedOrgId={selectedOrg?.id}
          />
        ) : null}
        {state.groupingEnabled && client && client.length > 0 ? (
          <OrgRowGroup
            collapsable={state.orgSearchTerm ? undefined : true}
            collapsed
            favOrgIds={state.favOrgs}
            heading='Client'
            onClick={onOrgSelectHandler}
            onClickFav={onClickFavOrg}
            orgs={client}
            selectedOrgId={selectedOrg?.id}
          />
        ) : null}
      </div>
      <div className={s.groupingContainer}>
        <Form.Check
          id='group'
          className={s.grouping}
          checked={state.groupingEnabled ? true : false}
          label='Grouping Enabled'
          onChange={onGroupingChange}
          type='checkbox'
        />
      </div>
    </>
  )
}
