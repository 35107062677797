import React from 'react'
import axios from 'axios'
import dayjs from 'dayjs'
import { produce } from 'immer'
import * as _ from 'lodash-es'
import { useCallback, useEffect, useReducer } from 'react'
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import * as reactUse from 'react-use'
import {
  loggingOut,
  timeoutSessionLogout,
  timeoutSessionLogoutWarning,
} from '../../constants'
import SpinnerButton from '../SpinnerButton'
import s from './Timeout.module.scss'

type TimeoutState = {
  extending: boolean
  loggingOut: boolean
  timingOut: boolean
  timingOutMessage: string
  warningModalOpen: boolean
}

type TimeoutAction =
  | {
      type: 'PROCESSING_EXTENSION'
      value: boolean
    }
  | {
      type: 'PROCESSING_LOGOUT'
      value: boolean
    }
  | {
      type: 'PROCESSING_TIMEOUT'
      value: boolean
      message: string
    }
  | {
      type: 'SHOW_WARNING_MODAL'
      value: boolean
    }

function timeoutReducer(
  state: TimeoutState,
  action: TimeoutAction
): TimeoutState {
  const nextState = produce(state, (draftState) => {
    switch (action.type) {
      case 'PROCESSING_EXTENSION': {
        draftState.extending = action.value
        return draftState
      }
      case 'PROCESSING_LOGOUT': {
        draftState.loggingOut = action.value
        localStorage.setItem(loggingOut, String(action.value))
        if (action.value) window.location.href = '/console/logout'
        return draftState
      }
      case 'PROCESSING_TIMEOUT': {
        draftState.timingOut = action.value
        draftState.timingOutMessage = action.message
        return draftState
      }
      case 'SHOW_WARNING_MODAL': {
        draftState.warningModalOpen = action.value
        localStorage.setItem('warningModalOpen', String(action.value))
        return draftState
      }
    }
  })

  return nextState
}

const initialState: TimeoutState = {
  extending: false,
  loggingOut: false,
  timingOut: false,
  timingOutMessage: '',
  warningModalOpen: false,
}

/**
 * Returns a function which can be called to extend the client-side session.
 * @returns function that extends the session
 */
export function useExtendClientSession(sesnTimeoutMs: number, sesnTimeoutWarnMs: number) {
  return async () => {
    const timeoutDtm = dayjs().add(sesnTimeoutMs, 'ms').format()
    const timeoutWarnDtm = dayjs()
      .add(sesnTimeoutMs - sesnTimeoutWarnMs, 'ms')
      .format()

    localStorage.setItem(timeoutSessionLogout, timeoutDtm)
    localStorage.setItem(timeoutSessionLogoutWarning, timeoutWarnDtm)
  }
}

/**
 * Returns a function which can be called to extend the session. With Okta, server side & client side session
 * are linked and will expire at the same moment.  The server side dictates this moment, so the value must be
 * retrieved from the non-HttpOnly cookie the server provides for the client's reference.
 * @returns function that extends the session
 */
function useAutoExtendServerSession() {
  return async () => {
    // Extend server side session via ping
    await axios.head(`/console/ping`)
  }
}

/**
 * Handles timeout and timeout warning functionality.
 * @author Kyle Fox
 */
function Timeout(props: {sesnTimeoutMs: number, sesnTimeoutWarnMs: number}) {
  const { useEffectOnce } = reactUse
  const [state, dispatch] = useReducer(timeoutReducer, initialState)

  // How long remains before session timeout on warning
  const {sesnTimeoutMs, sesnTimeoutWarnMs} = props

  // Session extension function
  const extendClientSession = useExtendClientSession(sesnTimeoutMs, sesnTimeoutWarnMs)
  const extendServerSession = useAutoExtendServerSession()

  // Start the initial client side session (initial timeout DTMs)
  useEffectOnce(() => {
    localStorage.setItem(loggingOut, 'false')
    extendClientSession()
  })

  useEffect(() => {
    const storageEventHandler = (event: StorageEvent) => {
      if (event.key === 'warningModalOpen') {
        const warningModalOpen = event.newValue === 'true'
        if (state.warningModalOpen !== warningModalOpen) {
          dispatch({ type: 'SHOW_WARNING_MODAL', value: warningModalOpen })
        }
      } else if (event.key === loggingOut) {
        const loggingOut = event.newValue === 'true'
        if (state.loggingOut !== loggingOut) {
          dispatch({ type: 'PROCESSING_LOGOUT', value: loggingOut })
        }
      }
    }

    window.addEventListener('storage', storageEventHandler)

    return () => {
      window.removeEventListener('storage', storageEventHandler)
    }
  }, [state.warningModalOpen, state.loggingOut])

  useEffect(() => {
    const f = async () => {
      if (state.timingOut) {
        console.info(state.timingOutMessage)
        window.location.href = '/console/timeout'
      }
    }

    f()
  }, [state.timingOut, state.timingOutMessage])

  /**
   * Starts an interval which checks if the user should be timed out or if the user should
   * be waned of impending timeout every second.  This is achieved by reading the
   * timeoutDtm and timeoutWarnDtm variables from local storage, and if either have expired
   * take the appropriate action.  These local storage variables are updated any time the
   * session is extended.
   * @returns
   */
  useEffect(() => {
    // Takes action if a timeout or timeout warning condition exists
    const checkTimeout = () => {
      const timeoutDtmStr = localStorage.getItem(timeoutSessionLogout)
      const timeoutWarnDtmStr = localStorage.getItem(
        timeoutSessionLogoutWarning
      )

      if (timeoutDtmStr && timeoutWarnDtmStr) {
        const timeoutDtm = dayjs(timeoutDtmStr)
        const timeoutWarnDtm = dayjs(timeoutWarnDtmStr)
        const now = dayjs()

        if (now.isAfter(timeoutDtm)) {
          if (!state.timingOut) {
            dispatch({
              type: 'PROCESSING_TIMEOUT',
              value: true,
              message: 'Timed out - reached max inactivity time',
            })
          }
        } else if (now.isAfter(timeoutWarnDtm)) {
          console.debug('Timeout warning')
          dispatch({ type: 'SHOW_WARNING_MODAL', value: true })
        }
      }
    }

    // Check for timeout every second
    const intervalId = setInterval(checkTimeout, 1000)

    return () => {
      // Stop the interval
      clearInterval(intervalId)
    }
  }, [dispatch, state.timingOut])

  // Automatically extend session on user activity
  // Kicks off listeners that listen for key press, mouse move, and touch events, extending
  // the session in a debounced fashion when these events occur.  Cancels listeners when
  // unmounted or as the warningModalOpen state is modified.
  useEffect(() => {
    // Takes action if a timeout or timeout warning condition exists
    const extendServerTimeout = () => {
      extendServerSession()
    }

    // Check for timeout every 60 second
    const intervalId = setInterval(extendServerTimeout, 60000)

    const debouncedExtendSession = _.throttle(
      () => {
        try {
          if (!state.warningModalOpen) {
            extendClientSession()
            extendServerSession()
          }
        } catch {
          if (!state.timingOut) {
            dispatch({
              type: 'PROCESSING_TIMEOUT',
              value: true,
              message: 'Timed out - failed to reset timeout dtms',
            })
          }
        }
      },
      60000,
      { leading: true }
    )

    const documentEventsThatUpdateTimeout = new Set([
      'keypress',
      'mousemove',
      'touchStart',
    ])

    for (const eventType of documentEventsThatUpdateTimeout) {
      document.addEventListener(eventType, debouncedExtendSession, {
        passive: true,
      })
    }

    return () => {
      // Remove the listeners
      for (const eventType of documentEventsThatUpdateTimeout) {
        document.removeEventListener(eventType, debouncedExtendSession)
      }

      // Stop the interval
      clearInterval(intervalId)
    }
  }, [
    extendClientSession,
    extendServerSession,
    state.timingOut,
    state.warningModalOpen,
  ])

  // Called when logout button is clicked on timeout warning
  const logOut = useCallback(() => {
    dispatch({ type: 'PROCESSING_LOGOUT', value: true })
    console.info('Timed out - user chose to logout on timeout warning')
  }, [dispatch])

  // Displayed in timeout warning
  const remainingMinutes = sesnTimeoutWarnMs / 1_000 / 60

  // Called when extend button is clicked on timeout warning
  const onExtend = useCallback(async () => {
    dispatch({ type: 'PROCESSING_EXTENSION', value: true })
    try {
      await extendServerSession()
      await extendClientSession()
      toast.success('Your session has been extended')
      dispatch({ type: 'SHOW_WARNING_MODAL', value: false })
    } catch {
      if (!state.timingOut) {
        dispatch({
          type: 'PROCESSING_TIMEOUT',
          value: true,
          message: 'Timed out - failed to extend session',
        })
      }
    } finally {
      dispatch({ type: 'PROCESSING_EXTENSION', value: false })
    }
  }, [dispatch, extendClientSession, extendServerSession, state.timingOut])

  return (
    <Modal
      backdrop='static'
      centered
      className={s.timeoutModal}
      show={state.warningModalOpen}
    >
      <Modal.Body>
        <h2>Your session is about to expire.</h2>
        <p>
          Your session will expire in <b>{remainingMinutes} minutes</b>. To
          extend your session, click the extend button below.{' '}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <div className='ms-auto'>
          <SpinnerButton
            className='mx-1'
            disabled={state.extending || state.loggingOut}
            label='Logout'
            loading={state.loggingOut}
            onClick={logOut}
            variant='secondary'
          />
          <SpinnerButton
            disabled={state.extending || state.loggingOut}
            label='Extend'
            loading={state.extending}
            onClick={onExtend}
            variant='primary'
          />
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default Timeout
