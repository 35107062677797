import React, {
  isValidElement,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'
import {
  faChevronRight,
  IconDefinition,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsn from 'classnames'
import NavCategory from './NavCategory'
import NavItem from './NavItem'
import { NavContext } from './Nav'
import s from './Nav.module.scss'
import { useSubRoute } from '../../hooks/common'

type Props = {
  id: string
  label: string
  icon?: IconDefinition
  children?: ReactNode
  tier: 1 | 2 | 3 | 4
  swapOpacity?: boolean
  accordion?: boolean
  currItem?: any
  navType?: 'DocsAPIMenu' | 'Menu'
  disabled?: boolean
  onMouseEvent?: (val: boolean) => () => void
  sendOpenGroupItem?: (item: string) => void
}

/**
 * NavGroup is an expandable navigation item on the Nav.  Expanding will trigger the
 * tier-1 or tier-2 menu to display with the NavGroup's children.
 * @param props
 * @returns
 */
export default function NavGroup(props: Props) {
  const { navState, active: act, dispatch } = useContext(NavContext)
  const {
    icon,
    currItem,
    navType = 'Menu',
    disabled,
    onMouseEvent,
    sendOpenGroupItem,
  } = props
  const [highlight, toggleHighlight] = useState<boolean>(false)
  const { currPath, subRoute } = useSubRoute()

  const setOpenGroups = () => {
    // resetting initial opengroups
    sendOpenGroupItem?.('')
    dispatch({ type: 'TOGGLE_GROUP', tier: props.tier, id: props.id })
  }

  /**
   * Iterates children checking if any child NavItem is active.  When NavItem containers
   * like NavGroup and NavCategory are found, iterates their children as well.  Useful
   * for applying "active" style to parent nav tiers of an active child.
   * @param children
   * @param currentlyActive
   * @returns
   */
  const isActive = (children: ReactNode, currentlyActive: string): boolean => {
    if (Array.isArray(children)) {
      for (const child of children) {
        if (!isValidElement(child) && !Array.isArray(child)) {
          continue
        }
        if (Array.isArray(child)) {
          const active = isActive(child, currentlyActive)
          if (active) {
            return true
          }
        } else if (child.type === NavItem) {
          const { id } = child.props as any
          if (id && id === currentlyActive) {
            if (sendOpenGroupItem && props.id && !navState.openGroups?.length) {
              sendOpenGroupItem(props.id)
            }
            return true
          }
        } else if (
          child.type === NavCategory ||
          child.type === NavGroup ||
          child.type === MenuItemDocs ||
          child.type === React.Fragment
        ) {
          const active = isActive(
            (child.props as any).children,
            currentlyActive
          )
          if (active) {
            return true
          }
        }
      }
    } else if (
      React.isValidElement(children) &&
      children.type === React.Fragment
    ) {
      const active = isActive(children.props?.children, currentlyActive)
      if (active) {
        return true
      }
    } else if (
      React.isValidElement(children) &&
      children.type === MenuItemDocs
    ) {
      const active = isActive(
        (children?.props as any).item.children,
        currentlyActive
      )
      if (active) {
        return true
      }
    }
    return false
  }

  const isActiveForNestedItem = (
    navData: any,
    curActiveUid: string
  ): boolean => {
    if (curActiveUid) {
      if (navData?.uid === curActiveUid) {
        if (sendOpenGroupItem && props.id && !navState.openGroups?.length) {
          sendOpenGroupItem(props.id)
        }
        return true
      } else if (navData?.childrens?.length) {
        for (const item of navData.childrens) {
          if (item.uid === curActiveUid) {
            if (sendOpenGroupItem && props.id && !navState.openGroups?.length) {
              sendOpenGroupItem(props.id)
            }
            return true
          } else if (item.childrens?.length) {
            const active = isActiveForNestedItem(item, curActiveUid)
            if (active) {
              return true
            }
          }
        }
      }
    }
    return false
  }

  const handleChevronToggle = (event: React.MouseEvent) => {
    event.stopPropagation()
    if (!disabled) {
      dispatch({ type: 'TOGGLE_GROUP', tier: props.tier, id: props.id })
    }
  }

  const open = navState.openGroups.includes(props.id)

  useEffect(() => {
    if (currPath) {
      const active =
        navType === 'Menu'
          ? isActive(props.children, act)
          : isActiveForNestedItem(currItem, subRoute)

      toggleHighlight(open || (active && !navState.openGroups[props.tier - 1]))
    }
  }, [currPath])

  let tierStyle = ''
  switch (props.tier) {
    case 1:
      tierStyle = s.tier1
      break
    case 2:
      tierStyle = s.tier2
      break
    case 3:
      tierStyle = s.tier3
      break
    case 4:
      tierStyle = s.tier4
      break
  }

  return (
    <div>
      <div
        className={clsn(s.navGroupContainer, disabled ? s.disabled : null)}
        onMouseEnter={onMouseEvent?.(true)}
        onMouseLeave={onMouseEvent?.(false)}
      >
        <NavItem
          fast
          id={props.id}
          key={`NavGroup-NavItem-${props.id}`}
          label={props.label}
          link={props?.currItem?.path || '#'}
          icon={icon}
          tier={props.tier}
          onClick={(e) => {
            if (!disabled) {
              if (props?.currItem?.path) {
                setOpenGroups()
              } else {
                e.preventDefault()
                setOpenGroups()
              }
            }
          }}
          fromNavGroup
          highlightNavGroup={highlight ? true : false}
        />
        <span
          onClick={handleChevronToggle}
          className={s.chevronIconContainer}
          aria-controls={
            props.accordion ? `${props.label}-collapse` : undefined
          }
          aria-expanded={props.accordion ? open : undefined}
        >
          <FontAwesomeIcon
            className={clsn(
              s.chevronIcon,
              open && props.accordion ? s.rotateExpanded : ''
            )}
            icon={faChevronRight}
            key={`${props.id}-icon`}
          />
        </span>
      </div>
      {props.accordion ? (
        <div
          className={s.navGroupCollapse}
          style={{ display: open ? 'block' : 'none' }}
        >
          <div
            id={`${props.label}-collapse`}
            className={s.navGroupInnerContent}
          >
            {props.children}
          </div>
        </div>
      ) : null}
      {open && !props.accordion ? (
        <div className={clsn(s.navGroup, props.tier === 2 ? s.tier2 : null)}>
          {props.children}
        </div>
      ) : null}
    </div>
  )
}

type MenuItemDocsProps = {
  /* Label to show above category, if desired */
  level?: any

  /* NavItems to show within this category */
  item: any

  sendOpenGroupItem: any
}

export function MenuItemDocs({
  item,
  level = 1,
  sendOpenGroupItem,
}: MenuItemDocsProps): any {
  return (
    <>
      {item.childrens && item.childrens.length > 0 ? (
        item.childrens?.map((child: any, index: any) =>
          child?.childrens?.length ? (
            <NavGroup
              id={child.uid}
              label={child.title}
              tier={level + 1}
              accordion={true}
              currItem={child}
              navType={'DocsAPIMenu'}
              key={`NavGroup-${index}-${child.uid}`}
              sendOpenGroupItem={sendOpenGroupItem}
            >
              <MenuItemDocs
                key={`MenuItemDocs-${index}-${child.uid}-${level}`}
                item={child}
                level={level + 1}
                sendOpenGroupItem={sendOpenGroupItem}
              />
            </NavGroup>
          ) : (
            <NavItem
              fast
              key={`NavItem-${index}-${child.uid}`}
              id={child.uid}
              label={child.title}
              link={child.path}
              tier={level + 1}
            />
          )
        )
      ) : item.type === 'header' ? (
        <NavCategory key={`NavCategory-${item.title}`} label={item.title}>
          <div></div>
        </NavCategory>
      ) : (
        <NavItem
          fast
          key={`NavItem-${item.uid}`}
          id={item.uid}
          label={item.title}
          link={item.path}
          tier={level + 1}
        />
      )}
    </>
  )
}
