import { consoleOperations } from '../constants'
import labels from './education-routes-labels.json'
import type { Route } from '../routes/index'

const {coreVersionsSwaggerView} = consoleOperations

export const educationRoutes: Record<string, Route> = {
  newsRoute: {
    id: 'news',
    label: labels.news,
    path: '/news',
  },
  releaseRoute: {
    id: 'release',
    label: labels.release,
    path: '/release',
  },
  releaseCalendarRoute: {
    id: 'releaseCalendar',
    label: labels.releaseCalendar,
    path: '/releaseCalendar',
  },
  documentationRoute: {
    id: 'documentation',
    label: labels.documentation,
    path: '/documentation',
  },
  schemaOverviewRoute: {
    id: 'schema-overview',
    label: labels.schemaOverview,
    path: '/schema/overview',
  },
  schemaAndChoicesRoute: {
    id: 'schema-and-choices',
    label: labels.schemaAndChoices,
    path: '/schema/and-choices',
  },
  sampleAPIsRoute: {
    id: 'sampleAPIs',
    label: labels.sampleAPIs,
    path: '/sampleAPIs',
  },
  swaggerCoreRoute: {
    id: 'swaggerCore',
    label: labels.swaggerCore,
    path: '/swaggerCore',
    consoleOperation: coreVersionsSwaggerView,
  }
}

export default educationRoutes