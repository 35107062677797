const uiSchema = {
  "ui:order": [
    "matrixName",
    "desc",
    "isBlended",
    "dimensions",
    "valType",
    "valFmt",
  ],
  dimensions: {
    "ui:order": ["propLabel", "propName", "propType", "propFmt", "whenBtwn"],
  },
};
export default uiSchema;
