import React from 'react'
import { faCheck } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsn from 'classnames'
import s from './EnvProjRow.module.scss'
import { Environment, Project } from './OrgEnvDropdown'

type Props = {
  onClick?: (envId: number) => void
  env?: Environment
  proj?: Project
  selected?: true
}

export default function EnvProjRow(props: Props) {
  const { onClick, env, proj, selected } = props

  if (!env && !proj) {
    return null
  }

  const id = env?.id || proj?.id
  const name = env?.name || proj?.name

  return (
    <div
      className={clsn({ [s.envContainer]: true, [s.selected]: selected })}
      onClick={() => {
        return id && onClick && onClick(id)
      }}
    >
      <div className={s.env}>
        <span className={s.envSelected}>
          <FontAwesomeIcon
            icon={faCheck}
            className={clsn(!selected ? 'invisible' : null)}
          />
        </span>
        <span className={s.envName}>{name}</span>
      </div>
    </div>
  )
}
