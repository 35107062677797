import {
  configureStore,
  createAction,
  createAsyncThunk,
  createReducer,
} from '@reduxjs/toolkit'
import {
  addRecentOrg as addRecentOrgToLocalStorage,
  getFavOrgs,
  getGroupingEnabled,
  getRecentOrgs,
  setGroupingEnabled as setGroupingEnabledInLocalStorage,
} from './orgEnvSelectorRequests'

// ----------------------------------------------------------------------------------- //
// State                                                                               //
// ----------------------------------------------------------------------------------- //

export type OrgEnvSelectorState = {
  envSearchTerm?: string
  favOrgs: number[]
  groupingEnabled: boolean
  orgSearchTerm?: string
  recentOrgs: number[]
}

export const initialState: OrgEnvSelectorState = {
  envSearchTerm: '',
  favOrgs: [],
  groupingEnabled: true,
  recentOrgs: [],
  orgSearchTerm: '',
}

// ----------------------------------------------------------------------------------- //
// Actions                                                                             //
// ----------------------------------------------------------------------------------- //

export const setEnvSearchTerm = createAction<string | undefined>(
  'setEnvSearchTerm'
)
export const setFavOrgs = createAction<number[] | undefined>('setFavOrgs')
export const setOrgSearchTerm = createAction<string | undefined>(
  'setOrgSearchTerm'
)

// ----------------------------------------------------------------------------------- //
// Thunks                                                                              //
// ----------------------------------------------------------------------------------- //
// Note: The generics on async thunks are <what it returns, what it accepts>

/**
 * addRecentOrg takes an orgId, pushes it on the list of recent orgs in local storage,
 * and returns the recent orgs list.
 */
export const addRecentOrg = createAsyncThunk<number[] | undefined, number>(
  'setRecentOrgs',
  (orgId) => {
    const recentOrgs = addRecentOrgToLocalStorage(orgId)
    return recentOrgs
  }
)

type LoadActionPayload = {
  favOrgs: number[] | undefined
  groupingEnabled: boolean
  recentOrgs: number[] | undefined
}
export const load = createAsyncThunk<LoadActionPayload, undefined>(
  'load',
  async () => {
    const favOrgs = await getFavOrgs()
    const groupingEnabled = (await getGroupingEnabled()) ?? false
    const recentOrgs = await getRecentOrgs()

    return {
      favOrgs,
      groupingEnabled,
      recentOrgs,
    }
  }
)

export const setGroupingEnabled = createAsyncThunk<boolean, boolean>(
  'setGroupingEnabled',
  (groupingEnabled) => {
    setGroupingEnabledInLocalStorage(groupingEnabled)
    return groupingEnabled
  }
)

// ----------------------------------------------------------------------------------- //
// Reducer                                                                             //
// ----------------------------------------------------------------------------------- //
export const orgEnvSelectorReducer = createReducer(initialState, (builder) => {
  builder.addCase(load.fulfilled, (draft, action) => {
    draft.favOrgs = action.payload.favOrgs ?? []
    draft.groupingEnabled = action.payload.groupingEnabled
    draft.recentOrgs = action.payload.recentOrgs ?? []
    return draft
  })
  builder.addCase(setEnvSearchTerm, (draft, action) => {
    draft.envSearchTerm = action.payload
    return draft
  })
  builder.addCase(setFavOrgs, (draft, action) => {
    draft.favOrgs = action.payload ?? []
    return draft
  })
  builder.addCase(setGroupingEnabled.fulfilled, (draft, action) => {
    draft.groupingEnabled = action.payload
    return draft
  })
  builder.addCase(setOrgSearchTerm, (draft, action) => {
    draft.orgSearchTerm = action.payload
    return draft
  })
  builder.addCase(addRecentOrg.fulfilled, (draft, action) => {
    draft.recentOrgs = action.payload ?? []
    return draft
  })
})

export const orgEnvStore = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: true,
      immutableCheck: true,
      // We're storing environment data (dates for createdAt) here that don't work with redux.
      serializableCheck: false,
    }),
  reducer: {
    orgEnvSelector: orgEnvSelectorReducer,
  },
})

// https://redux-toolkit.js.org/tutorials/typescript#define-root-state-and-dispatch-types
export type OrgEnvState = ReturnType<typeof orgEnvStore.getState>
export type OrgEnvDispatch = typeof orgEnvStore.dispatch

// #endregion
