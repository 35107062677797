import React, { ReactNode, Ref } from "react";
import {
  InstantSearch,
  SearchBox,
  useInstantSearch,
  Configure,
  usePagination,
} from "react-instantsearch";
import algoliasearch from "algoliasearch";
import { CustomHit } from "./Hits";
import css from "./Search.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faMagnifyingGlass,
} from "@fortawesome/pro-regular-svg-icons";
import cx from "classnames";
import SearchFilters from "./SearchFilters";

export type SiteFunction = "mobile" | "global" | "desktop";
interface NoResultsBoundaryProps {
  fallback: ReactNode;
  pathName: string | null;
  consoleConfig: ConsoleConfig;
  isAuthenticated: boolean;
  contextSlug?: string;
  siteFunction: SiteFunction;
}

type ConsoleConfig = {
  searchAppId?: string;
  searchKey?: string;
  searchIndex?: string;
} | null;

type Props = {
  active?: boolean;
  pathName: string;
  consoleConfig: ConsoleConfig;
  isAuthenticated: boolean;
  innerRef?: Ref<HTMLDivElement>;
  searchQuery?: string;
  contextSlug?: string;
  siteFunction: SiteFunction;
};
export { type Props as SearchProps };

export const Search = (props: Props) => {
  const {
    active = true,
    innerRef,
    consoleConfig,
    pathName,
    isAuthenticated,
    searchQuery,
    siteFunction,
    contextSlug,
  } = props;

  if (!consoleConfig?.searchAppId || !consoleConfig.searchKey) {
    return null;
  }

  const searchClient = algoliasearch(
    consoleConfig.searchAppId,
    consoleConfig.searchKey
  );

  if (!active) return null;

  return (
    <div ref={innerRef}>
      <InstantSearch
        indexName={consoleConfig?.searchIndex}
        searchClient={searchClient}
        initialUiState={
          consoleConfig?.searchIndex
            ? {
                [consoleConfig?.searchIndex]: {
                  query: searchQuery,
                },
              }
            : undefined
        }
      >
        <div
          className={cx({
            [css["search-container-expanded"]]: true,
            [css["mobile"]]: siteFunction === "mobile",
          })}
        >
          <SearchBoxContainer siteFunction={siteFunction} />
          <NoResultsBoundary
            consoleConfig={consoleConfig}
            pathName={pathName}
            fallback={<NoResults siteFunction={siteFunction} />}
            isAuthenticated={isAuthenticated}
            siteFunction={siteFunction}
            contextSlug={contextSlug}
          />
          <Configure
            // @ts-ignore typings for this component is incorrect
            hitsPerPage={
              siteFunction === "desktop" || siteFunction === "mobile" ? 5 : 35
            }
          />
        </div>
      </InstantSearch>
    </div>
  );
};

function SearchBoxContainer({ siteFunction }: { siteFunction: SiteFunction }) {
  const { indexUiState } = useInstantSearch();

  return (
    <div
      className={cx({
        [css["search-box-wrapper"]]: true,
        [css["open"]]: Boolean(indexUiState.query),
        [css["topmenu"]]: siteFunction !== "global",
        [css["mobile"]]: siteFunction === "mobile",
      })}
    >
      <div
        className={cx({
          [css["search-bar"]]: true,
          [css["mobile"]]: siteFunction === "mobile",
          [css["open"]]: Boolean(indexUiState.query),
        })}
      >
        <FontAwesomeIcon
          size="lg"
          aria-label="Search"
          icon={faMagnifyingGlass}
          className={css["nav-search-icon"]}
        />
        <SearchBox
          placeholder={
            siteFunction === "mobile"
              ? "Search Docs & APIs"
              : "Search across Console, Finxact Academy, & API References"
          }
          classNames={{
            root: css["search-input"],
            form: css["search-input"],
            loadingIcon: "display-none hidden",
          }}
          submitIconComponent={() => null}
          autoFocus
          searchAsYouType={true}
        />
      </div>
    </div>
  );
}

function NoResultsBoundary({
  fallback,
  pathName,
  consoleConfig,
  isAuthenticated,
  siteFunction,
  contextSlug,
}: NoResultsBoundaryProps) {
  const { results, uiState, indexUiState } = useInstantSearch();
  if (!indexUiState.query || !consoleConfig?.searchIndex) {
    return null;
  }

  const searchQuery = uiState[consoleConfig?.searchIndex].query;

  if (!results.hits.length && results.nbHits === 0) {
    return fallback;
  }

  const extractPart = (pathname: string) => {
    const parts = pathname.split("/").filter(Boolean);

    if (parts[0] === "algolia-search") {
      return null;
    }

    if (parts[1] === "tutorials") {
      return parts[1];
    }

    return parts[0] || "";
  };

  const slug = contextSlug ?? extractPart(pathName || "");

  const href = slug
    ? `/algolia-search?searchQuery=${searchQuery}&slug=${slug}`
    : `/algolia-search?searchQuery=${searchQuery}`;

  return (
    <>
      {siteFunction === "global" && <SearchFilters contextSlug={contextSlug} />}

      <div
        className={cx({
          [css["search-results"]]: true,
          [css["mobile"]]: siteFunction === "mobile",
        })}
      >
        <CustomHit
          slug={slug}
          siteFunction={siteFunction}
          isAuthenticated={isAuthenticated}
        />
        {siteFunction !== "global" && (
          <div
            className={cx({
              [css["btn-view-all-results"]]: true,
              [css.mobile]: siteFunction === "mobile",
            })}
          >
            <a href={href}>
              <button className={css["view-results-btn"]}>
                View All Results
              </button>
            </a>
          </div>
        )}
        {siteFunction === "global" && <CustomPagination />}
      </div>
    </>
  );
}

function NoResults({ siteFunction }: { siteFunction: SiteFunction }) {
  return (
    <div
      className={cx({
        [css["search-results"]]: true,
        [css["mobile"]]: siteFunction === "mobile",
      })}
    >
      <p className={css["no-results"]}>No results found. Please try again with different search terms.</p>
    </div>
  );
}

function CustomPagination() {
  const {
    pages,
    currentRefinement,
    isFirstPage,
    isLastPage,
    refine,
    createURL,
  } = usePagination();
  const previousPageIndex = currentRefinement - 1;
  const nextPageIndex = currentRefinement + 1;
  const currPage = currentRefinement === 0 ? 1 : currentRefinement + 1;
  return (
    <ul className={css["algolia-pagination-panel"]}>
      <button
        disabled={isFirstPage}
        className={cx({
          [css["nav-btn"]]: true,
          [css["disabled"]]: isFirstPage,
        })}
        onClick={() => refine(previousPageIndex)}
      >
        <FontAwesomeIcon icon={faArrowLeft} />
      </button>
      <div className={css["pages"]}>
        {pages.map((page) => {
          const label = page + 1;

          return (
            <PaginationItem
              className={label === currPage ? css["active"] : ""}
              key={page}
              isDisabled={false}
              aria-label={`Page ${label}`}
              href={createURL(page)}
              onClick={() => refine(page)}
            >
              {label}
            </PaginationItem>
          );
        })}
      </div>
      <button
        disabled={isLastPage}
        className={cx({
          [css["nav-btn"]]: true,
          [css["disabled"]]: isLastPage,
        })}
        onClick={() => refine(nextPageIndex)}
      >
        <FontAwesomeIcon icon={faArrowRight} />
      </button>
    </ul>
  );
}

type PaginationItemProps = Omit<React.ComponentProps<"a">, "onClick"> & {
  onClick: NonNullable<React.ComponentProps<"a">["onClick"]>;
  isDisabled: boolean;
};

function PaginationItem({
  isDisabled,
  href,
  onClick,
  ...props
}: PaginationItemProps) {
  if (isDisabled) {
    return (
      <li>
        <span {...props} />
      </li>
    );
  }

  return (
    <li>
      <a
        href={href}
        onClick={(event) => {
          if (isModifierClick(event)) {
            return;
          }
          event.preventDefault();
          onClick(event);
        }}
        {...props}
      />
    </li>
  );
}

function isModifierClick(event: React.MouseEvent) {
  const isMiddleClick = event.button === 1;

  return Boolean(
    isMiddleClick ||
      event.altKey ||
      event.ctrlKey ||
      event.metaKey ||
      event.shiftKey
  );
}
