import { faSearch } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cx from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import { Button, Nav, OverlayTrigger, Tab, Tooltip } from 'react-bootstrap'
import ChatWindow, { Message } from '../Chat/ChatWindow'
import { Search, SearchProps } from '../Search/Search'
import css from './SearchAndChat.module.scss'

interface SnCOverlayProps extends SearchProps {
  hasChatbotAccess: boolean
  messages: Message[]
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>
  currentMode: string
  setMode: React.Dispatch<React.SetStateAction<string>>
}

enum MODES {
  search = 'search',
  chat = 'chat',
}

const SearchAndChatOverlay = (props: SnCOverlayProps) => {
  const {
    pathName,
    innerRef,
    siteFunction,
    consoleConfig,
    isAuthenticated,
    hasChatbotAccess,
    messages,
    setMessages,
    currentMode,
    setMode,
  } = props

  if (!hasChatbotAccess) {
    return (
      <div className={css.overlayContent}>
        <Search
          siteFunction={siteFunction}
          pathName={pathName}
          innerRef={innerRef}
          consoleConfig={consoleConfig}
          isAuthenticated={isAuthenticated}
        />
      </div>
    )
  }

  return (
    <Tab.Container activeKey={currentMode} onSelect={(key) => setMode(key!)}>
      <Nav className={css.modeWrapper}>
        <div>Select mode</div>
        <div className={css.modeList}>
          <Nav.Item
            className={cx({
              [css.mode]: true,
              [css.active]: currentMode === MODES.search,
            })}
          >
            <Nav.Link eventKey={MODES.search}>Search</Nav.Link>
          </Nav.Item>
          {hasChatbotAccess && (
            <Nav.Item
              className={cx({
                [css.mode]: true,
                [css.active]: currentMode === MODES.chat,
              })}
            >
              <Nav.Link eventKey={MODES.chat}>Ask Finxact Pro</Nav.Link>
              <div className={css.betaPill}>Beta</div>
            </Nav.Item>
          )}
        </div>
      </Nav>
      <Tab.Content
        className={cx({
          [css.modeContent]: true,
          [css.chatOpen]: Boolean(messages.length),
          ['overflow-auto']: currentMode === MODES.search,
        })}
      >
        <Tab.Pane eventKey={MODES.search}>
          {currentMode === MODES.search && (
            <Search
              siteFunction={siteFunction}
              pathName={pathName}
              innerRef={innerRef}
              consoleConfig={consoleConfig}
              isAuthenticated={isAuthenticated}
            />
          )}
        </Tab.Pane>
        {hasChatbotAccess && (
          <Tab.Pane eventKey={MODES.chat}>
            {currentMode === MODES.chat && (
              <ChatWindow messages={messages} setMessages={setMessages} />
            )}
          </Tab.Pane>
        )}
      </Tab.Content>
    </Tab.Container>
  )
}

interface Props extends SearchProps {
  hasChatbotAccess: boolean
  toggleFinxactPro?: () => void
  showFinxactPro?: boolean
}

const SearchAndChat = (props: Props) => {
  const {
    pathName,
    innerRef,
    consoleConfig,
    isAuthenticated,
    siteFunction,
    hasChatbotAccess,
    showFinxactPro = false,
    toggleFinxactPro,
  } = props
  const [messages, setMessages] = useState<Message[]>([])
  const [currentMode, setMode] = useState<string>(MODES.search)
  const searchBtnRef = useRef<any>()

  useEffect(() => {
    // skipping 1st render
    if (showFinxactPro) {
      setMode(MODES.chat)
      searchBtnRef?.current?.click()
    }
  }, [showFinxactPro])

  // resetting the search tab to default search
  function onChatToggle(overlayState: boolean) {
    if (!overlayState) {
      if (showFinxactPro) {
        toggleFinxactPro?.()
      }
      if (currentMode !== MODES.search) {
        setTimeout(() => {
          setMode(MODES.search)
        }, 100)
      }
    }
  }

  return (
    <>
      <OverlayTrigger
        rootClose
        trigger='click'
        placement='bottom-end'
        onToggle={onChatToggle}
        overlay={(props) => (
          <Tooltip
            className={css.overlay}
            {...props}
            arrowProps={{
              style: { display: 'none' },
            }}
          >
            <SearchAndChatOverlay
              hasChatbotAccess={hasChatbotAccess}
              pathName={pathName}
              innerRef={innerRef}
              siteFunction={siteFunction}
              consoleConfig={consoleConfig}
              isAuthenticated={isAuthenticated}
              messages={messages}
              setMessages={setMessages}
              currentMode={currentMode}
              setMode={setMode}
            />
          </Tooltip>
        )}
      >
        <Button ref={searchBtnRef} className={css.searchButton}>
          <FontAwesomeIcon icon={faSearch} className={css.searchIcon} />
        </Button>
      </OverlayTrigger>
    </>
  )
}
export default SearchAndChat
