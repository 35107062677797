import axios from 'axios'
import localforage from 'localforage'
import * as t from 'io-ts'
import { Either, isLeft } from 'fp-ts/lib/Either.js'
import reporter from 'io-ts-reporters'
import { groupingEnabledKey, recentOrgsKey } from '../../constants'

export const FavOrgs = t.array(t.number)
export type FavOrgs = t.TypeOf<typeof FavOrgs>

/**
 * Log an error instead of throwing
 */
function formatLeft(left: Either<any, any>) {
  return reporter.report(left).join('\n')
}

const decoderError = 'DecoderError' as const

/**
 * Attempt to decode data with a codec.
 *
 * If unsuccessful, throw a formatted error detailing issues with the shape.
 */
function getExn<T, U, V>(codec: t.Type<T, U, V>, data: any): T {
  const result = codec.decode(data)

  if (isLeft(result)) {
    const error = new Error(formatLeft(result))
    error.name = decoderError

    throw error
  }

  return result.right
}

export async function getFavOrgs() {
  try {
    const response = await axios.get(`/console/favOrgs`)
    return getExn(FavOrgs, response.data)
  } catch (e: any) {
    console.error(e)
    return []
  }
}

export async function addFavOrg(orgId: number) {
  return await axios.post(`/console/favOrgs/${orgId}`)
}

export async function removeFavOrg(orgId: number) {
  await axios.delete(`/console/favOrgs/${orgId}`)
}

/**
 * Retrieves and returns the list of recent organizations (IDs) from local storage.
 * @returns
 */
export async function getRecentOrgs() {
  try {
    const recentOrgs = await localforage.getItem<number[]>(recentOrgsKey)
    return recentOrgs ?? []
  } catch (e) {
    console.error(e)
    return []
  }
}

/**
 * Adds a new recent organization (id) to the list of recent org IDs kept in local
 * storage.  The new recent org ID will be put on the front of the list.  The list will
 * be kept to a maximum of 10 entries, dropping the end of the list when needed (oldest).
 * @param orgId
 * @returns the latest list following the operation
 */
export async function addRecentOrg(orgId: number) {
  try {
    let recentOrgs = await localforage.getItem<number[]>(recentOrgsKey)

    if (!recentOrgs) {
      recentOrgs = [] as number[]
    }

    // Remove if existing
    recentOrgs = recentOrgs.filter((item) => item !== orgId)

    // Push on the front and limit to 10 entries
    recentOrgs.unshift(orgId)
    if (recentOrgs.length > 10) {
      recentOrgs.pop()
    }

    localforage.setItem(recentOrgsKey, recentOrgs)
    return recentOrgs
  } catch (e) {
    console.error(e)
  }
}

export async function getGroupingEnabled() {
  return await localforage.getItem<boolean>(groupingEnabledKey)
}

export async function setGroupingEnabled(enabled: boolean) {
  localforage.setItem(groupingEnabledKey, enabled)
}
