import React from "react";
import clsn from "classnames";
import type { ReactNode } from "react";
import s from "./Nav.module.scss";

type Props = {
  /* Label to show above category, if desired */
  label?: string;
  key?: string;

  /* NavItems to show within this category */
  children: ReactNode;
};

/**
 * NavCategory is similar to a NavGroup but does not offer expand/contract functionality.
 * Instead, NavCategory simply organizes NavItems together under a header; the nav items
 * are always visible.
 * @param props
 * @returns
 */
export default function NavCategory(props: Props) {
  if (!props.children || React.Children.count(props.children) === 0) {
    return null;
  }

  return (
    <div className={clsn(s.navCategory, !props.label ? "p-0" : null)}>
      {props.label && <div className={s.navCategoryLabel}>{props.label}</div>}
      {props.children ?? null}
    </div>
  );
}
