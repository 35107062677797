import { useLocation } from "react-use";

/**
 *
 * @returns Generates the last sub route from the current url
 */
export function useSubRoute() {
  const currLoc = useLocation();
  const currPath = currLoc?.pathname?.endsWith("/")
    ? currLoc?.pathname?.slice(0, -1)
    : currLoc?.pathname || "";
  //if UID is prefixed with '/' from prismic including the same in subroute
  const subRoute =
    currPath?.split("/")?.length < 3
      ? currPath
      : currPath?.split("/").pop() || "";
  return { currPath, subRoute };
}
