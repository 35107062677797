import React from 'react'
import clsn from 'classnames'
import { useEffectOnce } from 'react-use'
import s from './OrgEnvSelector.module.scss'

import EnvSelector from './EnvSelector'
import OrgSelector from './OrgSelector'
import { load } from './orgEnvSelectorStore'
import { useOrgEnvDispatch } from './orgEnvSelectorHooks'
import { Environment, Organization, Project } from './OrgEnvDropdown'

export default function OrgEnvSelector(props: {
  org: Organization
  orgs: Organization[]
  proj?: Project
  projs: Project[]
  env?: Environment
  envs: Environment[]
  onOrgSelect: (id: number) => void
  onEnvSelect: (id: number) => void
  onProjSelect: (id: number) => void
}) {
  const {
    org,
    orgs,
    proj,
    projs,
    env,
    envs,
    onOrgSelect,
    onEnvSelect,
    onProjSelect,
  } = props
  const orgEnvDispatch = useOrgEnvDispatch()

  // Load favorites & recents
  useEffectOnce(() => {
    orgEnvDispatch(load())
  })

  // Only show org selection if user can change org
  if (orgs?.length > 1) {
    return (
      <div className={clsn(s.main, s.withOrgSelect)}>
        <div className={s.leftPanel}>
          <OrgSelector org={org} orgs={orgs} onOrgSelect={onOrgSelect} />
        </div>
        <div className={s.rightPanel}>
          <EnvSelector
            proj={proj}
            projs={projs}
            env={env}
            envs={envs}
            onEnvSelect={onEnvSelect}
            onProjSelect={onProjSelect}
          />
        </div>
      </div>
    )
  } else {
    return (
      <div className={clsn(s.main, s.withoutOrgSelect)}>
        <div className={s.onlyPanel}>
          <EnvSelector
            proj={proj}
            projs={projs}
            env={env}
            envs={envs}
            isSingleOrg
            onEnvSelect={onEnvSelect}
            onProjSelect={onProjSelect}
          />
        </div>
      </div>
    )
  }
}
