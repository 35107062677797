import { faUserGear } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsn from 'classnames'
import React, { useCallback, useRef, useState, useEffect, useMemo } from 'react'
import { Dropdown } from 'react-bootstrap'
import s from './Header.module.scss'
import { FinxactLogoWhite } from '../../assets/images'
import {
  consoleOperations,
  constants,
  loggingOut,
  paths,
} from '../../constants'
import { routes } from '../../routes'
import SettingsDropdownSection from './SettingsDropdownSection/SettingsDropdownSection'

import MobileNavigation from './Mobile/MobileNavigation'
import SearchAndChat from './SearchAndChat/SearchAndChat'
import styleVars from '../../styles/variables.scss'

type HeaderProps = {
  className?: string
  consoleConfig: {
    prodEnv: boolean
    enableEcosystem?: boolean
    searchAppId?: string
    searchKey?: string
    searchIndex?: string
  }
  org?: {
    id: number
    name: string
    serviceTicketURL?: string | null
    implementationUrl?: string | null
    implRepoURL?: string | null
  }
  pathName: string
  isPermitted: (
    mode: 'cnsl' | 'core' | 'primary-org-cnsl',
    value: string | RegExp | string[] | undefined,
    inclusive?: boolean | undefined
  ) => boolean
  isTeaser?: boolean
  organization?: {
    id: number
    phase: string
  }
  user?: {
    firstName?: string | null
    lastName?: string | null
    superUser?: boolean
  }
  hamburgerIcon: string
  closeIcon: string
  defaultLogo: string
  chatOpenIcon?: string
  chatClosedIcon?: string
  // These are passed in until we move them to the shared location as well in future tickets
  menuData?: any
  reactRouterLinkComponent?: any
  nextRouterLinkComponent?: any
  parent: 'cnsl' | 'fast'
  project?: {
    id: number
    name: string
    status?: string
  }
  coreVersion?: string
  environment?: {
    id: number
    name: string
    tier?: string
    endpoint: string
    internal?: boolean
  }
  serviceEndpoint?: string
  activateFinxactPro?: boolean
  toggleFinxactPro?: () => void
}

export function Header(props: HeaderProps) {
  const {
    className,
    consoleConfig,
    pathName,
    defaultLogo,
    isPermitted,
    isTeaser,
    organization,
    user,
    hamburgerIcon,
    closeIcon,
    chatOpenIcon,
    chatClosedIcon,
    menuData,
    reactRouterLinkComponent,
    nextRouterLinkComponent,
    parent,
    project,
    serviceEndpoint,
    activateFinxactPro = false,
    toggleFinxactPro,
  } = props
  const { dashboardRoute } = routes

  const fullName = `${user?.firstName ?? ''} ${user?.lastName ?? ''}`.trim()
  const hasChatbotAccess = isPermitted('cnsl', consoleOperations.chatbotAccess)

  const [activeMenu, setActiveMenu] = useState('')
  const searchBarRef = useRef<HTMLDivElement>(null)

  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth)

  const { mobileMaxWidthBreakpoint } = styleVars

  //calculating max mobile width from css property
  const formattedMobileBreakpoint = useMemo(() => {
    if (mobileMaxWidthBreakpoint) {
      const breakpoint = mobileMaxWidthBreakpoint.replace('px', '')
      return Number(breakpoint)
    }
    return 0
  }, [mobileMaxWidthBreakpoint])

  const onLogoLoadError = ({ currentTarget }: any) => {
    currentTarget.onerror = null
    currentTarget.src = defaultLogo
  }

  const onLogout = useCallback(() => {
    localStorage.setItem(loggingOut, 'true')
    window.location.href = '/console/logout'
  }, [])

  const calculateActiveNavLinkPath = useCallback(() => {
    if (pathName.startsWith(paths.academy)) {
      return { path: paths.academy, menu: 'Academy' }
    } else if (pathName.startsWith(paths.docs)) {
      return { path: paths.docs, menu: 'Docs' }
    } else if (pathName.startsWith(paths.apis)) {
      return { path: paths.apis, menu: 'APIs' }
    } else if (!isTeaser) {
      return { path: paths.dashboard, menu: 'Dashboard' }
    } else {
      return { path: '', menu: '' }
    }
  }, [pathName, paths, isTeaser])

  useEffect(() => {
    const { menu } = calculateActiveNavLinkPath()
    setActiveMenu(menu)
  }, [calculateActiveNavLinkPath])

  const getActiveNavLinkPath = useCallback(
    () => calculateActiveNavLinkPath().path,
    [calculateActiveNavLinkPath]
  )

  //Registering resize event to calculate screenwidth
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth !== windowWidth) {
        setWindowWidth(window.innerWidth)
      }
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const logoSrc =
    organization?.id != null
      ? `/console/organization/${organization?.id}/logo`
      : defaultLogo

  return (
    <header
      className={clsn(s.main, className, consoleConfig.prodEnv ? s.prod : null)}
    >
      <div className={s.leftPanel}>
        <div
          className={clsn(s.logoLink, s.alignItemsBaseline)}
          onClick={() => {
            window.location.href = paths.dashboard
          }}
        >
          <span className={s.desktopOnly}>
            <img
              alt='Finxact'
              onError={onLogoLoadError}
              src={logoSrc}
              style={{ color: 'transparent' }}
            />
          </span>
          <div className={clsn(s.mobileOnly, s.p5)}>
            <div className={clsn(s.dFlex, s.alignItemsBaseline)}>
              <FinxactLogoWhite />
              <span className={s.activeMenu}>{activeMenu}</span>
            </div>
          </div>
        </div>

        {!consoleConfig.prodEnv ? (
          <div className={clsn(s.desktopOnly, s.dFlex)}>
            <a
              className={clsn(
                s.navLink,
                getActiveNavLinkPath() == paths.dashboard
                  ? s.active
                  : s.inactive
              )}
              href={paths.dashboard}
            >
              Dashboard
            </a>

            <a
              className={clsn(
                s.navLink,
                getActiveNavLinkPath() == paths.academy ? s.active : s.inactive
              )}
              href={paths.academy}
            >
              Academy
            </a>

            <a
              className={clsn(
                s.navLink,
                getActiveNavLinkPath() == paths.docs ? s.active : s.inactive
              )}
              href={paths.docs}
            >
              Docs
            </a>

            <a
              className={clsn(
                s.navLink,
                getActiveNavLinkPath() == paths.apis ? s.active : s.inactive
              )}
              href={paths.apis}
            >
              APIs
            </a>
          </div>
        ) : null}
      </div>

      <div className={clsn(s.rightPanel, s.desktopOnly)}>
        {user ? (
          <>
            <Dropdown className={s.userPanel}>
              <Dropdown.Toggle
                className={clsn(s.NavDropdownToggle, s.dropdownToggle)}
              >
                <FontAwesomeIcon className={s.userIcon} icon={faUserGear} />
              </Dropdown.Toggle>
              <Dropdown.Menu align={'end'}>
                <div className={s.dropdownMenuContainter}>
                  {isPermitted ? (
                    <SettingsDropdownSection
                      consoleConfig={consoleConfig}
                      isPermitted={isPermitted}
                      organization={organization}
                      user={user}
                    />
                  ) : null}
                  <div className={s.dropdownGrouping}>
                    <Dropdown.Header className={s.dropdownHeader}>
                      {fullName.toUpperCase()}
                    </Dropdown.Header>
                    <Dropdown.Item
                      className={s.dropdownItem}
                      href={constants.accountPath}
                    >
                      Profile
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={s.dropdownItem}
                      href={constants.accountPath}
                    >
                      Environments & keys
                    </Dropdown.Item>
                  </div>

                  <Dropdown.Divider className={s.dropdownDivider} />
                  <Dropdown.Item className={s.signOut} onClick={onLogout}>
                    Sign out
                  </Dropdown.Item>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </>
        ) : null}
        {!user ? (
          <div className={s.teaserLinksContainer}>
            <a className='' href='/profile-setup'>
              Sign up
            </a>
            |
            <a
              href='#'
              onClick={() => {
                window.location.href = `/console/login?returnPage=${window.location.pathname}`
              }}
            >
              Log in
            </a>
          </div>
        ) : null}
        {!consoleConfig.prodEnv ? (
          <SearchAndChat
            siteFunction='desktop'
            hasChatbotAccess={hasChatbotAccess}
            pathName={pathName}
            innerRef={searchBarRef}
            consoleConfig={consoleConfig}
            isAuthenticated={Boolean(user)}
            showFinxactPro={activateFinxactPro}
            toggleFinxactPro={toggleFinxactPro}
          />
        ) : null}
      </div>

      {windowWidth <= formattedMobileBreakpoint ? (
        <div className={s.mobileOnly}>
          <MobileNavigation
            {...props}
            consoleConfig={consoleConfig}
            nextRouterLinkComponent={nextRouterLinkComponent}
            reactRouterLinkComponent={reactRouterLinkComponent}
          />
        </div>
      ) : (
        ''
      )}
    </header>
  )
}
