import { ALGOLIA_RESULT_TYPES, RestrictedTags } from "../constants";

// construct URL for algolia search results here
export const constructURL = (type: string, uid: string, link: string) => {
  let url = "";
  if (type.toLowerCase() === ALGOLIA_RESULT_TYPES.document) {
    url = `/${ALGOLIA_RESULT_TYPES.docs}/document-detail/${ALGOLIA_RESULT_TYPES.document}/${uid}`;
  } else if (type.toLowerCase() === ALGOLIA_RESULT_TYPES.documentCenter) {
    url = `/${ALGOLIA_RESULT_TYPES.docs}/document-detail/${ALGOLIA_RESULT_TYPES.documentCenter}/${uid}`;
  } else if (type.toLowerCase() === ALGOLIA_RESULT_TYPES.tutorial) {
    url = `/${ALGOLIA_RESULT_TYPES.docs}/${ALGOLIA_RESULT_TYPES.tutorials}/detail-page/${uid}`;
  } else if (type.toLowerCase() === ALGOLIA_RESULT_TYPES.apiLibrary) {
    url = `/${ALGOLIA_RESULT_TYPES.apis}/detail-page/${ALGOLIA_RESULT_TYPES.apiLibrary}/${uid}`;
  } else if (type.toLowerCase() === ALGOLIA_RESULT_TYPES.training) {
    url = `/${ALGOLIA_RESULT_TYPES.docs}/document-detail/${ALGOLIA_RESULT_TYPES.training}/${uid}`;
  } else if (
    type.toLowerCase() === ALGOLIA_RESULT_TYPES.academy ||
    type.toLowerCase() === ALGOLIA_RESULT_TYPES.courses
  ) {
    url = `${link}`;
  } else if (type.toLowerCase() === ALGOLIA_RESULT_TYPES.console) {
    url = `/${uid}`;
  } else {
    url = `/${uid}`;
  }
  return `${url}`;
};

export const checkIfRestricted = function (documentTags: string[]) {
  // Check if any element of 'tags' exists in 'restrictedTags'
  if (documentTags?.length > 0) {
    return documentTags.some((tag) => RestrictedTags.includes(tag));
  }
  return false;
};
