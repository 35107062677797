export const featureFlags = {
  /**
   * CNSL-2819 Show assetCatalogue when in the correct core version it was introduced
   */
  includeAssetCatalogue: ">=1.12.0",

  /**
   * CNSL-3513 Multi-Asset: Console add (new) AssetExchRates model to Console>Architect>Bank
   */
  includeAssetExchRates: ">=1.15.0",

  /**
   * CNSL-4756
   */
  includeExternalReports: ">= 1.22.0",

  /**
   * CNSL-4672 risk profiles on Console>Architech>Risk Ratings
   */
  includeRiskRatings: ">= 1.22.0",
};
