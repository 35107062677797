import React, { useState, useEffect } from "react";
import clsn from "classnames";
import s from "./MobileMenu.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/pro-regular-svg-icons";
import { deepCopy, replaceItemsInList } from "../../utils";
import { useSubRoute } from "../../hooks/common";
import { FinxactLogoBlack } from "../../assets/images";

type BreadCrumb = {
  title: string;
  path: string;
};
type MenuItem = {
  icon?: any;
  title: string;
  childrens: MenuItem[];
  path: string;
  isLegacy?: boolean;
  isSelected?: boolean;
  type: "header" | "item" | "none" | "accordion";
  uid: string;
  inclusive?: boolean;
  coreOperation?: string | string[];
  consoleOperation?: string;
};
type DocCenterNavigationData = {
  breadCrumbs: BreadCrumb[];
  loading: boolean;
  docCenterNavigationItems: MenuItem[];
};
type Props = {
  navigationData: DocCenterNavigationData | any;
  nextRouterLinkComponent: any;
  reactRouterLinkComponent: any;
  parent: "cnsl" | "fast";
  external?: false;
  user?: {
    firstName: string | null;
    lastName: string | null;
    superUser: boolean;
  };
  toggleNavbar?: () => void;
};

type HeaderProps = {
  navigationData: DocCenterNavigationData | any;
  toggleNavbar?: () => void;
  className?: string;
  consoleConfig: {
    prodEnv: boolean;
    enableEcosystem?: boolean;
    searchAppId?: string;
    searchKey?: string;
    searchIndex?: string;
  };
  org?: {
    id: number;
    name: string;
    serviceTicketURL?: string | null;
    implementationUrl?: string | null;
    implRepoURL?: string | null;
  };
  pathName: string;
  isPermitted: (
    mode: "cnsl" | "core" | "primary-org-cnsl",
    value: string | RegExp | string[] | undefined,
    inclusive?: boolean | undefined
  ) => boolean;
  isTeaser?: boolean;
  organization?: {
    id: number;
    phase: string;
  };
  primaryOrg?: {
    category: string;
  };
  user?: {
    firstName?: string | null;
    lastName?: string | null;
    superUser?: boolean;
  };
  defaultLogo: string;
  hamburgerIcon: string;
  closeIcon: string;
  chatOpenIcon?: string;
  chatClosedIcon?: string;
  // These are passed in until we move them to the shared location as well in future tickets
  orgEnvSelector?: any;
  searchBar?: any;
  menuData?: any;
  reactRouterLinkComponent?: any;
  nextRouterLinkComponent?: any;
  parent: "cnsl" | "fast";
  project?: {
    id: number;
    name: string;
    status?: string;
  };
  coreVersion?: string;
  environment?: {
    id: number;
    name: string;
    tier?: string;
    endpoint: string;
    internal?: boolean;
  };
  serviceEndpoint?: string;
};

export default function MobileMenu({
  navigationData,
  nextRouterLinkComponent,
  reactRouterLinkComponent,
  parent,
  toggleNavbar,
  user,
}: HeaderProps) {
  const NextLink = nextRouterLinkComponent;
  const ReactLink = reactRouterLinkComponent;
  const { subRoute: uidPath } = useSubRoute();
  const [menuData, setMenuData] = useState<MenuItem[][]>([]);

  /**
   * Maintains Original Menu in [0] index
   * Maintains Selected Menu stack in [1] index
   */
  const [selectedMenuList, setSelectedMenuList] = useState<MenuItem[]>([]);
  const [bookmarkList, setBookmarkList] = useState<string[]>([]);
  const [bookmarkTitle, setBookmarkTitle] = useState<string>();

  const router = (() => {
    if (NextLink && parent === "fast") return "next";
    if (ReactLink && parent === "cnsl") return "react";
    return "none";
  })();

  useEffect(() => {
    if (navigationData?.docCenterNavigationItems?.length > 0) {
      const menuData = navigationData.docCenterNavigationItems[0]?.childrens
        ? [navigationData.docCenterNavigationItems[0]?.childrens]
        : [];
      setMenuData(menuData);
      if (menuData?.length) {
        const menu = deepCopy(menuData?.at(-1));
        // Constructing default menu selection as per route path
        setInitialCurrMenu(menu);
      }
    }
  }, [navigationData]);

  function setInitialCurrMenu(mainMenu: MenuItem[]) {
    mainMenu.some((item) => {
      if (item.uid === uidPath) {
        setSelectedMenuList(mainMenu);
        return true;
      }
      const titleList = item.title ? [item.title] : [];
      return item?.childrens?.length
        ? searchTheMenu(item.childrens, [], 0, titleList, titleList.length)
        : false;
    });
  }

  function searchTheMenu(
    subMenu: MenuItem[],
    currMenuList: MenuItem[][],
    currMenuIndex = 0,
    titleList: string[],
    titleIndex = 0
  ) {
    return subMenu.some((subItem: MenuItem) => {
      currMenuList = replaceItemsInList(currMenuList, subMenu, currMenuIndex);
      titleList = replaceItemsInList(titleList, subItem.title, titleIndex);
      if (subItem.uid === uidPath) {
        setDefaultMenuAndBookmark(currMenuList, titleList);
        return true;
      } else if (subItem?.childrens?.length) {
        const active = searchTheMenu(
          [...subItem.childrens],
          currMenuList,
          currMenuList?.length,
          titleList,
          titleList.length
        );
        if (active) {
          return true;
        }
      }
    });
  }

  function setDefaultMenuAndBookmark(
    menuList: MenuItem[][],
    titleList: string[]
  ) {
    if (menuList?.length) {
      setMenuData((prev: MenuItem[][]) => [...prev, ...menuList]);
      if (titleList?.length) {
        setBookmarkList([...titleList]);
      }
    }
  }

  function isActive(navData: any, currUid: string) {
    if (currUid) {
      if (currUid === navData?.uid) {
        return true;
      } else if (navData?.childrens?.length) {
        for (const item of navData.childrens) {
          if (item.uid === currUid) {
            return true;
          } else if (item.childrens?.length) {
            const active = isActive(item, currUid);
            if (active) {
              return true;
            }
          }
        }
      }
    }
  }

  const handleArrowClick = (
    childrens: MenuItem[],
    index: number,
    item: any
  ) => {
    if (childrens?.length) {
      setBookmarkList([...bookmarkList, item.title]);
      setMenuData((prev) => [...prev, childrens]);
    }
  };

  const handleBackClick = () => {
    setMenuData((prev) => prev.slice(0, prev.length - 1));
    setBookmarkList((prev) => prev.slice(0, prev.length - 1));
  };

  useEffect(() => {
    // resetting all the titles on reaching stating of the menu stack
    if (menuData?.length === 1 && bookmarkList?.length) {
      setBookmarkList([]);
    }
    // Set the curr menu stack
    if (menuData?.length) {
      setSelectedMenuList(menuData.at(-1));
    }
  }, [menuData]);

  useEffect(() => {
    if (bookmarkList?.length) {
      setBookmarkTitle(bookmarkList[menuData.length - 2]);
    }
  }, [bookmarkList]);

  const renderMenuLink = (item: any, index: number) => {
    // If parent menu doesn't have route path, showing the subMenu to user
    if (!item.path) {
      return (
        <span onClick={() => handleArrowClick(item.childrens, index, item)}>
          {item.title}
        </span>
      );
    }

    switch (router) {
      case "none":
        return <a href={item.path || "#"}>{item.title}</a>;
      case "react":
        return (
          <ReactLink
            data-robots={item.path}
            to={item.path || "#"}
            onClick={toggleNavbar}
          >
            {item.title}
          </ReactLink>
        );
      case "next":
        return (
          <NextLink
            data-robots={item.path}
            href={item.path || "#"}
            onClick={toggleNavbar}
          >
            {item.title}
          </NextLink>
        );
    }
  };
  return (
    <div className={clsn(s.menuContainer, !user ? s.p80 : "")}>
      {menuData.length > 1 && (
        <div
          className={s.backButtonContainer}
          onClick={() => handleBackClick()}
        >
          <FontAwesomeIcon
            className={clsn(s.chevronIcon)}
            icon={faChevronLeft}
          />
          {bookmarkTitle}
        </div>
      )}
      {
        <ul className={clsn(s.menuList, menuData.length > 1 ? s.mTop : "")}>
          {selectedMenuList?.length
            ? selectedMenuList.map((item, index) => (
                <li
                  key={index}
                  className={isActive(item, uidPath) ? s.activeMenu : ""}
                >
                  {renderMenuLink(item, index)}
                  {item.childrens && item.childrens.length > 0 && (
                    <span
                      className={s.chevronIconContainer}
                      onClick={() =>
                        handleArrowClick(item.childrens, index, item)
                      }
                    >
                      <FontAwesomeIcon
                        className={clsn(s.chevronIcon)}
                        icon={faChevronRight}
                      />
                    </span>
                  )}
                </li>
              ))
            : ""}
        </ul>
      }
      
        <div className={s.mobileFooter}>
          <div className={s.logoLink}>
            <FinxactLogoBlack />
          </div>
          {!user ? (
            <div className="flex">
              <a className={clsn(s.btn, s.btnEnroll)} href="/profile-setup">
                Enroll
              </a>
              <a className={clsn(s.btn, s.btnLogin)} href="/dashboard">
                Login
              </a>
            </div>
          ) : null}
        </div>
    </div>
  );
}
