import React from 'react'
import s from './ErrorAccess.scss'
import { LockIcon } from '@/assets/images'
import { Button } from 'react-bootstrap'

type ErrorAccessProps = {
  data: {
    envId: number | null
    entitlement: boolean | null
    projId: number | undefined
  }
}
export function ErrorAccess(props: ErrorAccessProps) {
  const { envId, entitlement, projId } = props.data
  let title
  let description

  if (!projId && !envId) {
    title = 'Environment selection required'
    description =
      'You do not have an environment selected. Please select an environment to use this feature.'
  } else if (entitlement) {
    title = 'Apologies, you cannot view this page'
    description =
      'You do not have the role permissions to access this feature. Please contact your administrator to request access.'
  }
  return (
    <div className={s.errorInfoContainer}>
      <div className={s.content}>
        <LockIcon />
        <div className={s.title}>{title}</div>
        <div className={s.description}>{description}</div>
        {!projId && !envId && (
          <Button
            className={s.appBtnSecondary}
            onClick={() => {
              window.location.href = `/dashboard`
            }}
          >
            Return to Dashboard Home
          </Button>
        )}
      </div>
    </div>
  )
}
