import React from 'react'
import {
  faCheckCircle,
  faCircle,
  faQuestionCircle,
  faTimesCircle,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { constants, extOpenProjectPath } from '../../constants'
import s from './EnvOrgIndicator.module.scss'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

type Props = {
  org?: {
    name: string
  }
  env?: {
    id: number
    name: string
  }
  proj?: {
    id: number
    name: string
  }
  imageTag?: string
  vsn?: string
  up?: boolean
  isPermitted: (
    mode: 'cnsl' | 'core' | 'primary-org-cnsl',
    value: string | RegExp | string[] | undefined,
    inclusive?: boolean | undefined
  ) => boolean
}

/**
 * Displays three lines of information:
 *  1 - The organization name (or messaging if none selected)
 *  2 - The environment name (or messaging if none selected)
 *  3 - Environment connection status & core version
 * @param props
 * @returns
 */
export default function EnvOrgIndicator(props: Props) {
  const { env, imageTag, proj, up, vsn, isPermitted } = props

  const { accountPath, environmentAdminPath } = constants

  // Org name
  const orgName = props.org?.name ?? 'No organization selected'

  // Environment name
  let envName = 'No environment selected'
  if (env) {
    envName = env.name
  } else if (proj) {
    envName = proj.name
  }

  // Status Icon
  let icon
  let iconClsn
  if (up) {
    icon = faCheckCircle
    iconClsn = s.connected
  } else if (env || proj) {
    icon = faTimesCircle
    iconClsn = s.disconnected
  } else {
    icon = faCircle
    iconClsn = ''
  }

  // Status
  const status = up
    ? `Connected - ${vsn ? vsn : 'Unknown Version'}`
    : 'Unable to Connect'

  /**
   * When in a project, take me to the project dashboard; when in an environment, take
   * me to either (a) the environment edit if I have permissions, or (b) the environment
   * list on my profile if I do not.
   */
  const onClick = () => {
    if (proj) {
      document.location = `${extOpenProjectPath}${proj.id}`
    } else if (env && isPermitted('cnsl', 'admin-environment-list')) {
      document.location = `${environmentAdminPath}${env.id}`
    } else {
      document.location = accountPath
    }
  }

  return (
    <div className={s.navOrgEnvIndicator}>
      <div className={s.gradient} />
      <div className={s.main}>
        <div>{orgName}</div>
        <div onClick={onClick} role='button'>
          {envName}
        </div>
        {env || proj ? (
          <div onClick={onClick} role='button'>
            <FontAwesomeIcon className={iconClsn} icon={icon} />
            &nbsp;
            {status}
            {
              imageTag ? (
                <FontAwesomeIcon className='ms-2' icon={faQuestionCircle} title={imageTag} />
              ) : null
            }
          </div>
        ) : null}
      </div>
    </div>
  )
}
