import React from 'react'

type Props = {
  children: React.ReactNode
  if?: boolean
}

const Hide = (props: Props) => {
  if (props.if) return null

  return <>{props.children}</>
}

export default Hide
