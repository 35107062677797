const acctGroupSchema = {
  'ui:widget': 'fxtSelectWidget',
  'ui:options': {
    apis: [
      {
        api: '/model/acctGroup',
        keyObject: 'acctGroup',
        valObject: 'desc',
        where: 'acctClass=2',
      },
    ],
  },
}

const acctNbrSchema = {
  'ui:widget': 'fxtSelectWidget',
  'ui:options': {
    apis: [
      {
        api: '/model/acct_gl',
        keyObject: 'acctNbr',
        valObject: 'acctTitle',
      },
    ],
    includeKeyInDesc: true,
    windowed: true,
  },
}


const uiSchema = {
    'ui:order': ['glSetCode', 'desc', 'balAcctGroup', 'balAcctNbr', '*'],
    balAcctGroup: acctGroupSchema,
    subBalAccts: {
      'ui:field': 'fxtPatternPropField',
      'ui:propertyLabels': {
        accrInt: 'Accrued Interest',
        negAccr: 'Negative Accrued Interest',
        fee: 'Fee',
        penalty: 'Penalty',
        wthFed: 'Federal Withholding',
        wthState: 'State Withholding',
        avlInt: 'Available Interest',
        depFee: 'Deposit Fees',
      },
      options: {
        apis: [
          {
            api: '/model/glSubBalType',
            keyObject: 'name',
            valObject: 'desc',
          },
        ],
        includeKeyInDesc: true,
      },
      patternProperties: {
        acctGroup: acctGroupSchema,
        acctNbr: acctNbrSchema,
        incAcctNbr: acctNbrSchema,
        expAcctNbr: acctNbrSchema,
      },
    },
  }
  
export default uiSchema