import React, { useMemo, useRef } from 'react'
import { Nav, NavCategory, NavGroup, NavItem } from '../Nav'
import s from './Menu.module.scss'
import {
  faBarsStaggered,
  faBellConcierge,
  faBook,
  faBuildingColumns,
  faChevronCircleLeft,
  faChevronCircleRight,
  faCommentsQuestion,
  faCube,
  faHomeBlank,
  faLayerGroup,
  faWavePulse,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { consoleOperations, constants, ORG_STATUS } from '../../constants'
import { featureFlags } from '../../featureFlags'
import {
  Route,
  routes,
  routesArray,
  foundationalSetupRoutes,
  prospectDashboardRoute,
} from '../../routes'
import { HTMLAttributes, useEffect, useState, useCallback } from 'react'
import { coreServerVersionSatisfies } from '../../utils'
import EnvOrgIndicator from './EnvOrgIndicator'
import Hide from './Hide'
import { buildSwaggerEndpoint } from '../../utils/buildSwaggerEndpoint'
import classNames from 'classnames'
import { OrgEnvDropdown } from '../OrgEnvDropdown'
import {
  Environment,
  Organization,
  Project,
} from '../OrgEnvDropdown/OrgEnvDropdown'

const { coreVersionsSwaggerView, s3TrainingSiteView } = consoleOperations
/**
 * Given react router path, lookup the route id
 * @param path
 * @returns the route id or empty string if no active route found
 */
const findRouteIdByPath = (path: string) => {
  const allRoutes = [...Object.values(foundationalSetupRoutes), ...routesArray]

  const pathWithoutHash = path.split('#')[0]

  if (
    pathWithoutHash === '/dashboard' ||
    pathWithoutHash === prospectDashboardRoute.path
  ) {
    return 'fast-dashboard'
  }

  // strict match first
  for (const rt of allRoutes) {
    if (pathWithoutHash === rt.path!) {
      return rt.id
    }
  }

  // then fuzzy match
  for (const rt of allRoutes) {
    if (pathWithoutHash.startsWith(rt.path!)) {
      return rt.id
    }
  }

  return ''
}

type Props = {
  consoleConfig: {
    prodEnv: boolean
    releaseCalendarConfigured: boolean
  }
  coreVersion?: string
  environment?: {
    id: number
    name: string
    tier?: string
    endpoint: string
    internal?: boolean
  }
  imageTag?: string
  isPermitted: (
    mode: 'cnsl' | 'core' | 'primary-org-cnsl',
    value: string | RegExp | string[] | undefined,
    inclusive?: boolean | undefined
  ) => boolean
  organization?: {
    id: number
    name: string
    serviceTicketURL?: string | null
    implementationUrl?: string | null
    implRepoURL?: string | null
    category: string
    status: keyof typeof ORG_STATUS
  }
  parent: 'cnsl' | 'fast'
  project?: {
    id: number
    name: string
    status?: string
  }
  serviceEndpoint?: string
  // OrgEnvDropdown
  envs: Environment[]
  orgs: Organization[]
  onOrgSelect: (id: number) => void
  onEnvSelect: (id: number) => void
  onProjSelect: (id: number) => void
  projs: Project[]

  // These are passed in to allow for custom routing components (both NEXTJS and React Router)
  reactRouterLinkComponent?: any
  nextRouterLinkComponent?: any
  location: string
}

/**
 * The Menu on the left hand side of the page.  The menu component renders a Nav.  While
 * Nav is generic and could be used in other contexts (or even applications), Menu is
 * specifically for Finxact Console and deals directly with our routes.
 * @param props
 * @returns
 */
export function Menu(props: Props & HTMLAttributes<HTMLDivElement>) {
  // Get currently active route
  const activeRoute = findRouteIdByPath(props.location)

  // Establish state using currently active route
  const [active, setActive] = useState(activeRoute)
  const [showOrgEnvTooltip, toggleOrgEnvTooltip] = useState(false)

  // Expanded?
  const [expanded, setExpanded] = useState(true)
  const toggleExpanded = useCallback(() => {
    setExpanded(!expanded)
  }, [expanded])
  const [initialNavOpenGroups, setInitialNavOpenGroups] = useState<string[]>([])

  const orgEnvSelectorRef = useRef<HTMLButtonElement>(null)

  // On route change, switch active route
  useEffect(() => {
    const routeId = findRouteIdByPath(props.location)
    setActive(routeId)
  }, [props.location])

  const {
    consoleConfig,
    coreVersion,
    environment,
    envs,
    imageTag,
    isPermitted,
    onEnvSelect,
    onOrgSelect,
    onProjSelect,
    organization,
    orgs,
    projs,
    project,
    serviceEndpoint,
  } = props

  const allOrgsGroup = organization?.id === constants.allOrganizationsId
  const serviceTicketURL = organization?.serviceTicketURL
  const implementationSiteUrl = organization?.implementationUrl
  const implementationRepoUrl = organization?.implRepoURL
  const dashboardLinksToFast = isPermitted('cnsl', 'workflow-dashboard-access')
  const dashboardLink = dashboardLinksToFast ? '/dashboard' : '/resourcesDash'

  const footer = (
    <EnvOrgIndicator
      env={environment ?? undefined}
      imageTag={imageTag}
      isPermitted={isPermitted}
      org={props.organization}
      proj={project ?? undefined}
      up={coreVersion != null}
      vsn={coreVersion}
    />
  )

  const {
    acctGroupRoute,
    acctTypeRoute,
    acct_glRoute,
    acctgSegRoute,
    accumTrnLimitRoute,
    achBatchExceptionRoute,
    achBatchRoute,
    achCorporateOriginationRoute,
    achFedReversalRoute,
    achRoute,
    achOriginInstructionRoute,
    assetCatalogueRoute,
    assetClassRoute,
    assetExchRatesRoute,
    bankparamRoute,
    bankparamUSBankInfoRoute,
    batchInformationRoute,
    batchTransactionRoute,
    buildsListRoute,
    calendarRoute,
    ccyCodeRoute,
    channelRoute,
    checkRoute,
    chkFilePostInstrRoute,
    choicesRoute,
    cntryRoute,
    cntrySubdivisionRoute,
    componentCollateralRoute,
    componentDepChrgOffRoute,
    componentFeeRoute,
    componentIntRoute,
    componentLimitRoute,
    componentLnParamsRoute,
    componentNsfRoute,
    componentRepayRoute,
    componentRewardRoute,
    componentRulesRoute,
    componentTdRoute,
    componentTrnOptRoute,
    configSystemAcctRoute,
    custRelTypeRoute,
    dashboardRoute,
    deploymentsListRoute,
    diagnosticsDashboardRoute,
    documentationRoute,
    domainRoute,
    dslEditorRoute,
    emptyCacheRoute,
    environmentHealthRoute,
    errLogRoute,
    escheatExtractsRoute,
    etlRoute,
    eventContextRoute,
    exceptRuleRoute,
    extDashboardRoute,
    externalReportsRoute,
    fdic370Route,
    feeCalcDtlRoute,
    fidmProcessingRoute,
    fileFmtRoute,
    freqAndDurMapRoute,
    freqModelerRoute,
    fundsAvlCatRoute,
    fundsAvlHoldRoute,
    glBalancingRoute,
    glExtractFilterRoute,
    glExtractsRoute,
    glSetRoute,
    holdTemplateRoute,
    journalRoute,
    lstseqRoute,
    matrixRoute,
    merchantGroupRoute,
    networkRoute,
    newsRoute,
    openExceptionsExtractsRoute,
    orderACHRoute,
    orderCORERoute,
    orderFEDWIRERoute,
    orderTemplateRoute,
    partyGroupRoute,
    partyRoute,
    partyUSBankInfoRoute,
    posnCalEventsRoute,
    posnRoleTypeRoute,
    posn_glRoute,
    prodGroupRoute,
    prodProgramGroupRelRoute,
    prodProgramGroupRoute,
    prodProgramRoute,
    prodSubTypeRoute,
    prodSvcRoute,
    prodTypeRoute,
    prod_bkLnDelqRoute,
    prod_bkRoute,
    prod_cardRoute,
    prod_launchpadRoute,
    prod_usTbpRoute,
    projectListRoute,
    promoCodeDtlRoute,
    rateIndexRoute,
    reconBatchRoute,
    reconTrnRoute,
    releaseCalendarRoute,
    releaseRoute,
    reportsRoute,
    restrictRuleRoute,
    riskProfileRoute,
    rolePermissionRoute,
    roleRoute,
    ruleLogRoute,
    sampleAPIsRoute,
    schemaAndChoicesRoute,
    schemaOverviewRoute,
    schemaRoute,
    stopRoute,
    swaggerCoreRoute,
    sweepRoute,
    systemCalendarRoute,
    systemCalendarTimelineRoute,
    taxFilesAndExtractsRoute,
    tguidDecodeRoute,
    tguidEncodeRoute,
    timeshiftsListRoute,
    trnCodeRoute,
    trnLimitRoute,
    trnStatGroupRoute,
    usTbpTypeRoute,
  } = routes

  const { includeExternalReports } = featureFlags
  const { externalDocsPath } = constants

  let swaggerEndpoint
  if (props.environment) {
    try {
      swaggerEndpoint = buildSwaggerEndpoint(
        props.environment?.endpoint,
        !!props.environment?.internal
      )
    } catch (e) {
      console.error('could not build swagger endpoint', e)
    }
  }

  const routeFilter = (route: Route) => {
    if (
      route.coreOperation &&
      !isPermitted('core', route.coreOperation, route.inclusive)
    ) {
      return false
    }

    if (
      route.consoleOperation &&
      !isPermitted('cnsl', route.consoleOperation)
    ) {
      return false
    }

    if (
      route.validCoreVns &&
      !coreServerVersionSatisfies(route.validCoreVns, props.coreVersion)
    ) {
      return null
    }

    return true
  }

  const routeHeirarchy = {
    implementation: [
      extDashboardRoute,
      projectListRoute,
      buildsListRoute,
      deploymentsListRoute,
      timeshiftsListRoute,
      environmentHealthRoute,
      ruleLogRoute,
    ].filter(routeFilter),
    foundationalSetup: Object.values(foundationalSetupRoutes).filter(
      routeFilter
    ),
    architect: {
      product: {
        taxBenefitPlans: [prod_usTbpRoute, usTbpTypeRoute].filter(routeFilter),
        heirarchy: [prodTypeRoute, prodSubTypeRoute, prodGroupRoute].filter(
          routeFilter
        ),
        components: [
          componentCollateralRoute,
          componentDepChrgOffRoute,
          componentFeeRoute,
          componentIntRoute,
          componentLimitRoute,
          componentLnParamsRoute,
          componentNsfRoute,
          componentRepayRoute,
          componentRewardRoute,
          componentRulesRoute,
          componentTdRoute,
          componentTrnOptRoute,
        ].filter(routeFilter),
        limits: [trnLimitRoute, accumTrnLimitRoute].filter(routeFilter),
        relationshipRewards: [
          prodProgramRoute,
          prodProgramGroupRoute,
          prodProgramGroupRelRoute,
        ].filter(routeFilter),
        other: [prod_bkLnDelqRoute, prodSvcRoute, sweepRoute].filter(
          routeFilter
        ),
      },
      generalLedger: [
        acctgSegRoute,
        acct_glRoute,
        posn_glRoute,
        glSetRoute,
        configSystemAcctRoute,
      ].filter(routeFilter),
      bank: {
        accountsAndPositions: [
          acctGroupRoute,
          acctTypeRoute,
          glExtractFilterRoute,
        ].filter(routeFilter),
        bank: [
          assetCatalogueRoute,
          assetClassRoute,
          calendarRoute,
          bankparamRoute,
          bankparamUSBankInfoRoute,
          ccyCodeRoute,
          channelRoute,
          chkFilePostInstrRoute,
          cntryRoute,
          cntrySubdivisionRoute,
          domainRoute,
          fileFmtRoute,
          freqAndDurMapRoute,
          matrixRoute,
          lstseqRoute,
          systemCalendarRoute,
        ].filter(routeFilter),
        parties: [custRelTypeRoute, partyGroupRoute, posnRoleTypeRoute].filter(
          routeFilter
        ),
        paymentNetwork: [
          prod_cardRoute,
          partyRoute,
          partyUSBankInfoRoute,
          merchantGroupRoute,
          networkRoute,
          riskProfileRoute,
        ].filter(routeFilter),
        rates: [assetExchRatesRoute, promoCodeDtlRoute, rateIndexRoute].filter(
          routeFilter
        ),
        roles: [roleRoute, rolePermissionRoute].filter(routeFilter),
        transactions: [
          feeCalcDtlRoute,
          fundsAvlCatRoute,
          fundsAvlHoldRoute,
          holdTemplateRoute,
          stopRoute,
          orderTemplateRoute,
          exceptRuleRoute,
          restrictRuleRoute,
          trnCodeRoute,
          trnStatGroupRoute,
        ].filter(routeFilter),
      },
      schemaExtension: [schemaRoute, choicesRoute].filter(routeFilter),
      behaviorExtension: [dslEditorRoute].filter(routeFilter),
      utilites: [freqModelerRoute, emptyCacheRoute].filter(routeFilter),
      utilties_tguid: [tguidEncodeRoute, tguidDecodeRoute].filter(routeFilter),
    },
    diagnosticsDashboard: [diagnosticsDashboardRoute].filter(routeFilter),
    diagnostics: {
      general: [errLogRoute, journalRoute, etlRoute].filter(routeFilter),
      balancingAndRecon: [
        glBalancingRoute,
        reconBatchRoute,
        reconTrnRoute,
      ].filter(routeFilter),
      events: [
        systemCalendarTimelineRoute,
        posnCalEventsRoute,
        eventContextRoute,
      ].filter(routeFilter),
    },
    operations: {
      achCorporate: [
        achCorporateOriginationRoute,
        achBatchRoute,
        achBatchExceptionRoute,
        achOriginInstructionRoute,
      ].filter(routeFilter),
      batchFileMgmt: [
        batchInformationRoute,
        achRoute,
        checkRoute,
        batchTransactionRoute,
      ].filter(routeFilter),
      orders: [orderACHRoute, orderCORERoute, orderFEDWIRERoute].filter(
        routeFilter
      ),
      reports: [reportsRoute].filter(routeFilter),
      reportsAndExtracts: [
        glExtractsRoute,
        escheatExtractsRoute,
        fidmProcessingRoute,
        openExceptionsExtractsRoute,
        taxFilesAndExtractsRoute,
      ].filter(routeFilter),
      other: [achFedReversalRoute, fdic370Route].filter(routeFilter),
    },
  }

  function setOpenGroupState(openGroupItem: string) {
    if (openGroupItem && !initialNavOpenGroups?.includes?.(openGroupItem)) {
      initialNavOpenGroups.push(openGroupItem)
      setInitialNavOpenGroups([...initialNavOpenGroups])
    } else if (!openGroupItem && initialNavOpenGroups?.length) {
      // resetting it on nav click
      setInitialNavOpenGroups([])
    }
  }

  // If an env/prj is not selected
  const isNavItemDisabled = useMemo(() => {
    return !(environment?.id || project?.id) || !coreVersion
  }, [environment, project, coreVersion])

  // Handler to show/hide tooltip
  const _handleMouseEvent = (value: boolean) => () => {
    if (isNavItemDisabled) {
      toggleOrgEnvTooltip(value)
    }
  }

  const _handleEnvSelect = (envId: number) => {
    // remove red highlight on env selector
    orgEnvSelectorRef.current?.classList.remove('actionable')
    onEnvSelect(envId)
  }

  const divider = <hr className={s.divider} />

  const header = (
    <div>
      {expanded && (
        <OrgEnvDropdown
          env={environment ?? undefined}
          envs={envs}
          onEnvSelect={_handleEnvSelect}
          onOrgSelect={onOrgSelect}
          onProjSelect={onProjSelect}
          org={organization ?? undefined}
          orgs={orgs}
          proj={project ?? undefined}
          projs={projs}
          orgEnvSelectorRef={orgEnvSelectorRef}
          showTooltip={showOrgEnvTooltip}
        />
      )}
    </div>
  )

  return (
    <Nav
      isDashboard={true}
      active={active}
      className={classNames(
        props.className,
        expanded ? s.expanded : s.collapsed
      )}
      header={header}
      footer={footer}
      isPermitted={isPermitted}
      isProd={consoleConfig.prodEnv}
      location={props.location}
      parent={props.parent}
      reactRouterLinkComponent={props.reactRouterLinkComponent}
      nextRouterLinkComponent={props.nextRouterLinkComponent}
      initialOpenGroups={
        initialNavOpenGroups?.length ? initialNavOpenGroups : []
      }
    >
      {/* Dashboard */}
      <NavItem
        closeOtherGroupsOnClick={true}
        id='fast-dashboard'
        fast={dashboardLinksToFast ? true : undefined}
        label='Home'
        link={dashboardLink}
        icon={faHomeBlank}
        tier={1}
      />

      {/* Foundational Setup */}
      {isPermitted('cnsl', 'workflow-dashboard-access') ? (
        <NavGroup
          id='fast-foundational-setup'
          label='Foundational Setup'
          tier={1}
          icon={faBuildingColumns}
          accordion={true}
          sendOpenGroupItem={setOpenGroupState}
          disabled={isNavItemDisabled}
          onMouseEvent={_handleMouseEvent}
        >
          {routeHeirarchy.foundationalSetup.map((route) => (
            <NavItem
              key={route.id}
              fast
              id={route.id}
              label={route.label}
              link={route.path}
              tier={2}
            />
          ))}
        </NavGroup>
      ) : null}

      {/* Architect */}
      {!allOrgsGroup && (!project || project.status !== 'conflicted') && (
        <NavGroup
          id='architect'
          label='Architect'
          tier={1}
          icon={faCube}
          accordion={true}
          disabled={isNavItemDisabled}
          onMouseEvent={_handleMouseEvent}
          sendOpenGroupItem={setOpenGroupState}
        >
          <NavCategory label='Configuration'>
            <NavGroup id='product-config' label='Product' tier={2}>
              {isPermitted('core', prod_bkRoute.coreOperation) ? (
                <>
                  {isPermitted('cnsl', 'workflow-dashboard-access') ? (
                    <>
                      <NavItem
                        key={prod_launchpadRoute.id}
                        id={prod_launchpadRoute.id}
                        fast
                        label={prod_launchpadRoute.label}
                        link={prod_launchpadRoute.path}
                        tier={3}
                      />
                      <NavItem
                        key={prod_bkRoute.id}
                        id={prod_bkRoute.id}
                        label={prod_bkRoute.label}
                        link={prod_bkRoute.path}
                        tier={3}
                      />
                    </>
                  ) : (
                    <NavItem
                      id={prod_bkRoute.id}
                      label='Product Launchpad'
                      link={prod_bkRoute.path}
                      tier={3}
                    />
                  )}
                </>
              ) : null}
              <NavCategory label='Tax Benefit Plans'>
                {routeHeirarchy.architect.product.taxBenefitPlans.map(
                  (route) => (
                    <NavItem
                      key={route.id}
                      id={route.id}
                      label={route.label}
                      link={route.path}
                      tier={3}
                    />
                  )
                )}
              </NavCategory>
              <NavCategory label='Hierarchy'>
                {routeHeirarchy.architect.product.heirarchy.map((route) => (
                  <NavItem
                    key={route.id}
                    id={route.id}
                    label={route.label}
                    link={route.path}
                    tier={3}
                  />
                ))}
              </NavCategory>
              <NavCategory label='Components'>
                {routeHeirarchy.architect.product.components.map((route) => (
                  <NavItem
                    key={route.id}
                    id={route.id}
                    label={route.label}
                    link={route.path}
                    tier={3}
                  />
                ))}
              </NavCategory>
              <NavCategory label='Limits'>
                {routeHeirarchy.architect.product.limits.map((route) => (
                  <NavItem
                    key={route.id}
                    id={route.id}
                    label={route.label}
                    link={route.path}
                    tier={2}
                  />
                ))}
              </NavCategory>
              <NavCategory label='Relationship Rewards'>
                {routeHeirarchy.architect.product.relationshipRewards.map(
                  (route) => (
                    <NavItem
                      key={route.id}
                      id={route.id}
                      label={route.label}
                      link={route.path}
                      tier={3}
                    />
                  )
                )}
              </NavCategory>
              <NavCategory label='Other'>
                {routeHeirarchy.architect.product.other.map((route) => (
                  <NavItem
                    key={route.id}
                    id={route.id}
                    label={route.label}
                    link={route.path}
                    tier={3}
                  />
                ))}
              </NavCategory>
            </NavGroup>
            <NavGroup
              id='general-ledger-config'
              label='General Ledger'
              tier={2}
            >
              {routeHeirarchy.architect.generalLedger.map((route) => (
                <NavItem
                  key={route.id}
                  id={route.id}
                  label={route.label}
                  link={route.path}
                  tier={3}
                />
              ))}
            </NavGroup>
            <NavGroup id='bank-config' label='Bank' tier={2}>
              <NavCategory label='Accounts and Positions'>
                {routeHeirarchy.architect.bank.accountsAndPositions.map(
                  (route) => (
                    <NavItem
                      key={route.id}
                      id={route.id}
                      label={route.label}
                      link={route.path}
                      tier={3}
                    />
                  )
                )}
              </NavCategory>
              <NavCategory label='Bank'>
                {routeHeirarchy.architect.bank.bank.map((route) => (
                  <NavItem
                    key={route.id}
                    id={route.id}
                    label={route.label}
                    link={route.path}
                    tier={3}
                  />
                ))}
              </NavCategory>
              <NavCategory label='Parties'>
                {routeHeirarchy.architect.bank.parties.map((route) => (
                  <NavItem
                    key={route.id}
                    id={route.id}
                    label={route.label}
                    link={route.path}
                    tier={3}
                  />
                ))}
              </NavCategory>
              <NavCategory label='Payment Network'>
                {routeHeirarchy.architect.bank.paymentNetwork.map((route) => (
                  <NavItem
                    key={route.id}
                    id={route.id}
                    label={route.label}
                    link={route.path}
                    tier={3}
                  />
                ))}
              </NavCategory>
              <NavCategory label='Rates'>
                {routeHeirarchy.architect.bank.rates.map((route) => (
                  <NavItem
                    key={route.id}
                    id={route.id}
                    label={route.label}
                    link={route.path}
                    tier={3}
                  />
                ))}
              </NavCategory>
              <NavCategory label='Roles'>
                {routeHeirarchy.architect.bank.roles.map((route) => (
                  <NavItem
                    key={route.id}
                    id={route.id}
                    label={route.label}
                    link={route.path}
                    tier={3}
                  />
                ))}
              </NavCategory>
              <NavCategory label='Transactions'>
                {routeHeirarchy.architect.bank.transactions.map((route) => (
                  <NavItem
                    key={route.id}
                    id={route.id}
                    label={route.label}
                    link={route.path}
                    tier={3}
                  />
                ))}
              </NavCategory>
            </NavGroup>
          </NavCategory>
          {project && (
            <NavCategory label='Schema Extension'>
              {routeHeirarchy.architect.schemaExtension.map((route) => (
                <NavItem
                  key={route.id}
                  id={route.id}
                  label={route.label}
                  link={route.path}
                  tier={2}
                />
              ))}
            </NavCategory>
          )}
          {project && (
            <NavCategory label='Behavior Extension'>
              {routeHeirarchy.architect.behaviorExtension.map((route) => (
                <NavItem
                  key={route.id}
                  id={route.id}
                  label={route.label}
                  link={route.path}
                  tier={2}
                />
              ))}
            </NavCategory>
          )}
          <Hide if={!environment || !!project || !swaggerEndpoint}>
            <NavCategory label='Resources'>
              <NavItem
                external
                label='Swagger'
                link={swaggerEndpoint ?? ''}
                target='swagger'
                tier={2}
              />
            </NavCategory>
          </Hide>
          <NavCategory label='Utilities'>
            {routeHeirarchy.architect.utilites.map((route) => (
              <NavItem
                key={route.id}
                id={route.id}
                label={route.label}
                link={route.path}
                tier={2}
              />
            ))}
            <NavGroup id='tguid' label='TGUID' tier={2}>
              {routeHeirarchy.architect.utilties_tguid.map((route) => (
                <NavItem
                  key={route.id}
                  id={route.id}
                  label={route.label}
                  link={route.path}
                  tier={3}
                />
              ))}
            </NavGroup>
          </NavCategory>
        </NavGroup>
      )}

      {/* Operations */}
      {(!project || project.status !== 'conflicted') && (
        <NavGroup
          id='operations'
          label='Operations'
          swapOpacity
          tier={1}
          icon={faBarsStaggered}
          accordion={true}
          disabled={isNavItemDisabled}
          onMouseEvent={_handleMouseEvent}
          sendOpenGroupItem={setOpenGroupState}
        >
          <NavCategory label='ACH Corporate Origination'>
            {routeHeirarchy.operations.achCorporate.map((route) => (
              <NavItem
                key={route.id}
                id={route.id}
                label={route.label}
                link={route.path}
                tier={2}
              />
            ))}
          </NavCategory>
          <NavCategory label='Batch File Management'>
            {routeHeirarchy.operations.batchFileMgmt.map((route) => (
              <NavItem
                key={route.id}
                id={route.id}
                label={route.label}
                link={route.path}
                tier={2}
              />
            ))}
          </NavCategory>
          <NavCategory label='Orders'>
            {routeHeirarchy.operations.orders.map((route) => (
              <NavItem
                key={route.id}
                id={route.id}
                label={route.label}
                link={route.path}
                tier={2}
              />
            ))}
          </NavCategory>
          <NavCategory label='Reports &amp; Extracts'>
            {routeHeirarchy.operations.reports.map((route) => (
              <NavItem
                key={route.id}
                id={route.id}
                label={route.label}
                link={route.path}
                tier={2}
              />
            ))}
            {coreServerVersionSatisfies(
              includeExternalReports,
              props.coreVersion
            ) && (
              <NavItem
                id={externalReportsRoute.id}
                label={externalReportsRoute.label}
                link={externalReportsRoute.path}
                tier={2}
              />
            )}
            {routeHeirarchy.operations.reportsAndExtracts.map((route) => (
              <NavItem
                key={route.id}
                id={route.id}
                label={route.label}
                link={route.path}
                tier={2}
              />
            ))}
          </NavCategory>
          <NavCategory label='Other'>
            {routeHeirarchy.operations.other.map((route) => (
              <NavItem
                key={route.id}
                id={route.id}
                label={route.label}
                link={route.path}
                tier={2}
              />
            ))}
          </NavCategory>
        </NavGroup>
      )}

      {/* Diagnostics */}
      {(!project || project.status !== 'conflicted') && (
        <NavGroup
          id='diagnostics'
          label='Diagnostics'
          tier={1}
          icon={faWavePulse}
          accordion={true}
          disabled={isNavItemDisabled}
          onMouseEvent={_handleMouseEvent}
          sendOpenGroupItem={setOpenGroupState}
        >
          {routeHeirarchy.diagnosticsDashboard.map((route) => (
            <NavItem
              key={route.id}
              id={route.id}
              label={route.label}
              link={route.path}
              tier={2}
            />
          ))}

          <NavCategory label='General'>
            {routeHeirarchy.diagnostics.general.map((route) => (
              <NavItem
                key={route.id}
                id={route.id}
                label={route.label}
                link={route.path}
                tier={2}
              />
            ))}
          </NavCategory>
          <NavCategory label='Balancing &amp; Reconciliation'>
            {routeHeirarchy.diagnostics.balancingAndRecon.map((route) => (
              <NavItem
                key={route.id}
                id={route.id}
                label={route.label}
                link={route.path}
                tier={2}
              />
            ))}
          </NavCategory>
          <NavCategory label='Events'>
            {routeHeirarchy.diagnostics.events.map((route) => (
              <NavItem
                key={route.id}
                id={route.id}
                label={route.label}
                link={route.path}
                tier={2}
              />
            ))}
          </NavCategory>
        </NavGroup>
      )}

      {/* Implementation */}
      {implementationRepoUrl && (
        <>
          {divider}
          <NavGroup
            id='implementation'
            label='Implementation'
            swapOpacity
            tier={1}
            icon={faLayerGroup}
            accordion={true}
            sendOpenGroupItem={setOpenGroupState}
          >
            {routeHeirarchy.implementation.map((route) => (
              <NavItem
                key={route.id}
                id={route.id}
                label={route.label}
                link={route.path}
                tier={2}
              />
            ))}
          </NavGroup>
        </>
      )}

      {divider}

      {/* Core Servicing */}
      {serviceEndpoint && (
        <Hide if={!environment || !!project}>
          <NavItem
            external
            label='Core Servicing'
            link={serviceEndpoint}
            target='core-servicing'
            tier={1}
            icon={faBellConcierge}
          />
        </Hide>
      )}

      {/* Request Service */}
      {!allOrgsGroup && (
        <NavItem
          external
          label='Request Service'
          link={serviceTicketURL ?? ''}
          target='request-service'
          tier={1}
          icon={faCommentsQuestion}
        />
      )}

      {/* Resources */}
      <NavGroup
        id='resources'
        label='Resources'
        tier={1}
        icon={faBook}
        accordion={true}
        sendOpenGroupItem={setOpenGroupState}
      >
        <NavItem
          id={dashboardRoute.id}
          label={'Resources Dashboard'}
          link={dashboardRoute.path}
          tier={2}
        />

        {implementationSiteUrl && (
          <NavItem
            external
            label='Implementation Site'
            link={implementationSiteUrl}
            target='learning-portal'
            tier={2}
          />
        )}

        <Hide if={!environment || !!project || !swaggerEndpoint}>
          <NavItem
            external
            label='Swagger'
            link={swaggerEndpoint ?? ''}
            target='swagger'
            tier={2}
          />
        </Hide>
        {isPermitted('cnsl', coreVersionsSwaggerView) ? (
          <NavItem
            id={swaggerCoreRoute.id}
            label={swaggerCoreRoute.label}
            link={swaggerCoreRoute.path}
            tier={2}
          />
        ) : null}

        <NavItem
          id={sampleAPIsRoute.id}
          label={`${sampleAPIsRoute.label}`}
          link={sampleAPIsRoute.path}
          tier={2}
        />

        <NavItem
          id={schemaOverviewRoute.id}
          label={schemaOverviewRoute.label}
          link={schemaOverviewRoute.path}
          tier={2}
        />

        <NavItem
          id={schemaAndChoicesRoute.id}
          label={schemaAndChoicesRoute.label}
          link={schemaAndChoicesRoute.path}
          tier={2}
        />

        <NavCategory label='Communications'>
          <NavItem
            id={releaseRoute.id}
            label={releaseRoute.label}
            link={releaseRoute.path}
            tier={2}
          />
          {consoleConfig.releaseCalendarConfigured && (
            <NavItem
              id={releaseCalendarRoute.id}
              label={releaseCalendarRoute.label}
              link={releaseCalendarRoute.path}
              tier={2}
            />
          )}
          <NavItem
            id={newsRoute.id}
            label={newsRoute.label}
            link={newsRoute.path}
            tier={2}
          />
        </NavCategory>

        {isPermitted('cnsl', s3TrainingSiteView) ? (
          <NavCategory label='Archived Resources'>
            <NavItem
              external
              label={'Archived Learning Portal'}
              link={externalDocsPath}
              target='learning-portal'
              tier={2}
            />
            <NavItem
              external
              label={'Archived Training'}
              link={`${externalDocsPath}/training`}
              target='learning-portal'
              tier={2}
            />
            <NavItem
              external
              label={'Archived Documentation'}
              link={`${externalDocsPath}/documentation`}
              target='learning-portal'
              tier={2}
            />
            <NavItem
              external
              label={'Archived Tutorials'}
              link={`${externalDocsPath}/tutorials`}
              target='learning-portal'
              tier={2}
            />
            <Hide if={environment?.tier === 'pd0'}>
              <NavItem
                id={documentationRoute.id}
                label={`Archived ${documentationRoute.label}`}
                link={documentationRoute.path}
                tier={2}
              />
            </Hide>
          </NavCategory>
        ) : null}
      </NavGroup>

      {divider}

      {/* Global */}
      {allOrgsGroup && (
        <NavGroup
          id='global'
          label='Global'
          tier={1}
          sendOpenGroupItem={setOpenGroupState}
        >
          <NavItem
            id={environmentHealthRoute.id}
            label={environmentHealthRoute.label}
            link={environmentHealthRoute.path}
            tier={2}
          />
        </NavGroup>
      )}
    </Nav>
  )
}
