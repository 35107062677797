import { OrgEnvDispatch, OrgEnvState } from './orgEnvSelectorStore'
import {
  TypedUseSelectorHook,
  useDispatch,
  useSelector,
  useStore,
} from 'react-redux'

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useOrgEnvDispatch = () => useDispatch<OrgEnvDispatch>()
export const useOrgEnvSelector: TypedUseSelectorHook<OrgEnvState> = useSelector
export const useOrgEnvStore = () => useStore<OrgEnvState>()
