import React from 'react'
import { faGear, faSearch } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMemo } from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import EnvProjRow from './EnvProjRow'
import s from './OrgEnvSelector.module.scss'

import { setEnvSearchTerm } from './orgEnvSelectorStore'
import { useOrgEnvDispatch, useOrgEnvSelector } from './orgEnvSelectorHooks'
import { Environment, Project } from './OrgEnvDropdown'
import clsn from 'classnames'

/**
 * The right hand panel of the OrgEnvSelector, allows for selection of a project
 * or environment.
 * @returns
 */
export default function EnvSelector(props: {
  proj?: Project
  projs: Project[]
  env?: Environment
  envs: Environment[]
  onEnvSelect: (id: number) => void
  onProjSelect: (id: number) => void
  isSingleOrg?: boolean
}) {
  const {
    proj,
    projs,
    env: selectedEnv,
    envs,
    onEnvSelect,
    onProjSelect,
    isSingleOrg,
  } = props
  const state = useOrgEnvSelector((state) => state.orgEnvSelector)
  const orgEnvDispatch = useOrgEnvDispatch()

  // Search filter
  const filteredEnvs = useMemo(() => {
    if (state.envSearchTerm) {
      const lowerCaseSearchTerm = state.envSearchTerm.toLocaleLowerCase()
      return envs.filter(
        (env) =>
          env.name.toLocaleLowerCase().includes(lowerCaseSearchTerm) ||
          env.id === selectedEnv?.id // always show selected env
      )
    }
    return envs
  }, [envs, state.envSearchTerm])

  // Search handler
  const onEnvSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    orgEnvDispatch(setEnvSearchTerm(e.target.value))
  }

  /**
   * Show the searchbox if
   * - user has only 1 org and total env+projs > 4
   * - user has multiple orgs
   */
  const shouldShowSearch = useMemo(() => {
    return !isSingleOrg || projs.length + envs.length > 4
  }, [envs, projs, isSingleOrg])

  const _emptyMessage = useMemo(() => {
    if (!envs.length) {
      return <p className={s.emptyMessage}>No environments</p>
    } else if (!filteredEnvs.length) {
      return <p className={s.emptyMessage}>No environments to show</p>
    }
  }, [envs, filteredEnvs])

  // Render
  return (
    <>
      <div className={s.envContainer}>
        {shouldShowSearch ? (
          <>
            <h2 className={s.heading}>Environment / Project</h2>
            <p className={s.headingNote}>
              Available below based on selected org
            </p>
            <InputGroup>
              <InputGroup.Text className={s.searchBoxIcon} id='envSearchIcon'>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control
                aria-describedby='envSearchIcon'
                aria-label='Search'
                className={s.searchBox}
                onChange={onEnvSearchTermChange}
                placeholder='Search'
                type='text'
                value={state.envSearchTerm}
              />
            </InputGroup>
          </>
        ) : null}

        {projs && projs.length > 0 ? (
          <>
            <h3 className={clsn(s.heading, shouldShowSearch ? null : 'mt-0')}>
              Projects
            </h3>
            {projs.map((p) => {
              return (
                <EnvProjRow
                  key={p.id}
                  onClick={onProjSelect}
                  proj={p}
                  selected={
                    !selectedEnv && proj && p.id === proj.id ? true : undefined
                  }
                />
              )
            })}
          </>
        ) : null}

        <h3
          className={clsn(
            s.heading,
            projs.length === 0 && !shouldShowSearch ? 'mt-0' : null
          )}
        >
          Environments
        </h3>

        {filteredEnvs && filteredEnvs.length > 0
          ? filteredEnvs.map((e) => {
              return (
                <EnvProjRow
                  env={e}
                  key={e.id}
                  onClick={onEnvSelect}
                  selected={
                    selectedEnv && e.id === selectedEnv.id ? true : undefined
                  }
                />
              )
            })
          : _emptyMessage}
      </div>
      <div className={s.manageEnvs}>
        {envs.length ? (
          <a href='/account' className='d-flex align-items-center'>
            <FontAwesomeIcon icon={faGear} />
            Manage Environments
          </a>
        ) : (
          'Request environment access to get started.'
        )}
      </div>
    </>
  )
}
