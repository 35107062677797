import React from "react";
import { Dropdown } from 'react-bootstrap'
import headerStyles from '../Header.module.scss'
import { ADMIN_OPERATIONS_REGEX } from "../../../constants";

type SettingsDropdownSectionProps = {
  consoleConfig: {
    enableEcosystem?: boolean;
    prodEnv: boolean
  };
  isPermitted: (
    mode: "cnsl" | "core" | "primary-org-cnsl",
    value: string | RegExp | string[] | undefined,
    inclusive?: boolean | undefined
  ) => boolean;
  organization?: {
    id: number;
  };
  user?: {
    superUser?: boolean
  }
}

/**
 * Dropdown admin menu that appears in the top navigation bar.  Allows user to choose from various administration
 * options (e.g. manage users, manage organizations...) based on console role.
 * @author Kyle Fox
 */
export default function SettingsDropdownSection(props: SettingsDropdownSectionProps) {
  const {consoleConfig, isPermitted, organization, user} = props
  const orgId = organization?.id
  const isSuperUser = user?.superUser
  const enableEcosystem = consoleConfig?.enableEcosystem
  const allowEcosystem = enableEcosystem !== undefined

  const orgLink = (() => {
    if (isSuperUser && isPermitted('cnsl', 'admin-organization-list')) {
      return (
        <Dropdown.Item
          className={headerStyles.dropdownItem}
          href='/admin/organizations/'
        >
          Organizations
        </Dropdown.Item>
      )
    } else if (isPermitted('cnsl', 'admin-organization-read')) {
      return (
        <Dropdown.Item
          className={headerStyles.dropdownItem}
          href={`/admin/organization/edit/${orgId}`}
        >
          Organization
        </Dropdown.Item>
      )
    }
    return null
  })()

  const ecoHeader = (() => {
    if (allowEcosystem && !enableEcosystem && isSuperUser) {
      return (
        <Dropdown.Header className={headerStyles.dropdownHeader}>
          Ecosystem
        </Dropdown.Header>
      )
    }
    return null
  })()

  const org2Link = (() => {
    if (!allowEcosystem || enableEcosystem) return null

    if (isSuperUser && isPermitted('cnsl', 'admin-organization-list')) {
      return (
        <Dropdown.Item
          className={headerStyles.dropdownItem}
          href='/admin/organizations2/'
        >
          Organizations
        </Dropdown.Item>
      )
    } else if (isSuperUser && isPermitted('cnsl', 'admin-organization-read')) {
      return (
        <Dropdown.Item
          className={headerStyles.dropdownItem}
          href={`/admin/organization2/edit/${orgId}`}
        >
          Organization
        </Dropdown.Item>
      )
    }
    return null
  })()

  const env2Link = (() => {
    if (!allowEcosystem || enableEcosystem) return null

    if (isSuperUser && isPermitted('cnsl', 'admin-environment-list')) {
      return (
        <Dropdown.Item
          className={headerStyles.dropdownItem}
          href='/admin/environments2/'
        >
          Environments
        </Dropdown.Item>
      )
    }
    return null
  })()

  const activateLink = (() => {
    if (allowEcosystem && !enableEcosystem && isSuperUser) {
      return (
        <Dropdown.Item
          className={headerStyles.dropdownItem}
          href='/admin/ecoactivate/'
        >
          Activate
        </Dropdown.Item>
      )
    }
    return null
  })()

  const hasAccessManagementAccess =
    isPermitted("cnsl", "access-management-access") &&
    isPermitted("cnsl", [
      "admin-account-list",
      "admin-prfl-tmpl-list",
      "admin-console-role-list",
    ]);

  return isPermitted('cnsl', ADMIN_OPERATIONS_REGEX) ||
    isPermitted('primary-org-cnsl', 'admin-environment-list') ? (
    <div className={headerStyles.dropdownGrouping}>
      <Dropdown.Header className={headerStyles.dropdownHeader}>
        SETTINGS
      </Dropdown.Header>
      {!consoleConfig.prodEnv && hasAccessManagementAccess ? (
        <Dropdown.Item
          className={headerStyles.dropdownItem}
          href='/access-management'
        >
          Access Management
        </Dropdown.Item>
      ) : null}
      {isPermitted("cnsl", 'admin-account-list') ? (
        <Dropdown.Item
        className={headerStyles.dropdownItem}
        href='/admin/accounts/'
        >
            Accounts
        </Dropdown.Item>
      ) : null}
      {isPermitted("cnsl", 'admin-course-list') ? (
        <Dropdown.Item
          className={headerStyles.dropdownItem}
          href='/admin/courses/'
        >
          Courses
        </Dropdown.Item>
      ) : null}
      {isPermitted('primary-org-cnsl', 'admin-environment-list') ? (
        <Dropdown.Item
          className={headerStyles.dropdownItem}
          href='/admin/environments/'
        >
          Environments
        </Dropdown.Item>
      ) : null}
      {orgLink}
      {isPermitted('cnsl', /^admin-prfl-tmpl-.*$/) ? (
        <Dropdown.Item
          className={headerStyles.dropdownItem}
          href='/admin/profileTemplates'
        >
          Profile Templates
        </Dropdown.Item>
      ) : null}
      {isPermitted('cnsl', /^admin-report-.*$/) ? (
        <Dropdown.Item
        className={headerStyles.dropdownItem}
        href='/admin/reports'
        >
          Reports
        </Dropdown.Item>
      ) : null}
      {isPermitted('cnsl', 'admin-console-role-list') ? (
        <Dropdown.Item
          className={headerStyles.dropdownItem}
          href='/admin/consoleRoles/'
        >
          Roles &amp; Permissions
        </Dropdown.Item>
      ) : null}
      {isPermitted('cnsl', 'admin-core-build-list') ? (
        <Dropdown.Item
        className={headerStyles.dropdownItem}
        href='/admin/core-builds/'
        >
          Core Builds
        </Dropdown.Item>
      ) : null}

      {ecoHeader}
      {org2Link}
      {env2Link}
      {activateLink}
    </div>
  ) : null
}