/**
 * 
 * @param data 
 * @returns deep copies the input object/array
 */
export function deepCopy<T>(data: T): T {
  return data ? JSON.parse(JSON.stringify(data)) : "";
}


/**
 * 
 * @param list 
 * @param item 
 * @param index 
 * @returns Replaced list of items from an arr at specific index
 */
export function replaceItemsInList<T>(list: T[], item: T, index: number) {
  if(list?.length) {
    list.splice(index, list.length, item)
    return [...list];
  }
  return [item];
}