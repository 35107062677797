import { featureFlags } from "../featureFlags";
import labels from "./architect-routes-labels.json";
import type { Route } from '../routes/index'

const { includeAssetCatalogue, includeAssetExchRates, includeRiskRatings } =
  featureFlags;

export const architectRoutes: Record<string, Route> = {
  prod_launchpadRoute: {
    id: "product_launchpad",
    label: "Product Launchpad",
    path: "/dashboard/product-launchpad",
  },
  prod_bkRoute: {
    id: "prod_bk",
    label: labels.prod_bk,
    path: "/prod_bk",
    coreOperation: "Prod_bkList",
  },
  prod_usTbpRoute: {
    id: "prod_usTbp",
    label: labels.prod_usTbp,
    path: "/prod_usTbp",
    coreOperation: "Prod_usTbpList",
  },
  usTbpTypeRoute: {
    id: "usTbpType",
    label: labels.usTbpType,
    path: "/usTbpType",
    coreOperation: "UsTbpTypeList",
  },
  prodTypeRoute: {
    id: "prodType",
    label: labels.prodType,
    path: "/prodType",
    coreOperation: "ProdTypeList",
  },
  prodSubTypeRoute: {
    id: "prodSubType",
    label: labels.prodSubType,
    path: "/prodSubType",
    coreOperation: "ProdSubTypeList",
  },
  prodGroupRoute: {
    id: "prodGroup",
    label: labels.prodGroup,
    path: "/prodGroup",
    coreOperation: "ProdGroupList",
  },
  prodProgramRoute: {
    id: "prodProgram",
    label: labels.prodProgram,
    path: "/prodProgram",
    coreOperation: "ProdProgramList",
  },
  prodProgramGroupRoute: {
    id: "prodProgramGroup",
    label: labels.prodProgramGroup,
    path: "/prodProgramGroup",
    coreOperation: "ProdProgramGroupList",
  },
  prodProgramGroupRelRoute: {
    id: "prodProgramGroupRel",
    label: labels.prodProgramGroupRel,
    path: "/prodProgramGroupRel",
    coreOperation: "ProdProgramGroupRelList",
  },
  prodSvcRoute: {
    id: "prodSvc",
    label: labels.prodSvc,
    path: "/prodSvc",
    coreOperation: "ProdSvcList",
  },
  prod_bkLnDelqRoute: {
    id: "prod_bkLnDelq",
    label: labels.prod_bkLnDelq,
    path: "/prod_bkLnDelq",
    coreOperation: "Prod_bkLnDelqList",
  },
  componentCollateralRoute: {
    id: "componentCollateral",
    label: labels.componentCollateral,
    path: "/componentCollateral",
    coreOperation: "ComponentCollateralList",
  },
  componentDepChrgOffRoute: {
    id: "componentDepChrgOff",
    label: labels.componentDepChrgOff,
    path: "/componentDepChrgOff",
    coreOperation: "ComponentDepChrgOffList",
  },
  componentFeeRoute: {
    id: "componentFee",
    label: labels.componentFee,
    path: "/componentFee",
    coreOperation: "ComponentFeeList",
  },
  componentIntRoute: {
    id: "componentInt",
    label: labels.componentInt,
    path: "/componentInt",
    coreOperation: "ComponentIntList",
  },
  componentLnParamsRoute: {
    id: "componentLnParams",
    label: labels.componentLnParams,
    path: "/componentLnParams",
    coreOperation: "ComponentLnParamsList",
  },
  componentNsfRoute: {
    id: "componentNsf",
    label: labels.componentNsf,
    path: "/componentNsf",
    coreOperation: "ComponentNsfList",
  },
  componentRepayRoute: {
    id: "componentRepay",
    label: labels.componentRepay,
    path: "/componentRepay",
    coreOperation: "ComponentRepayList",
  },
  componentRewardRoute: {
    id: "componentReward",
    label: labels.componentReward,
    path: "/componentReward",
    coreOperation: "ComponentRewardList",
  },
  componentRulesRoute: {
    id: "componentRules",
    label: labels.componentRules,
    path: "/componentRules",
    coreOperation: "ComponentRulesList",
  },
  componentTdRoute: {
    id: "componentTd",
    label: labels.componentTd,
    path: "/componentTd",
    coreOperation: "ComponentTdList",
  },
  componentTrnOptRoute: {
    id: "componentTrnOpt",
    label: labels.componentTrnOpt,
    path: "/componentTrnOpt",
    coreOperation: "ComponentTrnOptList",
  },
  componentLimitRoute: {
    id: "componentLimit",
    label: labels.componentLimit,
    path: "/componentLimit",
    coreOperation: "ComponentLimitList",
  },
  accumTrnLimitRoute: {
    id: "accumTrnLimit",
    label: labels.accumTrnLimit,
    path: "/accumTrnLimit",
    coreOperation: "AccumTrnLimitList",
  },
  trnLimitRoute: {
    id: "trnLimit",
    label: labels.trnLimit,
    path: "/trnLimit",
    coreOperation: "TrnLimitList",
  },
  stopRoute: {
    id: "stop",
    label: labels.stop,
    path: "/stop",
    coreOperation: "StopList",
  },
  sweepRoute: {
    id: "sweep",
    label: labels.sweep,
    path: "/sweep",
    coreOperation: "SweepList",
  },
  // General Ledger
  acctgSegRoute: {
    id: "acctgSeg",
    label: labels.acctgSeg,
    path: "/acctgSeg",
    coreOperation: "AcctgSegList",
  },
  acct_glRoute: {
    id: "acct_gl",
    label: labels.acct_gl,
    path: "/acct_gl",
    coreOperation: "AcctList",
  },
  posn_glRoute: {
    id: "posn_gl",
    label: labels.posn_gl,
    path: "/posn_gl",
    coreOperation: "Posn_glList",
  },
  glSetRoute: {
    id: "glSet",
    label: labels.glSet,
    path: "/glSet",
    coreOperation: "GlSetList",
  },
  configSystemAcctRoute: {
    id: "configSystemAcct",
    label: labels.configSystemAcct,
    path: "/configSystemAcct",
    coreOperation: "ConfigSystemAcctList",
  },
  // Bank Configuration
  acctTypeRoute: {
    id: "acctType",
    label: labels.acctType,
    path: "/acctType",
    coreOperation: "AcctTypeList",
  },
  acctGroupRoute: {
    id: "acctGroup",
    label: labels.acctGroup,
    path: "/acctGroup",
    coreOperation: "AcctGroupList",
  },
  assetCatalogueRoute: {
    id: "assetCatalogue",
    label: labels.assetCatalogue,
    path: "/assetCatalogue",
    coreOperation: "AssetCatalogueList",
    validCoreVns: includeAssetCatalogue,
  },
  assetClassRoute: {
    id: "assetClass",
    label: labels.assetClass,
    path: "/assetClass",
    coreOperation: "AssetClassList",
  },
  assetExchRatesRoute: {
    id: "assetExchRates",
    label: labels.assetExchRates,
    path: "/assetExchRates",
    coreOperation: "AssetExchRatesList",
    validCoreVns: includeAssetExchRates,
  },
  bankparamRoute: {
    id: "bankparam",
    label: labels.bankparam,
    path: "/bankparam",
    coreOperation: "BankparamList",
  },
  bankparamUSBankInfoRoute: {
    id: "bankparamUSBankInfo",
    label: labels.bankparamUSBankInfo,
    path: "/bankparamUSBankInfo",
    coreOperation: "BankparamUSBankInfoList",
  },
  calendarRoute: {
    id: "calendar",
    label: labels.calendar,
    path: "/calendar",
    coreOperation: "CalendarList",
  },
  channelRoute: {
    id: "channel",
    label: labels.channel,
    path: "/channel",
    coreOperation: "ChannelList",
  },
  prod_cardRoute: {
    id: "prod_card",
    label: labels.prod_card,
    path: "/prod_card",
    coreOperation: "Prod_cardList",
  },
  chkFilePostInstrRoute: {
    id: "chkFilePostInstr",
    label: labels.chkFilePostInstr,
    path: "/chkFilePostInstr",
    coreOperation: "ChkFilePostInstrList",
  },
  cntryRoute: {
    id: "cntry",
    label: labels.cntry,
    path: "/cntry",
    coreOperation: "CntryList",
  },
  cntrySubdivisionRoute: {
    id: "cntrySubdivision",
    label: labels.cntrySubdivision,
    path: "/cntrySubdivision",
    coreOperation: "CntrySubdivisionList",
  },
  ccyCodeRoute: {
    id: "ccyCode",
    label: labels.ccyCode,
    path: "/ccyCode",
    coreOperation: "CcyCodeList",
  },
  custRelTypeRoute: {
    id: "custRelType",
    label: labels.custRelType,
    path: "/custRelType",
    coreOperation: "CustRelTypeList",
  },
  domainRoute: {
    id: "domain",
    label: labels.domain,
    path: "/domain",
    coreOperation: "DomainList",
  },
  feeCalcDtlRoute: {
    id: "feeCalcDtl",
    label: labels.feeCalcDtl,
    path: "/feeCalcDtl",
    coreOperation: "FeeCalcDtlList",
  },
  // We have two paths here because of a limitation in FXTModelEdit where the list path is expected to be a prefix of the edit page path.
  fileFmtRoute: {
    id: "fileFmt",
    label: labels.fileFmt,
    path: "/fileFmt",
    coreOperation: "FileFmtList",
  },
  fileFmtRecRoute: {
    id: "fileFmtRec",
    label: labels.fileFmtRec,
    path: "/fileFmtRec",
    coreOperation: "FileFmtList",
  },
  glExtractFilterRoute: {
    id: "glExtractFilter",
    label: labels.glExtractFilter,
    path: "/glExtractFilter",
    coreOperation: "GlExtractFilterList",
  },
  fundsAvlCatRoute: {
    id: "fundsAvlCat",
    label: labels.fundsAvlCat,
    path: "/fundsAvlCat",
    coreOperation: "FundsAvlCatList",
  },
  fundsAvlHoldRoute: {
    id: "fundsAvlHold",
    label: labels.fundsAvlHold,
    path: "/fundsAvlHold",
    coreOperation: "FundsAvlHoldList",
  },
  partyRoute: {
    id: "party_org_fininst",
    label: labels.party_org_fininst,
    path: "/party_org_fininst",
    coreOperation: "Party_org_fininstList",
  },
  partyUSBankInfoRoute: {
    id: "partyUSBankInfo",
    label: labels.partyUSBankInfo,
    path: "/partyUSBankInfo",
    coreOperation: "PartyUSBankInfoList",
  },
  freqAndDurMapRoute: {
    id: "freqAndDurMap",
    label: labels.freqAndDurMap,
    path: "/freqAndDurMap",
    coreOperation: "FreqAndDurMapList",
  },
  holdTemplateRoute: {
    id: "holdTemplate",
    label: labels.holdTemplate,
    path: "/holdTemplate",
    coreOperation: "HoldTemplateList",
  },
  lstseqRoute: {
    id: "lstseq",
    label: labels.lstseq,
    path: "/lstSeq",
    coreOperation: "LstSeqList",
  },
  matrixRoute: {
    id: "matrix",
    label: labels.matrix,
    path: "/matrix",
    coreOperation: "MatrixTypeList",
  },
  merchantGroupRoute: {
    id: "merchantGroup",
    label: labels.merchantGroup,
    path: "/merchantGroup",
    coreOperation: "MerchantGroupList",
  },
  networkRoute: {
    id: "network",
    label: labels.network,
    path: "/network",
    coreOperation: "NetworkList",
  },
  orderTemplateRoute: {
    id: "orderTemplate",
    label: labels.orderTemplate,
    path: "/orderTemplate",
    coreOperation: "OrderTemplateList",
  },
  partyGroupRoute: {
    id: "partyGroup",
    label: labels.partyGroup,
    path: "/partyGroup",
    coreOperation: "PartyGroupList",
  },
  posnRoleTypeRoute: {
    id: "posnRoleType",
    label: labels.posnRoleType,
    path: "/posnRoleType",
    coreOperation: "PosnRoleTypeList",
  },
  promoCodeDtlRoute: {
    id: "promoCodeDtl",
    label: labels.promoCodeDtl,
    path: "/promoCodeDtl",
    coreOperation: "PromoCodeDtlList",
  },
  rateIndexRoute: {
    id: "rateIndex",
    label: labels.rateIndex,
    path: "/rateIndex",
    coreOperation: "RateIndexList",
  },
  rateIndexRatesRoute: {
    id: "rateIndexRates",
    label: labels.rateIndexRates,
    path: "/rateIndexRates",
    coreOperation: "RateIndexList",
  },
  riskProfileRoute: {
    id: "riskProfile",
    label: labels.riskProfile,
    path: "/riskProfile",
    coreOperation: "RiskProfileList",
    validCoreVns: includeRiskRatings,
  },
  roleRoute: {
    id: "role",
    label: labels.role,
    path: "/role",
    coreOperation: "RoleList",
  },
  rolePermissionRoute: {
    id: "rolePermission",
    label: labels.rolePermission,
    path: "/rolePermission",
    coreOperation: "RolePermissionList",
  },
  systemCalendarRoute: {
    id: "systemCalendar",
    label: labels.systemCalendar,
    path: "/systemCalendar",
    coreOperation: "SystemCalendarList",
  },
  exceptRuleRoute: {
    id: "exceptRule",
    label: labels.exceptRule,
    path: "/exceptRule",
    coreOperation: "ExceptRuleList",
  },
  restrictRuleRoute: {
    id: "restrictRule",
    label: labels.restrictRule,
    path: "/restrictRule",
    coreOperation: "ExceptRuleList",
  },
  trnCodeRoute: {
    id: "trnCode",
    label: labels.trnCode,
    path: "/trnCode",
    coreOperation: "TrnCodeList",
  },
  trnStatGroupRoute: {
    id: "trnStatGroup",
    label: labels.trnStatGroup,
    path: "/trnStatGroup",
    coreOperation: "TrnStatGroupList",
  },
  // Utilities
  freqModelerRoute: {
    id: "freqModeler",
    label: labels.freqModeler,
    path: "/freqModeler",
    coreOperation: "CalcNextDt",
  },
  emptyCacheRoute: {
    id: "emptyCache",
    label: labels.emptyCache,
    path: "/emptyCache",
    coreOperation: ["ClearCache", "EmptyAllCache"],
    inclusive: true,
  },
  tguidDecodeRoute: {
    id: "tguidDecode",
    label: labels.tguidDecode,
    path: "/tguidDecode",
  },
  tguidEncodeRoute: {
    id: "tguidEncode",
    label: labels.tguidEncode,
    path: "/tguidEncode",
  },
};

export default architectRoutes;
