export const constants = {
  accountPath: "/account",
  allOrganizationsId: -1,
  environmentAdminPath: "admin/environments/edit/",
  externalDocsPath: "/console/external-docs",
  privacyPolicy: "https://www.finxact.com/us/en/privacy-policy.html",
  finxactProQSG: "/docs/document-detail/document/get-started-with-finxact-pro"
};

export const paths = {
  dashboard: "/dashboard",
  docs: "/docs",
  academy: "/academy",
  apis: "/apis",
};

/**
 * Local Storage Timeout variables
 */
export const loggingOut = "loggingOut",
  timeoutSessionLogout = "timeoutDtm",
  timeoutSessionLogoutWarning = "timeoutWarnDtm";

export const groupingEnabledKey = "org-grouping-enabled",
  recentOrgsKey = "recent-orgs";

export const ALGOLIA_RESULT_TYPES = {
  academy: "academy",
  apiLibrary: "api_library",
  apis: "apis",
  console: "console",
  document: "document",
  courses: "courses",
  documentCenter: "document_center",
  training: "training_document",
  tutorials: "tutorials",
  tutorial: "tutorial",
  docs: "docs",
  dashboard: "dashboard",
};
export const RestrictedTags = ["Restricted"]; //tags are case-sensitive

export const extCancelledProjectPath = `/extensions/project/canceled/`,
  extCompletedProjectPath = `/extensions/project/completed/`,
  extOpenProjectPath = `/extensions/project/view/`;

// listing gotten with
/*
  $ yarn node

  const fs = require('fs')
  const _ = require('lodash')

  const file = fs.readFileSync('../_dev/dockerfiles/sql/pg/2.0-dml.sql', {encoding: 'utf-8'})

  console.log(
    [...file.matchAll(/^\('((?:(?:\w+)-?)+)',/mg)].map(x => {
      const permission = x[1]
      return `${_.camelCase(permission)} = '${permission}'`
    }).join(',\n')
  )
*/

export const consoleOperations = {
  adminAccountCreate: "admin-account-create",
  adminAccountRead: "admin-account-read",
  adminAccountUpdate: "admin-account-update",
  adminAccountDelete: "admin-account-delete",
  adminAccountList: "admin-account-list",
  adminConsoleRoleCreate: "admin-console-role-create",
  adminConsoleRoleRead: "admin-console-role-read",
  adminConsoleRoleUpdate: "admin-console-role-update",
  adminConsoleRoleDelete: "admin-console-role-delete",
  adminConsoleRoleList: "admin-console-role-list",
  adminConsoleOperationsList: "admin-console-operations-list",
  adminEnvironmentCreate: "admin-environment-create",
  adminEnvironmentRead: "admin-environment-read",
  adminEnvironmentUpdate: "admin-environment-update",
  adminEnvironmentDelete: "admin-environment-delete",
  adminEnvironmentList: "admin-environment-list",
  adminOrganizationCreate: "admin-organization-create",
  adminOrganizationRead: "admin-organization-read",
  adminOrganizationUpdate: "admin-organization-update",
  adminOrganizationDelete: "admin-organization-delete",
  adminOrganizationList: "admin-organization-list",
  adminPrflTmplCreate: "admin-prfl-tmpl-create",
  adminPrflTmplDelete: "admin-prfl-tmpl-delete",
  adminPrflTmplList: "admin-prfl-tmpl-list",
  adminPrflTmplRead: "admin-prfl-tmpl-read",
  adminPrflTmplUpdate: "admin-prfl-tmpl-update",
  adminCourseCreate: "admin-course-create",
  adminCourseRead: "admin-course-read",
  adminCourseUpdate: "admin-course-update",
  adminCourseDelete: "admin-course-delete",
  adminCourseList: "admin-course-list",
  adminReportLockedAccounts: "admin-report-locked-accounts",
  adminReportPasswordResets: "admin-report-password-resets",
  adminReportUserDetails: "admin-report-user-details",
  adminReportRolePermissions: "admin-report-role-permissions",
  adminReportUserChanges: "admin-report-user-changes",
  adminReportUserEnvironments: "admin-report-user-environments",
  adminNewsApprove: "admin-news-approve",
  adminTrainingDocumentsApprove: "admin-training-documents-approve",
  downloadPostmanSettings: "download-postman-settings",
  extensionsExpertMode: "extensions-schema-expert-mode",
  coreServicing: "core-servicing",
  requestService: "request-service",
  achFileList: "ach-file-list",
  checkFileList: "check-file-list",
  coreBatchFileList: "core-batch-file-list",
  glBalancingList: "gl-balancing-list",
  glExtractsFileList: "gl-extracts-file-list",
  reportsFileList: "reports-file-list",
  taxextractsFileList: "taxextracts-file-list",
  escheatExtractsFileList: "escheat-extracts-file-list",
  courseList: "course-list",
  projectExportAllConfiguration: "project-export-all-configuration",
  projectView: "project-view",
  projectComplete: "project-complete",
  projectCreate: "project-create",
  projectPermitAuthorizedUsers: "project-permit-authorized-users",
  projectVersionUpdate: "project-version-update",
  requestEnvironmentDeploys: "request-environment-deploys",
  projectEnvironmentRebuild: "project-environment-rebuild",
  s3TrainingSiteView: "s3-training-site-view",
  extensionsSchemaCreate: "extensions-schema-create",
  extensionsSchemaRead: "extensions-schema-read",
  extensionsSchemaUpdate: "extensions-schema-update",
  extensionsSchemaDelete: "extensions-schema-delete",
  extensionsDslEditor: "extensions-dsl-editor",
  adminCoreBuildUpdate: "admin-core-build-update",
  adminCoreBuildList: "admin-core-build-list",
  adminCoreBuildRead: "admin-core-build-read",
  healthcheckList: "healthcheck-list",
  overrideConfigAsCode: "override-config-as-code",
  permissionRequestEnvironmentDeploys: "request-environment-deploys",
  permissionRequestEnvironmentTimeshifts: "request-environment-timeshifts",
  permissionRequestEnvironmentSmartTimeshifts:
    "request-environment-smart-timeshifts",
  coreVersionsSwaggerView: "core-versions-swagger-view",
  chatbotAccess: "chatbot-access",
};

export const ADMIN_OPERATIONS_REGEX =
  /^(admin-report-.*)|(admin-account-list|admin-course-list|admin-environment-list|admin-organization-list|admin-organization-read|admin-prfl-tmpl-list|admin-console-role-list|admin-core-build-list|access-management-access)$/;

export const ALGOLIA_SLUGS = {
  docs: "docs",
  apis: "apis",
  tutorials: "tutorials",
  academy: "academy",
};

export const JOB_TITLE_LIST = [
  "Administrator",
  "Architect",
  "Banker",
  "Business Analyst",
  "Consultant",
  "Data Analyst",
  "Designer",
  "Developer",
  "Executive",
  "IT / Dev Ops",
  "Marketing",
  "Operations",
  "Partnerships",
  "Product Manager",
  "Sales",
  "Other",
];

export enum ORG_STATUS {
  Active = 'Active',
  Deleted = 'Deleted'
}