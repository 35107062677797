import React, { FormEvent, ForwardedRef, forwardRef, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import css from './ProspectBanner.module.scss'
import clsn from 'classnames'
import axios from 'axios'
import SpinnerButton from '../SpinnerButton'

type Props = {
  hasEnv: boolean
  showToast: (val: 'success' | 'error', msg: string, title: string) => void
  onSuccess?: () => void
}

const ProspectBanner = forwardRef(
  (props: Props, ref: ForwardedRef<HTMLButtonElement>) => {
    const { hasEnv, onSuccess, showToast } = props
    const [showModal, toggleModal] = useState(false)
    const [isSubmitting, setSubmittingState] = useState(false)
    const [inputVal, setValue] = useState('')

    const title = hasEnv ? 'Sandbox' : 'Preview'
    const language = hasEnv
      ? 'You are in sandbox mode. Contact Finxact to upgrade the full capabilities.'
      : 'You are viewing a preview of Dashboard. Request a sandbox to get started.'

    const _handleToggle = (val: boolean) => () => {
      toggleModal(val)
    }

    const _handleSubmit = (e: FormEvent) => {
      setSubmittingState(true)
      e.preventDefault()
      const payload = {
        type: 'fast-environment',
        message: {
          Message: inputVal,
        },
      }
      axios
        .post('/console/my/environment/requests', payload)
        .then(() => {
          showToast(
            'success',
            'Our team will be in contact shortly to provide more information about Finxact and our flexible licensing options.',
            "Success! We've received your request"
          )
          onSuccess?.()
          toggleModal(false)
          setSubmittingState(false)
        })
        .catch((e) => {
          showToast(
            'error',
            'There was an issue submitting your request. Please try again.',
            'Error'
          )
          toggleModal(false)
          setSubmittingState(false)
        })
    }

    return (
      <>
        <div className={css.banner}>
          <h6>{title}</h6>
          <p>{language}</p>
          {/* Button is hidden with visibility:hidden and not conditionally rendered
              to make sure request modal can be toggled from outside using the btn's ref
           */}
          <Button
            className={clsn(css.linkBtn, !hasEnv ? css.hidden : null)}
            variant='link'
            ref={ref}
            onClick={_handleToggle(true)}
          >
            Speak with Finxact
          </Button>
        </div>

        {showModal ? (
          <Modal
            onHide={_handleToggle(false)}
            show
            centered
            contentClassName={clsn(css['request-modal'], css['request-modal'])}
          >
            <Form onSubmit={_handleSubmit}>
              <Modal.Header className={css.header}>
                Contact the Finxact team
              </Modal.Header>
              <Modal.Body>
                <p className={css.desc}>
                  Tell us more about your needs and our team will be in-touch
                  shortly to address your inquiry!
                </p>
                <Form.Group>
                  <Form.Label className={css['form-label']}>
                    How can we help?
                  </Form.Label>
                  <Form.Control
                    as='textarea'
                    onChange={(e) => setValue(e.target.value)}
                    placeholder='Tell us more about your use-case'
                    rows={3}
                    value={inputVal}
                  />
                </Form.Group>
              </Modal.Body>
              <Modal.Footer className={css.footer}>
                <div className='ms-auto'>
                  <Button
                    className={css.linkBtn}
                    onClick={_handleToggle(false)}
                    variant='link'
                  >
                    Cancel
                  </Button>
                  <SpinnerButton
                    disabled={!inputVal}
                    label='Submit request'
                    loading={isSubmitting}
                    type='submit'
                    variant='primary'
                  />
                </div>
              </Modal.Footer>
            </Form>
          </Modal>
        ) : null}
      </>
    )
  }
)

export default ProspectBanner
