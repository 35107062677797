import React, { HTMLAttributes, useCallback, useEffect, useState } from "react";
import { MenuItemDocs, Nav, NavCategory, NavGroup, NavItem } from "../Nav";
import s from "./../Menu/Menu.module.scss";

import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronCircleLeft,
  faChevronCircleRight,
} from "@fortawesome/pro-regular-svg-icons";
import { useLocation } from "react-use";

type Props = {
  menuData: any;
  isPermitted: (
    mode: "cnsl" | "core" | "primary-org-cnsl",
    value: string | RegExp | string[] | undefined,
    inclusive?: boolean | undefined
  ) => boolean;
  parent: "cnsl" | "fast";
  // These are passed in to allow for custom routing components (both NEXTJS and React Router)
  reactRouterLinkComponent?: any;
  nextRouterLinkComponent?: any;
  location: string;
};

export function VerticalStackMenu(
  props: Props & HTMLAttributes<HTMLDivElement>
) {
  const { menuData, isPermitted } = props;
  const currLoc = useLocation();
  const pathName = currLoc?.pathname || "";

  // Flatten Array items to get all the routes
  const flattenNavItems = (items: any) => {
    let result: any = [];

    items &&
      items.length &&
      items.forEach((item: any) => {
        // Destructure the item to exclude the 'childrens' property
        const { childrens, ...itemWithoutChildrens } = item;

        // Check if the 'path' property has a value
        if (itemWithoutChildrens.path) {
          result.push(itemWithoutChildrens);
        }

        if (childrens && childrens.length > 0) {
          result = result.concat(flattenNavItems(childrens));
        }
      });

    return result;
  };

  const allRoutes = flattenNavItems(menuData);

  /**
   * Given react router path, lookup the route id
   * @param path
   * @returns the route id or empty string if no active route found
   */
  const findRouteIdByPath = (path: string) => {
    const pathWithoutHash = path.split("#")[0];

    if (pathWithoutHash === "/apis" || path === "/docs") {
      return path;
    }

    // strict match first
    for (const rt of allRoutes) {
      if (pathWithoutHash === rt.path!) {
        return rt.uid;
      }
    }

    // then fuzzy match
    for (const rt of allRoutes) {
      if (pathWithoutHash.startsWith(rt.path!)) {
        return rt.uid;
      }
    }

    return "";
  };

  // Get currently active route
  const activeRoute = findRouteIdByPath(props.location);

  // Establish state using currently active route
  const [active, setActive] = useState(activeRoute);

  // Expanded?
  const [expanded, setExpanded] = useState(true);
  const toggleExpanded = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  const [initialNavOpenGroups, setInitialNavOpenGroups] = useState<string[]>(
    []
  );

  // On route change, switch active route
  useEffect(() => {
    const routeId = findRouteIdByPath(pathName);
    setActive(routeId);
  }, [props.location, pathName, allRoutes]);

  function setOpenGroupState(openGroupItem: string) {
    if (openGroupItem && !initialNavOpenGroups?.includes?.(openGroupItem)) {
      initialNavOpenGroups.push(openGroupItem);
      setInitialNavOpenGroups([...initialNavOpenGroups]);
    } else if (!openGroupItem && initialNavOpenGroups?.length) {
      // resetting it on nav click
      setInitialNavOpenGroups([]);
    }
  }

  // Recursive Menu Component
  const MenuItem = ({ item, level = 0 }: any) => {
    if (item && item.length > 0) {
      return (
        <NavCategory label={item.title}>
          {item.map((child: any) => (
            <MenuItem
              key={child.uid}
              id={item.uid}
              item={child}
              level={level + 1}
            />
          ))}
        </NavCategory>
      );
    } else {
      return item.type === "header" ? (
        <NavCategory label={item.title}>
          <div></div>
        </NavCategory>
      ) : (
        <NavItem
          fast
          key={item.uid}
          id={item.uid}
          label={item.title}
          link={item.path}
          tier={level + 1}
        />
      );
    }
  };

  return (
    <>
      <div className={s.vstackContainer}>
        {menuData && menuData?.length && (
          <Nav
            isDashboard={false}
            active={active}
            className={classNames(
              props.className,
              expanded ? s.expanded : s.collapsed
            )}
            isPermitted={isPermitted}
            location={props.location}
            parent={props.parent}
            reactRouterLinkComponent={props.reactRouterLinkComponent}
            nextRouterLinkComponent={props.nextRouterLinkComponent}
            initialOpenGroups={
              initialNavOpenGroups?.length ? initialNavOpenGroups : []
            }
          >
            {menuData.map((item: any, idx: any) =>
              item.childrens.length ? (
                <NavGroup
                  id={item.uid}
                  label={item.title}
                  tier={1}
                  accordion={true}
                  currItem={item}
                  navType={"DocsAPIMenu"}
                  key={`NavGroup-${idx}-${item.uid}`}
                  sendOpenGroupItem={setOpenGroupState}
                >
                  <MenuItemDocs
                    key={`MenuItemDocs-${idx}-${item.uid}`}
                    item={item}
                    sendOpenGroupItem={setOpenGroupState}
                  />
                </NavGroup>
              ) : (
                <NavItem
                  closeOtherGroupsOnClick
                  fast
                  key={item.uid}
                  label={item.title}
                  link={item.path}
                  tier={1}
                  id={item.uid}
                />
              )
            )}
          </Nav>
        )}
      </div>
    </>
  );
}
