import labels from "./operations-routes-labels.json";
import type { Route } from '../routes/index'

export const operationsRoutes: Record<string, Route> = {
  achFedReversalRoute: {
    id: "achFedReversal",
    label: labels.achFedReversal,
    path: "/achFedReversal",
    inclusive: true,
    coreOperation: [
      "SystemCalendarList",
      "ReverseOutgoingACHFile",
      "ReverseOutgoingACHEntry",
      "ReverseOutgoingACHBatch",
    ],
  },
  fdic370Route: {
    id: "fdic370",
    label: labels.fdic370,
    path: "/fdic370",
    coreOperation: "BankClose",
  },
  glBalancingRoute: {
    id: "glBalancing",
    label: labels.glBalancing,
    path: "/glBalancing",
    coreOperation: "ListFiles",
    consoleOperation: "gl-balancing-list",
  },
  glExtractsRoute: {
    id: "glExtracts",
    label: labels.glExtracts,
    path: "/glExtracts",
    coreOperation: "ListFiles",
    consoleOperation: "gl-extracts-file-list",
  },
  escheatExtractsRoute: {
    id: "escheatExtracts",
    label: labels.escheatExtracts,
    path: "/escheatExtracts",
    coreOperation: "ListFiles",
    consoleOperation: "escheat-extracts-file-list", // FIXME
  },
  fidmProcessingRoute: {
    id: 'fidm',
    label: labels.fidm,
    path: '/fidm',
    coreOperation: 'ListFiles',
    consoleOperation: 'fidm-file-list',
  },
  journalRoute: {
    id: "journal",
    label: labels.journal,
    path: "/journal",
    coreOperation: "JournalList",
  },
  openExceptionsExtractsRoute: {
    id: "openExceptionsExtracts",
    label: labels.openExceptionsExtracts,
    path: "/openExceptionsExtracts",
    coreOperation: "ListFiles",
    consoleOperation: "open-exceptions-extracts-file-list",
  },
  reportsRoute: {
    id: "reports",
    label: labels.reports,
    path: "/reports",
    coreOperation: "ListFiles",
    consoleOperation: "reports-file-list",
  },
  externalReportsRoute: {
    id: "externalReports",
    label: labels.externalReports,
    path: "/externalReports",
    coreOperation: "ListFiles",
    consoleOperation: "reports-file-list",
  },
  taxFilesAndExtractsRoute: {
    id: "taxFilesAndExtracts",
    label: labels.taxFilesAndExtracts,
    path: "/taxFilesAndExtracts",
    coreOperation: "TaxFileList",
  },
  // Batch File Management
  batchInformationRoute: {
    id: "batchInformation",
    label: labels.batchInformation,
    path: "/batchInformation",
    coreOperation: "BatchList",
  },
  achRoute: {
    id: "ach",
    label: labels.ach,
    path: "/ach",
    coreOperation: "ListFiles",
    consoleOperation: "ach-file-list",
  },
  achCorporateOriginationRoute: {
    id: "achCorporateOrigination",
    label: labels.achCorporateOrigination,
    path: "/achCorporateOrigination",
    coreOperation: "ListFiles",
    consoleOperation: "ach-file-list",
  },
  achBatchRoute: {
    id: "achBatch",
    label: labels.achBatch,
    path: "/achBatch",
    coreOperation: "ListFiles",
    consoleOperation: "ach-file-list",
  },
  achBatchExceptionRoute: {
    id: "achBatchException",
    label: labels.achBatchException,
    path: "/achBatchException",
    coreOperation: "ListFiles",
    consoleOperation: "ach-file-list",
  },
  achOriginInstructionRoute: {
    id: "achOriginInstr",
    label: labels.achOriginInstr,
    path: "/achOriginInstr",
    coreOperation: "ListFiles",
    consoleOperation: "ach-file-list",
  },
  checkRoute: {
    id: "check",
    label: labels.check,
    path: "/check",
    coreOperation: "ListFiles",
    consoleOperation: "check-file-list",
  },
  batchTransactionRoute: {
    id: "batchTransaction",
    label: labels.batchTransaction,
    path: "/batchTransaction",
    coreOperation: "ListFiles",
    consoleOperation: "core-batch-file-list",
  },
  // Diagnostic Tools
  healthCheckRoute: {
    id: "healthCheckDash",
    label: labels.healthCheckDash,
    path: "/healthCheckDash",
    coreOperation: [
      "SystemCalendarList",
      "PosnCalendarList",
      "EventCtxtList",
      "ErrLogList",
      "GetPosnCalendarMissingEvents",
    ],
    inclusive: true,
  },
  // new diagnostics dashboard
  diagnosticsDashboardRoute: {
    id: "diagnosticsDashboard",
    label: labels.diagnosticsDashboard,
    path: "/diagnosticsDashboard",
    coreOperation: [
      "SystemCalendarList",
      "PosnCalendarList",
      "EventCtxtList",
      "ErrLogList",
      "GetPosnCalendarMissingEvents",
    ],
    inclusive: true,
  },
  systemCalendarTimelineRoute: {
    id: "systemCalendarTimeline",
    label: labels.systemCalendarTimeline,
    path: "/systemCalendarTimeline",
    coreOperation: "SystemCalendarList",
  },
  eventContextRoute: {
    id: "eventContext",
    label: labels.eventContext,
    path: "/eventContext",
    coreOperation: "EventCtxtList",
  },
  errLogRoute: {
    id: "errLog",
    label: labels.errLog,
    path: "/errLog",
    coreOperation: "ErrLogList",
  },
  errLogBulkResolveRoute: {
    id: "errLog",
    label: labels.errLog,
    path: "/errLog/bulkResolve",
    coreOperation: "ErrLogList",
  },
  etlRoute: {
    id: "etl",
    label: labels.etl,
    path: "/ETL",
    coreOperation: "GetEtlStatus",
  },
  posnCalEventsRoute: {
    id: "posnCalEvents",
    label: labels.posnCalEvents,
    path: "/posnCalEvents",
    coreOperation: "PosnCalendarList",
  },
  // Orders
  orderACHRoute: {
    id: "orderACH",
    label: labels.orderACH,
    path: "/orderACH",
    coreOperation: "OrderList",
  },
  orderCORERoute: {
    id: "orderCORE",
    label: labels.orderCORE,
    path: "/orderCORE",
    coreOperation: "OrderList",
  },
  orderFEDWIRERoute: {
    id: "orderFEDWIRE",
    label: labels.orderFEDWIRE,
    path: "/orderFEDWIRE",
    coreOperation: "OrderList",
  },
  // Recon
  reconBatchRoute: {
    id: "reconBatch",
    label: labels.reconBatch,
    path: "/reconBatch",
    coreOperation: "ReconBatch",
  },
  reconTrnRoute: {
    id: "reconTrn",
    label: labels.reconTrn,
    path: "/reconTrn",
    coreOperation: "ReconTrn",
  },
}

export default operationsRoutes