import architectRoutes from './architect-routes'
import educationRoutes from './education-routes'
import extensibilityRoutes from './extensibility-routes'
import operationsRoutes from './operations-routes'

import { LazyExoticComponent } from 'react'

export type RoutePermissions = {
  consoleOperation?: string[] | string | RegExp
  coreOperation?: string | string[]
  inclusive?: true
}

export type Route = {
  id: string
  label: string
  path: string
  validCoreVns?: string
  exact?: boolean
} & RoutePermissions

export type RouteWithComponent = {
  component: LazyExoticComponent<any> | (() => JSX.Element | null)
} & Route

const redirectFromLoginRoute: Route = {
  id: 'login',
  label: 'Login Redirect',
  path: '/login',
}

export const dashboardRoute: Route = {
  id: 'dashboard',
  label: 'Dashboard',
  path: '/resourcesDash',
}

export const prospectDashboardRoute: Route = {
  id: 'prospectDashboard',
  label: 'Dashboard',
  path: '/prospectDashboard',
}

const dashboardRootRoute: Route = {
  id: 'dashboard',
  label: 'Dashboard',
  path: '/',
  exact: true,
}

const adminRoute: Route = {
  id: 'admin',
  label: 'Admin',
  path: '/admin',
}

const accountRoute: Route = {
  id: 'account',
  label: 'Account',
  path: '/account',
}

const environmentStatusRoute: Route = {
  id: 'environmentStatus',
  label: 'Environment Status',
  path: '/environmentStatus',
}

const _404Route: Route = {
  id: '_404',
  label: '404 Not Found',
  path: '*',
}

/**
 * All console proper routes (does not include foundational setup routes) as a map of
 * route ID to route object.
 */
export const routes: Record<string, Route> = {
  dashboardRoute,
  prospectDashboardRoute,
  redirectFromLoginRoute,
  dashboardRootRoute,
  adminRoute,
  accountRoute,
  ...architectRoutes,
  ...educationRoutes,
  ...extensibilityRoutes,
  ...operationsRoutes,
  environmentStatusRoute,
  _404Route,
}

/**
 * Array of all console proper routes (does not include foundational setup routes).
 */
export const routesArray = [
  dashboardRoute,
  prospectDashboardRoute,
  redirectFromLoginRoute,
  adminRoute,
  accountRoute,
  ...Object.values(architectRoutes),
  ...Object.values(educationRoutes),
  ...Object.values(extensibilityRoutes),
  ...Object.values(operationsRoutes),
  environmentStatusRoute,
  dashboardRootRoute,
  _404Route,
]

export const envCheckRoutesArray = [
  ...Object.values(architectRoutes),
  ...Object.values(operationsRoutes),
];
export * from './architect-routes'
export * from './education-routes'
export * from './extensibility-routes'
export * from './operations-routes'
export * from './foundational-setup-routes'