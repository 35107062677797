import React, { useMemo } from "react";
import { useClickAway } from "react-use";
import { useCallback, useRef, useState, useEffect } from "react";
import { consoleOperations, constants, paths } from "../../../constants";

import clsn from "classnames";
import s from "./../Header.module.scss";
import m from "./MobileNavigation.module.scss";
import { Route, routes } from "../../../routes";
import MobileMenu from "./../../Mobile/MobileMenu";

import { Search } from "../Search";
import { buildSwaggerEndpoint } from "../../../utils/buildSwaggerEndpoint";

const { coreVersionsSwaggerView, s3TrainingSiteView } = consoleOperations;

import {
  mobileMenu,
  academyMenu,
  dashboardMenuWrapper,
  foundationalSetup,
  architectWrapper,
  archProducts,
  architectGL,
  architectBank,
  schemaExtension,
  swagger,
  utilities,
  diagnostics,
  operations,
  implementation,
  resources,
  resourcesImplSiteUrl,
  swaggerCoreRoute,
  communications,
  archResources,
  reqService,
  coreServicing,
  global,
  tguid,
} from "./../../../utils/menu";
import { coreServerVersionSatisfies } from "../../../utils";

type HeaderProps = {
  className?: string;
  consoleConfig: {
    prodEnv: boolean;
    enableEcosystem?: boolean;
    searchAppId?: string;
    searchKey?: string;
    searchIndex?: string;
  };
  org?: {
    id: number;
    name: string;
    serviceTicketURL?: string | null;
    implementationUrl?: string | null;
    implRepoURL?: string | null;
  };
  pathName: string;
  isPermitted: (
    mode: "cnsl" | "core" | "primary-org-cnsl",
    value: string | RegExp | string[] | undefined,
    inclusive?: boolean | undefined
  ) => boolean;
  isTeaser?: boolean;
  organization?: {
    id: number;
    phase: string;
  };
  primaryOrg?: {
    category: string;
  };
  user?: {
    firstName?: string | null;
    lastName?: string | null;
    superUser?: boolean;
  };
  defaultLogo: string;
  hamburgerIcon: string;
  closeIcon: string;
  chatOpenIcon?: string;
  chatClosedIcon?: string;
  // These are passed in until we move them to the shared location as well in future tickets
  orgEnvSelector?: any;
  searchBar?: any;
  menuData?: any;
  reactRouterLinkComponent?: any;
  nextRouterLinkComponent?: any;
  parent: "cnsl" | "fast";
  project?: {
    id: number;
    name: string;
    status?: string;
  };
  coreVersion?: string;
  environment?: {
    id: number;
    name: string;
    tier?: string;
    endpoint: string;
    internal?: boolean;
  };
  serviceEndpoint?: string;
};

export default function MobileNavigation(props: HeaderProps) {
  const {
    consoleConfig,
    pathName,
    isPermitted,
    environment,
    coreVersion,
    isTeaser,
    project,
    org,
    user,
    hamburgerIcon,
    closeIcon,
    menuData,
    reactRouterLinkComponent,
    nextRouterLinkComponent,
    parent,
    serviceEndpoint,
  } = props;
  const [navbarOpen, setNavbarOpen] = useState(false);
  const ref = useRef(null);

  const allOrgsGroup = org?.id === constants.allOrganizationsId;
  const serviceTicketURL = org?.serviceTicketURL;
  const implementationSiteUrl = org?.implementationUrl;
  const implementationRepoUrl = org?.implRepoURL;
  const dashboardLinksToFast = isPermitted("cnsl", "workflow-dashboard-access");
  const dashboardLink = dashboardLinksToFast ? "/dashboard" : "/resourcesDash";

  const { dashboardRoute } = routes;
  const { apisMenu, docsMenu } = menuData ?? {};
  const [menu, setMenu] = useState([]);

  let swaggerEndpoint: string;
  if (environment) {
    try {
      swaggerEndpoint = buildSwaggerEndpoint(
        environment?.endpoint,
        !!environment?.internal
      );
    } catch (e) {
      console.error("could not build swagger endpoint", e);
    }
  }

  const routeFilter: any = (route: Route) => {
    if (
      route.coreOperation &&
      !isPermitted("core", route.coreOperation, route.inclusive)
    ) {
      return false;
    }

    if (
      route.consoleOperation &&
      !isPermitted("cnsl", route.consoleOperation)
    ) {
      return false;
    }

    if (
      route.validCoreVns &&
      !coreServerVersionSatisfies(route.validCoreVns, coreVersion)
    ) {
      return null;
    }

    return true;
  };

  const removeSlash = (str: string) => {
    return str.replace(/\//g, "");
  };
  const renderDashboardMenu = () => {
    const menu: any = JSON.parse(JSON.stringify(dashboardMenuWrapper));
    let navItems = menu.docCenterNavigationItems[0].childrens;

    if ((environment?.id || project?.id) && coreVersion != null && navItems?.length) {
      navItems[0].path = dashboardLink;
      navItems[0].uid = removeSlash(dashboardLink);
      if (isPermitted("cnsl", "workflow-dashboard-access")) {
        foundationalSetup.childrens.filter(routeFilter);
        navItems.push(foundationalSetup);
      }
      if (
        !allOrgsGroup &&
        (!project || project.status !== 'conflicted') &&navItems[2]?.childrens.length
      ) {
        navItems.push(JSON.parse(JSON.stringify(architectWrapper)));
        if (isPermitted("core", "Prod_bkList")) {
          if (isPermitted("cnsl", "workflow-dashboard-access")) {
            navItems[2].childrens[0].childrens =
              archProducts.filter(routeFilter);
          } else {
            archProducts.shift();
            navItems[2].childrens[0].childrens =
              archProducts.filter(routeFilter);
          }
          architectGL.childrens.filter(routeFilter);
          architectBank.childrens.filter(routeFilter);
          schemaExtension.filter(routeFilter);
          utilities.filter(routeFilter);
          tguid.childrens.filter(routeFilter);
          navItems[2].childrens.push(architectGL);
          navItems[2].childrens.push(architectBank);
          if (project) {
            navItems[2].childrens = [
              ...navItems[2].childrens,
              ...schemaExtension,
            ];
          }
          if (!environment || !!project || !swaggerEndpoint) {
            swagger.path = swaggerEndpoint ?? "";
            swagger.uid = removeSlash(swaggerEndpoint ?? "");
            navItems[2].childrens.push(swagger);
          }
          navItems[2].childrens = [...navItems[2].childrens, ...utilities];
          navItems[2].childrens.push(tguid);
          if (isPermitted("cnsl", coreVersionsSwaggerView)) {
            navItems[2].childrens.push(swaggerCoreRoute);
          }
          navItems[2].childrens.push(communications);
          if (isPermitted("cnsl", s3TrainingSiteView)) {
            navItems[2].childrens.push(archResources);
          }
        }
      }
      if (!project || project.status !== "conflicted") {
        navItems.push(diagnostics);
      }
      if (!project || project.status !== "conflicted") {
        navItems.push(operations);
      }
      if (implementationRepoUrl) {
        navItems.push(implementation);
      }
      navItems.push(resources);
      if (implementationSiteUrl) {
        resources.childrens.push(resourcesImplSiteUrl);
      }
      if (!environment || !!project || !swaggerEndpoint) {
        swagger.path = swaggerEndpoint ?? "";
        swagger.uid = removeSlash(swaggerEndpoint ?? "");
        resources.childrens.push(swagger);
      }
      if (allOrgsGroup) {
        navItems.push(global);
      }
      if (serviceEndpoint) {
        navItems.push(coreServicing);
      }
      if (!allOrgsGroup) {
        reqService.path = serviceTicketURL ?? "";
        reqService.uid = removeSlash(serviceTicketURL ?? "");
        navItems.push(reqService);
      }
    }
    return menu;
  };

  const getActiveNavLinkPath = useCallback(() => {
    if (pathName.startsWith(paths.academy)) {
      return paths.academy;
    } else if (pathName.startsWith(paths.docs)) {
      return paths.docs;
    } else if (pathName.startsWith(paths.apis)) {
      return paths.apis;
    } else if (!isTeaser) {
      return dashboardLink;
    } else {
      return "";
    }
  }, [pathName]);

  const dashboardData = renderDashboardMenu();
  mobileMenu.docCenterNavigationItems[0].childrens[0].childrens =
    dashboardData.docCenterNavigationItems[0].childrens;

  mobileMenu.docCenterNavigationItems[0].childrens[1].childrens =
    academyMenu.docCenterNavigationItems[0].childrens;
  if (
    mobileMenu?.docCenterNavigationItems?.[0]?.childrens?.[2] &&
    docsMenu?.docCenterNavigationItems?.[0]?.childrens
  ) {
    mobileMenu.docCenterNavigationItems[0].childrens[2].childrens =
      docsMenu.docCenterNavigationItems[0].childrens;
  }
  if (
    mobileMenu?.docCenterNavigationItems?.[0]?.childrens?.[3] &&
    apisMenu?.docCenterNavigationItems?.[0]?.childrens
  ) {
    mobileMenu.docCenterNavigationItems[0].childrens[3].childrens =
      apisMenu.docCenterNavigationItems[0].childrens;
  }
  const [activeTab, setActiveTab] = useState(getActiveNavLinkPath());

  const handleTabClick = (tab: string) => {
    setActiveTab(`/${tab}`);
  };

  useEffect(() => {
    setMenu(mobileMenu);
  }, [
    activeTab,
    docsMenu,
    apisMenu,
    academyMenu,
    environment,
    project,
    coreVersion,
  ]);

  useClickAway(ref, () => setNavbarOpen(false));
  // Define the function to toggle navbar state
  const toggleNavbar = () => {
    setActiveTab(getActiveNavLinkPath());
    setNavbarOpen((prev) => !prev);
  };
  return (
    <>
      <div ref={ref} className={m.fullHeight}>
        <div className={m.hamburgerIcon}>
          <button className={m.toggle} onClick={() => toggleNavbar()}>
            {navbarOpen ? (
              <img src={closeIcon} width="32" height="32" alt="Close icon" />
            ) : (
              <img
                src={hamburgerIcon}
                width="32"
                height="32"
                alt="Hamburger icon"
              />
            )}
          </button>
        </div>
        {navbarOpen && (
          <div className={m.menuContainer}>
            <div className={clsn(m.searchWrapperMobile)}>
              <Search
                siteFunction="mobile"
                pathName={pathName}
                consoleConfig={consoleConfig}
                isAuthenticated={Boolean(user)}
              />
            </div>
            {
              <MobileMenu
                nextRouterLinkComponent={nextRouterLinkComponent}
                reactRouterLinkComponent={reactRouterLinkComponent}
                navigationData={menu}
                toggleNavbar={toggleNavbar}
                {...props}
              />
            }
          </div>
        )}
      </div>
    </>
  );
}
