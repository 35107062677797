const uiSchema = {
    'ui:order': ['prodProgram', '*'],
    prodProgram: {
      'ui:widget': 'fxtSelectWidget',
      'ui:options': {
        apis: [
          {
            api: '/model/prodProgram',
            keyObject: 'prodProgram',
            valObject: 'prodProgram',
          },
        ],
      },
    },
  }

  export default uiSchema