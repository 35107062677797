import React from 'react'
import {
  faChevronDown,
  faChevronRight,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsn from 'classnames'
import { useState } from 'react'
import s from './OrgEnvSelector.module.scss'
import OrgRow from './OrgRow'
import { Organization } from './OrgEnvDropdown'

type Props = {
  collapsable?: true
  collapsed?: true
  favOrgIds: number[]
  heading: string
  orgs: Organization[]
  onClick: (orgId: number) => void
  onClickFav: (orgId: number, isFav: boolean) => void
  selectedOrgId?: number
}

export default function EnvProjRowGroup(props: Props) {
  const [collapsed, setCollapsed] = useState(props.collapsed ? true : false)

  const body = props.orgs.map((o) => (
    <OrgRow
      isFav={
        props.favOrgIds.find((val) => {
          return val === o.id
        })
          ? true
          : false
      }
      key={o.id}
      isGrouped
      onClick={() => {
        props.onClick(o.id)
      }}
      onClickFavOrg={props.onClickFav}
      org={o}
      selected={o.id === props.selectedOrgId ? true : undefined}
    />
  ))

  if (props.collapsable) {
    return (
      <>
        <h3
          className={clsn(s.heading, 'd-flex', 'justify-content-between')}
          onClick={() => {
            setCollapsed(!collapsed)
          }}
          role='button'
        >
          {props.heading}
          <FontAwesomeIcon icon={collapsed ? faChevronRight : faChevronDown} />
        </h3>
        <div className={collapsed ? 'd-none' : 'd-block'}>{body}</div>
      </>
    )
  } else {
    return (
      <>
        <h3 className={s.heading}>{props.heading}</h3>
        {body}
      </>
    )
  }
}
