import {
  extCancelledProjectPath,
  extCompletedProjectPath,
  extOpenProjectPath,
} from '../constants';
import { consoleOperations } from '../constants';
import labels from './extensibility-routes-labels.json';
import type { Route } from '../routes/index';

const {
  extensionsDslEditor,
  extensionsExpertMode,
  extensionsSchemaRead,
  permissionRequestEnvironmentDeploys,
  permissionRequestEnvironmentTimeshifts,
  permissionRequestEnvironmentSmartTimeshifts,
  projectView,
} = consoleOperations;

// IMPORTANT: The order of the route exports below matters.  Most specific routes must
//    come before less specific routes to ensure matching works appropriately.
export const extensibilityRoutes: Record<string, Route> = {
  deployRoute: {
    id: 'deployBuild',
    label: labels.deployBuild,
    path: `/extensions/build/deploy/:id`,
    consoleOperation: permissionRequestEnvironmentDeploys,
  },
  buildsListRoute: {
    id: 'buildsList',
    label: labels.buildsList,
    path: '/extensions/build',
    exact: true,
    consoleOperation: permissionRequestEnvironmentDeploys,
  },
  deploymentsListRoute: {
    id: 'deploymentsList',
    label: labels.deploymentsList,
    path: '/extensions/deployments',
    consoleOperation: permissionRequestEnvironmentDeploys,
  },
  timeshiftsListRoute: {
    id: 'timeshiftsListRoute',
    label: labels.timeshiftsListRoute,
    path: '/extensions/timeshifts',
    consoleOperation: permissionRequestEnvironmentTimeshifts,
  },
  timeshiftRoute: {
    id: 'timeshiftRoute',
    label: labels.timeshiftRoute,
    path: '/extensions/timeshift/execute',
    consoleOperation: permissionRequestEnvironmentTimeshifts,
  },
  smartTimeshiftRoute: {
    id: 'smartTimeshiftRoute',
    label: labels.smartTimeShiftRoute,
    path: '/extensions/timeshift/submit',
    consoleOperation: permissionRequestEnvironmentSmartTimeshifts,
  },
  environmentHealthRoute: {
    id: 'environmentHealth',
    label: labels.environmentHealth,
    path: '/environmentHealth',
  },
  bankConfigRoute: {
    id: 'bankConfiguration',
    label: labels.bankConfiguration,
    path: '/bank-config',
  },
  projectViewRoute: {
    id: 'projectView',
    label: labels.projectView,
    path: `${extOpenProjectPath}:id`,
    consoleOperation: projectView,
  },
  cancelledProjectViewRoute: {
    id: 'cancelledProjectView',
    label: labels.cancelledProjectView,
    path: `${extCancelledProjectPath}:id`,
    consoleOperation: projectView,
  },
  completedProjectViewRoute: {
    id: 'completedProjectView',
    label: labels.completedProjectView,
    path: `${extCompletedProjectPath}:id`,
    consoleOperation: projectView,
  },
  schemaAdvancedRoute: {
    id: 'projectSchemaAdvanced',
    label: labels.projectSchemaAdvanced,
    path: `/extensions/schema-and-choices/schema/:filename`,
    consoleOperation: extensionsSchemaRead,
  },
  choicesAdvancedRoute: {
    id: 'projectChoicesAdvanced',
    label: labels.projectChoicesAdvanced,
    path: `/extensions/schema-and-choices/choices/:filepath*`,
    consoleOperation: extensionsExpertMode,
  },
  choicesRoute: {
    id: 'projectChoices',
    label: labels.projectChoices,
    path: `/extensions/schema-and-choices#choices`,
    consoleOperation: extensionsSchemaRead,
  },
  schemaRoute: {
    id: 'projectSchema',
    label: labels.projectSchema,
    path: `/extensions/schema-and-choices#schema`,
    consoleOperation: extensionsSchemaRead,
  },
  projectSchemaAndChoicesRoute: {
    id: 'projectSchemaAndChoices',
    label: labels.projectSchemaAndChoices,
    path: `/extensions/schema-and-choices`,
    consoleOperation: extensionsSchemaRead,
  },
  dslEditorRoute: {
    id: 'projectDSLEditor',
    label: labels.projectDSLEditor,
    path: `/extensions/editor`,
    consoleOperation: extensionsDslEditor,
  },
  publishRoute: {
    id: 'projectPublish',
    label: labels.projectPublish,
    path: '/extensions/publish',
    consoleOperation: projectView,
  },
  resolveConflictsRoute: {
    id: 'projectResolveConflicts',
    label: labels.projectResolveConflicts,
    path: '/extensions/resolveConflicts',
    consoleOperation: projectView,
  },
  changesRoute: {
    id: 'projectChanges',
    label: labels.projectChanges,
    path: '/extensions/changes',
    consoleOperation: projectView,
  },
  projectListRoute: {
    id: 'projectList',
    label: labels.projectList,
    path: '/extensions/project',
    exact: true,
    consoleOperation: projectView,
  },
  extDashboardRoute: {
    id: 'extDashboard',
    label: labels.extDashboard,
    path: '/extensions/dashboard',
    consoleOperation: projectView,
  },
  extSchemaRoute: {
    id: 'extSchema',
    label: labels.extSchema,
    path: '/extensions/main/schema',
    consoleOperation: projectView,
  },
  extDslRoute: {
    id: 'extDsl',
    label: labels.extDsl,
    path: '/extensions/main/dsl',
    consoleOperation: projectView,
  },
  ruleLogRoute: {
    id: 'ruleLog',
    label: labels.ruleLog,
    path: '/extensions/rulelog',
    consoleOperation: projectView,
  },
};

export default extensibilityRoutes;
