import React, {
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Button, Overlay, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Provider } from 'react-redux'
import OrgEnvSelector from './OrgEnvSelector'
import s from './OrgEnvSelector.module.scss'
import { orgEnvStore } from './orgEnvSelectorStore'
import { ORG_STATUS } from '@/constants'
import { faChevronUp, faChevronDown } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsn from 'classnames'

export type Organization = {
  id: number
  name: string
  category: string
  status: keyof typeof ORG_STATUS
}

export type Project = {
  id: number
  name: string
}

export type Environment = {
  id: number
  name: string
}

export function OrgEnvDropdown(props: {
  org?: Organization
  orgs: Organization[]
  proj?: Project
  projs: Project[]
  env?: Environment
  envs: Environment[]
  onOrgSelect: (id: number) => void
  onEnvSelect: (id: number) => void
  onProjSelect: (id: number) => void
  orgEnvSelectorRef: RefObject<HTMLButtonElement>
  showTooltip: boolean
}) {
  const {
    org,
    orgs,
    proj,
    projs,
    env,
    envs,
    orgEnvSelectorRef,
    onOrgSelect,
    onEnvSelect,
    onProjSelect,
    showTooltip,
  } = props

  const [isOpen, toggleIsOpen] = useState(false)

  const _renderLabel = useMemo(() => {
    switch (true) {
      case Boolean(env?.id):
        return env?.name
      case Boolean(proj?.id):
        return proj?.name
      case orgs.length === 1:
        return 'Select environment'
      default:
        return 'Select an Org / Env'
    }
  }, [env, proj, org, orgs, envs])

  // Auto select environment/project if only 1 exists for the org and there is no selection for a current env or proj
  useEffect(() => {
    if (envs.length === 1 && !projs.length && !env && !proj) {
      onEnvSelect(envs[0].id)
    }
    if (projs.length === 1 && !envs.length && !env && !proj) {
      onProjSelect(projs[0].id)
    }
  }, [env, envs, org])

  const _renderTooltip = useCallback(() => {
    if (!envs.length) {
      return 'An environment is required to access the rest of Finxact'
    }
    if (orgs.length > 1) {
      return 'Select an org and environment to leverage the configuration menu items'
    }
    return 'Select an environment to leverage the configuration menu items'
  }, [orgs, envs])

  return org ? (
    <Provider store={orgEnvStore}>
      <OverlayTrigger
        rootClose
        trigger='click'
        placement='bottom-start'
        onToggle={(nextVal) => toggleIsOpen(nextVal)}
        overlay={(props) => (
          <Tooltip
            {...props}
            bsPrefix='shared'
            arrowProps={{
              style: { display: 'none' },
            }}
            className={clsn(
              s.orgEnvDropdown,
              orgs.length === 1 ? s.onlyEnv : null
            )}
          >
            <OrgEnvSelector
              org={org}
              orgs={orgs}
              proj={proj}
              projs={projs}
              env={env}
              envs={envs}
              onOrgSelect={onOrgSelect}
              onEnvSelect={onEnvSelect}
              onProjSelect={onProjSelect}
            />
          </Tooltip>
        )}
      >
        <Button className={s['dropdown-trigger']} ref={orgEnvSelectorRef}>
          <div className='text-start flex-grow-1'>{_renderLabel}</div>
          <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} />
        </Button>
      </OverlayTrigger>
      <Overlay
        target={orgEnvSelectorRef.current}
        placement='right'
        show={showTooltip}
      >
        {(props) => (
          <Tooltip {...props} bsPrefix='noEnv' className={s.envTooltip}>
            {_renderTooltip()}
          </Tooltip>
        )}
      </Overlay>
    </Provider>
  ) : null
}
