// create swagger endpoint from the env endpoint
export function buildSwaggerEndpoint(endpoint: string, internal: boolean) {
  let swaggerUrl = "";
  if (endpoint) {
    const urlParsed = endpoint.split(".");
    for (let i = 0; i < urlParsed.length; i++) {
      if (i === 0) {
        urlParsed[i] = `${urlParsed[i]}-swagger`;
        swaggerUrl = swaggerUrl + urlParsed[i];
      } else {
        swaggerUrl = `${swaggerUrl}.${urlParsed[i]}`;
      }
    }
  }

  // Although when connecting to internal environments, console must use http,
  //    the user still needs to use https to get to swagger.
  if (internal) {
    swaggerUrl = swaggerUrl.replace("http://", "https://");
  }

  return swaggerUrl;
}
