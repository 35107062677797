import React from 'react'
import { Form } from "react-bootstrap"

const matrixFieldUiSchema = {
  'ui:widget': 'fxtSelectWidget',
  'ui:options': {
    apis: [
      {
        api: '/model/matrixType',
        keyObject: 'matrixName',
        valObject: 'matrixName',
      },
    ],
  }
}

export const feeCalcDtlUnRequire = [
  'FeeCalcDtlFeeLimits._Ix',
  'FeeCalcDtlFeeLimits.name',
]

const getUiSchema = (feeAppMode?: string, setModeTrnCode?: () => void, setModeOffset?: () => void) => {
    return {
      'ui:order': [
        'name',
        'desc',
        'trnCode',
        'feeAcctGroup',
        'feeAcctNbr',
        'isAccrFee',
        'assessAt',
        'feeFreq',
        'feeAmt',
        'feePct',
        'feeBalOpt',
        'feeMatrix',
        'feeMinAmt',
        '*',
        'feeLimits',
      ],
      feeAcctGroup: {
        'ui:widget':
          feeAppMode && feeAppMode !== 'offset'
            ? 'hidden'
            : 'fxtSelectWidget',
        'ui:options': {
          apis: [
            {
              api: '/model/acctGroup',
              keyObject: 'acctGroup',
              valObject: 'desc',
              where: 'acctClass=2',
            },
          ],
        },
      },
      feeAcctNbr: {
        'ui:widget':
          feeAppMode && feeAppMode !== 'offset'
            ? 'hidden'
            : 'fxtSelectWidget',
        'ui:options': {
          apis: [
            {
              api: '/model/acct_gl',
              keyObject: 'acctNbr',
              valObject: 'acctTitle',
            },
          ],
          filters: [
            {
              object: 'acctGroup',
              field: 'feeAcctGroup',
            },
          ],
          includeKeyInDesc: true,
          windowed: true,
        },
      },
      feeAcctgSeg: { 'ui:field': 'fxtPatternPropField' },
      isAccrFee: {
        'ui:widget':
        feeAppMode && feeAppMode !== 'offset' ? 'hidden' : undefined,
      },
      trnCode: {
        'ui:before': (
          <Form.Group>
            <label>Fee Application via:</label>
            <div>
              <Form.Check
                checked={feeAppMode === 'trnCode'}
                id='fee-application-trn-code'
                label='Transaction Code'
                onChange={setModeTrnCode}
                type='radio'
              />
              <Form.Check
                checked={feeAppMode === 'offset'}
                id='fee-application-offset-account'
                label='Offset Account'
                onChange={setModeOffset}
                type='radio'
              />
            </div>
          </Form.Group>
        ),
        'ui:widget':
          feeAppMode && feeAppMode !== 'trnCode'
            ? 'hidden'
            : 'fxtSelectWidget',
        'ui:options': {
          apis: [
            {
              api: '/model/trnCode',
              keyObject: 'trnCode',
              valObject: 'desc',
            },
          ],
          includeKeyInDesc: true,
        },
      },
      feeMatrix: matrixFieldUiSchema,
      feeFreqMatrix: matrixFieldUiSchema,
      feeMaxPctMatrix: matrixFieldUiSchema,
      feeMinPctMatrix: matrixFieldUiSchema,
      feeLimits: {
        'ui:options': { orderable: false },
        'ui:emptyMessage':
          'No fee limits are defined. Add a fee limit via the button on the right.',
        items: {
          'ui:order': ['name', '*'],
          _Ix: { 'ui:widget': 'hidden' },
          name: { 'ui:widget': 'hidden' },
          statGroup: {
            'ui:widget': 'fxtSelectWidget',
            'ui:options': {
              apis: [
                {
                  api: '/model/trnStatGroup',
                  keyObject: 'statGroup',
                  valObject: 'desc',
                },
              ],
            },
          },
        },
      },
      rebate: {
        'ui:order': [
          'rebateTrnCode',
          'rebateAcctGroup',
          'rebateAcctNbr',
          'rebateAcctgSeg',
          'assessAt',
          'rebateFreq',
          'rebateAmt',
          'rebatePct',
          'rebateMatrix',
          'rebateMinAmt',
          'rebateMaxAmt',
          '*',
        ],
        rebateTrnCode: {
          'ui:widget': 'fxtSelectWidget',
          'ui:options': {
            apis: [
              {
                api: '/model/trnCode',
                keyObject: 'trnCode',
                valObject: 'desc',
              },
            ],
            includeKeyInDesc: true,
          },
        },
        rebateAcctGroup: {
          'ui:widget': 'fxtSelectWidget',
          'ui:options': {
            apis: [
              {
                api: '/model/acctGroup',
                keyObject: 'acctGroup',
                valObject: 'desc',
                where: 'acctClass=2',
              },
            ],
          },
        },
        rebateAcctNbr: {
          'ui:widget': 'fxtSelectWidget',
          'ui:options': {
            apis: [
              {
                api: '/model/acct_gl',
                keyObject: 'acctNbr',
                valObject: 'acctTitle',
              },
            ],
            filters: [
              {
                object: 'acctGroup',
                field: 'rebate.rebateAcctGroup',
              },
            ],
            includeKeyInDesc: true,
            windowed: true,
          },
        },
        rebateAcctgSeg: { 'ui:field': 'fxtPatternPropField' },
        rebateMatrix: matrixFieldUiSchema,
      },
      waiveFeeAcctNbr: {
        'ui:widget': 'fxtSelectWidget',
        'ui:options': {
          apis: [
            {
              api: '/model/acct_gl',
              keyObject: 'acctNbr',
              valObject: 'acctTitle',
            },
          ],
          filters: [
            {
              object: 'acctGroup',
              field: 'waiveFeeAcctGroup',
            },
          ],
          includeKeyInDesc: true,
          windowed: true,
        },
      },
  
      waiveFeeAcctGroup: {
        'ui:widget': 'fxtSelectWidget',
        'ui:options': {
          apis: [
            {
              api: '/model/acctGroup',
              keyObject: 'acctGroup',
              valObject: 'desc',
              where: 'acctClass=2',
            },
          ],
        },
      },
      waiveFeeTrnCode: {
        'ui:widget': 'fxtSelectWidget',
        'ui:options': {
          apis: [
            {
              api: '/model/trnCode',
              keyObject: 'trnCode',
              valObject: 'desc',
            },
          ],
          includeKeyInDesc: true,
        },
      },
  }
}

export default getUiSchema