import React, { useMemo } from 'react'
import clsn from 'classnames'
import {
  createContext,
  HTMLAttributes,
  ReactNode,
  useEffect,
  useReducer,
  useRef,
} from 'react'
import { useClickAway } from 'react-use'
import s from './Nav.module.scss'
import { Action, NavState, reducer } from './NavReducer'

type Props = {
  /**
   * Property to set 100% height only to Dashboard
   */
  isDashboard?: boolean
  /**
   * The id of the active NavItem (e.g. 'prod_bk')
   */
  active?: string

  /**
   * Child NavItems, NavGroups, and NavCategories
   */
  children?: ReactNode

  /**
   * Show a component in the header (used for org/env selector)
   */
  header?: ReactNode
  /**
   * Show a component in the footer (used for environment status)
   */
  footer?: ReactNode

  /**
   * Adds top padding for production banner
   */
  isProd?: boolean

  isPermitted: (
    mode: 'cnsl' | 'core' | 'primary-org-cnsl',
    value: string | RegExp | string[] | undefined,
    inclusive?: boolean | undefined
  ) => boolean

  parent: 'cnsl' | 'fast'

  reactRouterLinkComponent?: any
  nextRouterLinkComponent?: any
  location: string
  initialOpenGroups?: string[]
} & HTMLAttributes<HTMLDivElement>

type NavContextType = {
  navState: NavState
  active: string
  parent: 'cnsl' | 'fast'
  dispatch: (value: Action) => void
  isPermitted: (
    mode: 'cnsl' | 'core' | 'primary-org-cnsl',
    value: string | RegExp | string[] | undefined,
    inclusive?: boolean | undefined
  ) => boolean
  reactRouterLinkComponent?: any
  nextRouterLinkComponent?: any
}

export const NavContext = createContext<NavContextType>({
  navState: { openGroups: [] } as NavState,
  active: '',
  parent: 'cnsl',
  dispatch: (value: Action) => {
    // Do nothing
  },
  isPermitted: (mod, value, inclusive) => false,
})

/**
 * Nav is a component which renders a side navigation menu system.  The Nav is the top
 * level component and can accept a variety of children.  Nav will automatically close
 * any expanded nav groups on route change or on click outside of Nav.
 *
 * @param props
 * @returns
 */
export default function Nav(props: Props) {
  const ref = useRef<HTMLDivElement>(null)
  const [navState, dispatch] = useReducer(reducer, {
    openGroups: [],
  })

  function toggleGroup() {
    // toggle the tier 2 menu on nav change/click away
    if (props.isDashboard) {
      const currAction: Action =
        props.isDashboard && navState?.openGroups?.length > 1
          ? ({
              type: 'TOGGLE_GROUP',
              tier: navState.openGroups.length,
              id: navState.openGroups[navState.openGroups.length - 1] ?? '',
            } as Action)
          : {
              type: 'CLOSE_ALL_GROUPS',
            }
      dispatch(currAction)
    }
  }

  // Close nav groups on route change
  useEffect(() => {
    toggleGroup()
  }, [props.location])

  useEffect(() => {
    if (props.active && props?.initialOpenGroups?.length) {
      dispatch({
        type: 'SET_GROUPS',
        openGroups: props.initialOpenGroups,
      })
    }
  }, [props.initialOpenGroups])

  // Close nav groups on click outside menu
  useClickAway(ref, () => {
    toggleGroup()
  })

  const contextValue = useMemo(() => {
    return {
      navState: navState,
      active: props.active ?? '',
      parent: props.parent,
      dispatch: dispatch,
      isPermitted: props.isPermitted,
      reactRouterLinkComponent: props.reactRouterLinkComponent,
      nextRouterLinkComponent: props.nextRouterLinkComponent,
    }
  }, [
    navState,
    props.active,
    props.parent,
    dispatch,
    props.isPermitted,
    props.reactRouterLinkComponent,
    props.nextRouterLinkComponent,
  ])

  return (
    <NavContext.Provider value={contextValue}>
      <nav
        className={clsn(
          props.className,
          s.nav,
          s.themeDefault,
          props.isProd ? s.prod : null
        )}
        ref={ref}
      >
        <div
          className={clsn(
            s.navWrap,
            !props.isDashboard ? s.navWrapFullHeight : null,
            props.header ? s.withHeader : null,
            props.footer ? s.withFooter : null
          )}
        >
          {props.header ?? null}

          <div className={s.body}>{props.children ?? null}</div>

          {props.footer ?? null}
        </div>
      </nav>
    </NavContext.Provider>
  )
}
