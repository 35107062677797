import foundationalSetupRoutes from "./../routes/foundational-setup-routes";
import architectRoutes from "./../routes/architect-routes";
import operationsRoutes from "./../routes/operations-routes";
import educationRoutes from "./../routes/education-routes";
import extensibilityRoutes from "./../routes/extensibility-routes";

import { dashboardRoute } from "../routes";

import { consoleOperations, constants } from "../constants";

const { externalDocsPath } = constants;

export const academyMenu: any = {
  breadCrumbs: [],
  loading: false,
  docCenterNavigationItems: [
    {
      title: "",
      childrens: [ 
        {
          title: "My Learning",
          childrens: [],
          path: "/academy",
          type: "item",
          uid: "academy",
        },
        {
          title: "Academy Overview",
          childrens: [],
          path: "/academy/overview",
          type: "item",
          uid: "overview",
        },
        {
          title: "Catalog",
          childrens: [],
          path: "/academy/catalog",
          type: "item",
          uid: "catalog",
        },
        {
          title: "Badges & certifications",
          childrens: [],
          path: "/academy/badges-certifications",
          type: "item",
          uid: "badges-certifications",
        },
      ],
      path: "",
      type: "",
      uid: "",
    },
  ],
};

export const mobileMenu: any = {
  breadCrumbs: [],
  loading: false,
  type: "none",
  docCenterNavigationItems: [
    {
      title: "",
      childrens: [
        {
          title: "Dashboard",
          childrens: [],
          path: "",
          type: "item",
          uid: "dashboard",
        },
        {
          title: "Academy",
          childrens: [],
          path: "",
          type: "item",
          uid: "academy",
        },
        {
          title: "Docs",
          childrens: [],
          path: "",
          type: "item",
          uid: "docs",
        },
        {
          title: "APIs",
          childrens: [],
          path: "",
          type: "item",
          uid: "apis",
        },
      ],
      path: "",
      type: "",
      uid: "",
    },
  ],
};


export const dashboardMenuWrapper: any = {
  breadCrumbs: [],
  loading: false,
  docCenterNavigationItems: [
    {
      title: "",
      childrens: [
        {
          title: "Home",
          childrens: [],
          path: "",
          type: "",
          uid: "",
        },
      ],
      path: "",
      type: "",
      uid: "",
    },
  ],
};

export const foundationalSetup: any = {
  title: "Foundational Setup",
  childrens: [
    {
      title: foundationalSetupRoutes.institutionalConfiguration.label,
      childrens: [],
      path: foundationalSetupRoutes.institutionalConfiguration.path,
      type: "item",
      uid: "institutional-configuration",
    },
    {
      title: foundationalSetupRoutes.generalLedger.label,
      childrens: [],
      path: foundationalSetupRoutes.generalLedger.path,
      type: "item",
      uid: "general-ledger",
    },
    {
      title: foundationalSetupRoutes.transactionCodes.label,
      childrens: [],
      path: foundationalSetupRoutes.transactionCodes.path,
      type: "item",
      uid: "transaction-codes",
    },
    {
      title: foundationalSetupRoutes.networkRoute.label,
      childrens: [],
      path: foundationalSetupRoutes.networkRoute.path,
      type: "item",
      uid: "network",
    },
    {
      title: foundationalSetupRoutes.customerRelationshipTypes.label,
      childrens: [],
      path: foundationalSetupRoutes.customerRelationshipTypes.path,
      type: "item",
      uid: "customer-relationship-types",
    },
  ],
  path: "",
  type: "item",
  uid: "foundational-setup",
};

export const architectWrapper: any = {
  title: "Architect",
  path: "",
  childrens: [
    {
      title: "Product",
      childrens: [],
      path: "",
      type: "item",
      uid: "archProducts",
    },
  ],
  type: "item",
  uid: "architect",
};

export const archProducts: any = [
  {
    title: architectRoutes.prod_launchpadRoute.label,
    childrens: [],
    path: architectRoutes.prod_launchpadRoute.path,
    type: "item",
    uid: "product-launchpad",
  },
  {
    title: architectRoutes.prod_bkRoute.label,
    childrens: [],
    path: architectRoutes.prod_bkRoute.path,
    type: "item",
    uid: "prod_bk",
  },
  {
    title: architectRoutes.prod_usTbpRoute.label,
    childrens: [],
    path: architectRoutes.prod_usTbpRoute.path,
    type: "item",
    uid: "prod_usTbp",
  },
  {
    title: architectRoutes.usTbpTypeRoute.label,
    childrens: [],
    path: architectRoutes.usTbpTypeRoute.path,
    type: "item",
    uid: "usTbpType",
  },
  {
    title: architectRoutes.prodTypeRoute.label,
    childrens: [],
    path: architectRoutes.prodTypeRoute.path,
    type: "item",
    uid: "prodType",
  },
  {
    title: architectRoutes.prodSubTypeRoute.label,
    childrens: [],
    path: architectRoutes.prodSubTypeRoute.path,
    type: "item",
    uid: "prodSubType",
  },
  {
    title: architectRoutes.prodGroupRoute.label,
    childrens: [],
    path: architectRoutes.prodGroupRoute.path,
    type: "item",
    uid: "prodGroup",
  },
  {
    title: architectRoutes.componentCollateralRoute.label,
    childrens: [],
    path: architectRoutes.componentCollateralRoute.path,
    type: "item",
    uid: "componentCollateral",
  },
  {
    title: architectRoutes.componentDepChrgOffRoute.label,
    childrens: [],
    path: architectRoutes.componentDepChrgOffRoute.path,
    type: "item",
    uid: "componentDepChrgOff",
  },
  {
    title: architectRoutes.componentFeeRoute.label,
    childrens: [],
    path: architectRoutes.componentFeeRoute.path,
    type: "item",
    uid: "componentFee",
  },
  {
    title: architectRoutes.componentIntRoute.label,
    childrens: [],
    path: architectRoutes.componentIntRoute.path,
    type: "item",
    uid: "componentInt",
  },
  {
    title: architectRoutes.componentLimitRoute.label,
    childrens: [],
    path: architectRoutes.componentLimitRoute.path,
    type: "item",
    uid: "componentInt",
  },
  {
    title: architectRoutes.componentLnParamsRoute.label,
    childrens: [],
    path: architectRoutes.componentLnParamsRoute.path,
    type: "item",
    uid: "componentLnParams",
  },
  {
    title: architectRoutes.componentNsfRoute.label,
    childrens: [],
    path: architectRoutes.componentNsfRoute.path,
    type: "item",
    uid: "componentNsf",
  },
  {
    title: architectRoutes.componentRepayRoute.label,
    childrens: [],
    path: architectRoutes.componentRepayRoute.path,
    type: "item",
    uid: "componentRepay",
  },
  {
    title: architectRoutes.componentRewardRoute.label,
    childrens: [],
    path: architectRoutes.componentRewardRoute.path,
    type: "item",
    uid: "componentReward",
  },
  {
    title: architectRoutes.componentRulesRoute.label,
    childrens: [],
    path: architectRoutes.componentRulesRoute.path,
    type: "item",
    uid: "componentRules",
  },
  {
    title: architectRoutes.componentTdRoute.label,
    childrens: [],
    path: architectRoutes.componentTdRoute.path,
    type: "item",
    uid: "componentTd",
  },
  {
    title: architectRoutes.componentTrnOptRoute.label,
    childrens: [],
    path: architectRoutes.componentTrnOptRoute.path,
    type: "item",
    uid: "componentTrnOpt",
  },
  {
    title: architectRoutes.trnLimitRoute.label,
    childrens: [],
    path: architectRoutes.trnLimitRoute.path,
    type: "item",
    uid: "trnLimit",
  },
  {
    title: architectRoutes.accumTrnLimitRoute.label,
    childrens: [],
    path: architectRoutes.accumTrnLimitRoute.path,
    type: "item",
    uid: "accumTrnLimit",
  },
  {
    title: architectRoutes.prodProgramRoute.label,
    childrens: [],
    path: architectRoutes.prodProgramRoute.path,
    type: "item",
    uid: "prodProgram",
  },
  {
    title: architectRoutes.prodProgramGroupRoute.label,
    childrens: [],
    path: architectRoutes.prodProgramGroupRoute.path,
    type: "item",
    uid: "prodProgramGroup",
  },
  {
    title: architectRoutes.prodProgramGroupRelRoute.label,
    childrens: [],
    path: architectRoutes.prodProgramGroupRelRoute.path,
    type: "item",
    uid: "prodProgramGroupRel",
  },
  {
    title: architectRoutes.prod_bkLnDelqRoute.label,
    childrens: [],
    path: architectRoutes.prod_bkLnDelqRoute.path,
    type: "item",
    uid: "prod_bkLnDelq",
  },
  {
    title: architectRoutes.prodSvcRoute.label,
    childrens: [],
    path: architectRoutes.prodSvcRoute.path,
    type: "item",
    uid: "prodSvc",
  },
  {
    title: architectRoutes.sweepRoute.label,
    childrens: [],
    path: architectRoutes.sweepRoute.path,
    type: "item",
    uid: "sweep",
  },
];

export const architectGL = {
  title: "General Ledger",
  childrens: [
    {
      title: architectRoutes.acctgSegRoute.label,
      childrens: [],
      path: architectRoutes.acctgSegRoute.path,
      type: "item",
      uid: "acctgSeg",
    },
    {
      title: architectRoutes.acct_glRoute.label,
      childrens: [],
      path: architectRoutes.acct_glRoute.path,
      type: "item",
      uid: "acct_gl",
    },
    {
      title: architectRoutes.posn_glRoute.label,
      childrens: [],
      path: architectRoutes.posn_glRoute.path,
      type: "item",
      uid: "posn_gl",
    },
    {
      title: architectRoutes.glSetRoute.label,
      childrens: [],
      path: architectRoutes.glSetRoute.path,
      type: "item",
      uid: "glSet",
    },
    {
      title: architectRoutes.configSystemAcctRoute.label,
      childrens: [],
      path: architectRoutes.configSystemAcctRoute.path,
      type: "item",
      uid: "configSystemAcct",
    },
  ],
  path: operationsRoutes.diagnosticsDashboardRoute.path,
  type: "item",
  uid: "prod-general-ledger",
};

export const architectBank = {
  title: "Bank",
  childrens: [
    {
      title: architectRoutes.acctGroupRoute.label,
      childrens: [],
      path: architectRoutes.acctGroupRoute.path,
      type: "item",
      uid: "acctGroup",
    },
    {
      title: architectRoutes.acctTypeRoute.label,
      childrens: [],
      path: architectRoutes.acctTypeRoute.path,
      type: "item",
      uid: "acctType",
    },
    {
      title: architectRoutes.glExtractFilterRoute.label,
      childrens: [],
      path: architectRoutes.glExtractFilterRoute.path,
      type: "item",
      uid: "glExtractFilter",
    },
    {
      title: architectRoutes.assetCatalogueRoute.label,
      childrens: [],
      path: architectRoutes.assetCatalogueRoute.path,
      type: "item",
      uid: "assetCatalogue",
    },
    {
      title: architectRoutes.assetClassRoute.label,
      childrens: [],
      path: architectRoutes.assetClassRoute.path,
      type: "item",
      uid: "assetClass",
    },
    {
      title: architectRoutes.calendarRoute.label,
      childrens: [],
      path: architectRoutes.calendarRoute.path,
      type: "item",
      uid: "calendar",
    },
    {
      title: architectRoutes.bankparamRoute.label,
      childrens: [],
      path: architectRoutes.bankparamRoute.path,
      type: "item",
      uid: "bankparam",
    },
    {
      title: architectRoutes.bankparamUSBankInfoRoute.label,
      childrens: [],
      path: architectRoutes.bankparamUSBankInfoRoute.path,
      type: "item",
      uid: "bankparamUSBankInfo",
    },
    {
      title: architectRoutes.ccyCodeRoute.label,
      childrens: [],
      path: architectRoutes.ccyCodeRoute.path,
      type: "item",
      uid: "ccyCode",
    },
    {
      title: architectRoutes.channelRoute.label,
      childrens: [],
      path: architectRoutes.channelRoute.path,
      type: "item",
      uid: "channel",
    },
    {
      title: architectRoutes.chkFilePostInstrRoute.label,
      childrens: [],
      path: architectRoutes.chkFilePostInstrRoute.path,
      type: "item",
      uid: "chkFilePostInstr",
    },
    {
      title: architectRoutes.cntryRoute.label,
      childrens: [],
      path: architectRoutes.cntryRoute.path,
      type: "item",
      uid: "cntry",
    },
    {
      title: architectRoutes.cntrySubdivisionRoute.label,
      childrens: [],
      path: architectRoutes.cntrySubdivisionRoute.path,
      type: "item",
      uid: "cntrySubdivision",
    },
    {
      title: architectRoutes.domainRoute.label,
      childrens: [],
      path: architectRoutes.domainRoute.path,
      type: "item",
      uid: "domain",
    },
    {
      title: architectRoutes.fileFmtRoute.label,
      childrens: [],
      path: architectRoutes.fileFmtRoute.path,
      type: "item",
      uid: "fileFmt",
    },
    {
      title: architectRoutes.freqAndDurMapRoute.label,
      childrens: [],
      path: architectRoutes.freqAndDurMapRoute.path,
      type: "item",
      uid: "freqAndDurMap",
    },
    {
      title: architectRoutes.matrixRoute.label,
      childrens: [],
      path: architectRoutes.matrixRoute.path,
      type: "item",
      uid: "matrix",
    },
    {
      title: architectRoutes.lstseqRoute.label,
      childrens: [],
      path: architectRoutes.lstseqRoute.path,
      type: "item",
      uid: "lstSeq",
    },
    {
      title: architectRoutes.systemCalendarRoute.label,
      childrens: [],
      path: architectRoutes.systemCalendarRoute.path,
      type: "item",
      uid: "systemCalendar",
    },
    {
      title: architectRoutes.custRelTypeRoute.label,
      childrens: [],
      path: architectRoutes.custRelTypeRoute.path,
      type: "item",
      uid: "custRelType",
    },
    {
      title: architectRoutes.partyGroupRoute.label,
      childrens: [],
      path: architectRoutes.partyGroupRoute.path,
      type: "item",
      uid: "partyGroup",
    },
    {
      title: architectRoutes.posnRoleTypeRoute.label,
      childrens: [],
      path: architectRoutes.posnRoleTypeRoute.path,
      type: "item",
      uid: "posnRoleType",
    },
    {
      title: architectRoutes.prod_cardRoute.label,
      childrens: [],
      path: architectRoutes.prod_cardRoute.path,
      type: "item",
      uid: "prod_card",
    },
    {
      title: architectRoutes.partyRoute.label,
      childrens: [],
      path: architectRoutes.partyRoute.path,
      type: "item",
      uid: "party_org_fininst",
    },
    {
      title: architectRoutes.partyUSBankInfoRoute.label,
      childrens: [],
      path: architectRoutes.partyUSBankInfoRoute.path,
      type: "item",
      uid: "partyUSBankInfo",
    },
    {
      title: architectRoutes.merchantGroupRoute.label,
      childrens: [],
      path: architectRoutes.merchantGroupRoute.path,
      type: "item",
      uid: "merchantGroup",
    },
    {
      title: architectRoutes.networkRoute.label,
      childrens: [],
      path: architectRoutes.networkRoute.path,
      type: "item",
      uid: "network",
    },
    {
      title: architectRoutes.riskProfileRoute.label,
      childrens: [],
      path: architectRoutes.riskProfileRoute.path,
      type: "item",
      uid: "riskProfile",
    },
    {
      title: architectRoutes.assetExchRatesRoute.label,
      childrens: [],
      path: architectRoutes.assetExchRatesRoute.path,
      type: "item",
      uid: "assetExchRates",
    },
    {
      title: architectRoutes.promoCodeDtlRoute.label,
      childrens: [],
      path: architectRoutes.promoCodeDtlRoute.path,
      type: "item",
      uid: "promoCodeDtl",
    },
    {
      title: architectRoutes.rateIndexRoute.label,
      childrens: [],
      path: architectRoutes.rateIndexRoute.path,
      type: "item",
      uid: "rateIndex",
    },
    {
      title: architectRoutes.roleRoute.label,
      childrens: [],
      path: architectRoutes.roleRoute.path,
      type: "item",
      uid: "role",
    },
    {
      title: architectRoutes.rolePermissionRoute.label,
      childrens: [],
      path: architectRoutes.rolePermissionRoute.path,
      type: "item",
      uid: "rolePermission",
    },
    {
      title: architectRoutes.feeCalcDtlRoute.label,
      childrens: [],
      path: architectRoutes.feeCalcDtlRoute.path,
      type: "item",
      uid: "feeCalcDtl",
    },
    {
      title: architectRoutes.fundsAvlCatRoute.label,
      childrens: [],
      path: architectRoutes.fundsAvlCatRoute.path,
      type: "item",
      uid: "fundsAvlCat",
    },
    {
      title: architectRoutes.fundsAvlHoldRoute.label,
      childrens: [],
      path: architectRoutes.fundsAvlHoldRoute.path,
      type: "item",
      uid: "fundsAvlHold",
    },
    {
      title: architectRoutes.holdTemplateRoute.label,
      childrens: [],
      path: architectRoutes.holdTemplateRoute.path,
      type: "item",
      uid: "holdTemplate",
    },
    {
      title: architectRoutes.stopRoute.label,
      childrens: [],
      path: architectRoutes.stopRoute.path,
      type: "item",
      uid: "stop",
    },
    {
      title: architectRoutes.orderTemplateRoute.label,
      childrens: [],
      path: architectRoutes.orderTemplateRoute.path,
      type: "item",
      uid: "orderTemplate",
    },
    {
      title: architectRoutes.exceptRuleRoute.label,
      childrens: [],
      path: architectRoutes.exceptRuleRoute.path,
      type: "item",
      uid: "exceptRule",
    },
    {
      title: architectRoutes.restrictRuleRoute.label,
      childrens: [],
      path: architectRoutes.restrictRuleRoute.path,
      type: "item",
      uid: "restrictRule",
    },
    {
      title: architectRoutes.trnCodeRoute.label,
      childrens: [],
      path: architectRoutes.trnCodeRoute.path,
      type: "item",
      uid: "trnCode",
    },
    {
      title: architectRoutes.trnStatGroupRoute.label,
      childrens: [],
      path: architectRoutes.trnStatGroupRoute.path,
      type: "item",
      uid: "trnStatGroup",
    },
  ],
  path: architectRoutes.bankparamRoute.path,
  type: "item",
  uid: "arch-banks",
};

export const schemaExtension: any = [
  {
    title: extensibilityRoutes.schemaRoute.label,
    childrens: [],
    path: extensibilityRoutes.schemaRoute.path,
    type: "item",
    uid: "schema-and-choices#schema",
  },
  {
    title: extensibilityRoutes.choicesRoute.label,
    childrens: [],
    path: extensibilityRoutes.choicesRoute.path,
    type: "item",
    uid: "schema-and-choices#choices",
  },
  {
    title: extensibilityRoutes.dslEditorRoute.label,
    childrens: [],
    path: extensibilityRoutes.dslEditorRoute.path,
    type: "item",
    uid: "editor",
  },
];
export const swagger: any = {
  title: "Swagger",
  childrens: [],
  path: "",
  type: "item",
  uid: "swagger",
  external: true,
};
export const utilities: any = [
  {
    title: architectRoutes.freqModelerRoute.label,
    childrens: [],
    path: architectRoutes.freqModelerRoute.path,
    type: "item",
    uid: "freqModeler",
  },
  {
    title: architectRoutes.emptyCacheRoute.label,
    childrens: [],
    path: architectRoutes.emptyCacheRoute.path,
    type: "item",
    uid: "emptyCache",
  },
];

export const tguid: any = {
  title: "TGUID",
  childrens: [
    {
      title: architectRoutes.tguidEncodeRoute.label,
      childrens: [],
      path: architectRoutes.tguidEncodeRoute.path,
      type: "item",
      uid: "tguidEncode",
    },
    {
      title: architectRoutes.tguidDecodeRoute.label,
      childrens: [],
      path: architectRoutes.tguidDecodeRoute.path,
      type: "item",
      uid: "tguidDecode",
    },
  ],
  path: "",
  type: "item",
  uid: "tguid",
};

export const diagnostics: any = {
  title: "Diagnostics",
  childrens: [
    {
      title: operationsRoutes.diagnosticsDashboardRoute.label,
      childrens: [],
      path: operationsRoutes.diagnosticsDashboardRoute.path,
      type: "item",
      uid: "diagnosticsDashboard",
    },
    {
      title: operationsRoutes.errLogRoute.label,
      childrens: [],
      path: operationsRoutes.errLogRoute.path,
      type: "item",
      uid: "errLog",
    },
    {
      title: operationsRoutes.journalRoute.label,
      childrens: [],
      path: operationsRoutes.journalRoute.path,
      type: "item",
      uid: "journal",
    },
    {
      title: operationsRoutes.etlRoute.label,
      childrens: [],
      path: operationsRoutes.etlRoute.path,
      type: "item",
      uid: "etl",
    },
    {
      title: operationsRoutes.glBalancingRoute.label,
      childrens: [],
      path: operationsRoutes.glBalancingRoute.path,
      type: "item",
      uid: "glBalancing",
    },
    {
      title: operationsRoutes.reconBatchRoute.label,
      childrens: [],
      path: operationsRoutes.reconBatchRoute.path,
      type: "item",
      uid: "reconBatch",
    },
    {
      title: operationsRoutes.reconTrnRoute.label,
      childrens: [],
      path: operationsRoutes.reconTrnRoute.path,
      type: "item",
      uid: "reconTrn",
    },
    {
      title: operationsRoutes.systemCalendarTimelineRoute.label,
      childrens: [],
      path: operationsRoutes.systemCalendarTimelineRoute.path,
      type: "item",
      uid: "systemCalendarTimeline",
    },
    {
      title: operationsRoutes.posnCalEventsRoute.label,
      childrens: [],
      path: operationsRoutes.posnCalEventsRoute.path,
      type: "item",
      uid: "posnCalEvents",
    },
    {
      title: operationsRoutes.eventContextRoute.label,
      childrens: [],
      path: operationsRoutes.eventContextRoute.path,
      type: "item",
      uid: "eventContext",
    },
  ],
  path: "",
  type: "item",
  uid: "diagnostics",
};

export const operations: any = {
  title: "Operations",
  childrens: [
    {
      title: operationsRoutes.achCorporateOriginationRoute.label,
      childrens: [],
      path: operationsRoutes.achCorporateOriginationRoute.path,
      type: "item",
      uid: "achCorporateOrigination",
    },
    {
      title: operationsRoutes.achBatchRoute.label,
      childrens: [],
      path: operationsRoutes.achBatchRoute.path,
      type: "item",
      uid: "achBatch",
    },
    {
      title: operationsRoutes.achBatchExceptionRoute.label,
      childrens: [],
      path: operationsRoutes.achBatchExceptionRoute.path,
      type: "item",
      uid: "achBatchException",
    },
    {
      title: operationsRoutes.achOriginInstructionRoute.label,
      childrens: [],
      path: operationsRoutes.achOriginInstructionRoute.path,
      type: "item",
      uid: "achOriginInstr",
    },
    {
      title: operationsRoutes.batchInformationRoute.label,
      childrens: [],
      path: operationsRoutes.batchInformationRoute.path,
      type: "item",
      uid: "batchTransaction",
    },
    {
      title: operationsRoutes.achRoute.label,
      childrens: [],
      path: operationsRoutes.achRoute.path,
      type: "item",
      uid: "ach",
    },
    {
      title: operationsRoutes.checkRoute.label,
      childrens: [],
      path: operationsRoutes.checkRoute.path,
      type: "item",
      uid: "check",
    },
    {
      title: operationsRoutes.batchTransactionRoute.label,
      childrens: [],
      path: operationsRoutes.batchTransactionRoute.path,
      type: "item",
      uid: "batchTransaction",
    },
    {
      title: operationsRoutes.orderACHRoute.label,
      childrens: [],
      path: operationsRoutes.orderACHRoute.path,
      type: "item",
      uid: "orderACH",
    },
    {
      title: operationsRoutes.orderCORERoute.label,
      childrens: [],
      path: operationsRoutes.orderCORERoute.path,
      type: "item",
      uid: "orderCORE",
    },
    {
      title: operationsRoutes.orderFEDWIRERoute.label,
      childrens: [],
      path: operationsRoutes.orderFEDWIRERoute.path,
      type: "item",
      uid: "orderFEDWIRE",
    },
    {
      title: operationsRoutes.reportsRoute.label,
      childrens: [],
      path: operationsRoutes.reportsRoute.path,
      type: "item",
      uid: "reports",
    },
    {
      title: operationsRoutes.glExtractsRoute.label,
      childrens: [],
      path: operationsRoutes.glExtractsRoute.path,
      type: "item",
      uid: "glExtracts",
    },
    {
      title: operationsRoutes.escheatExtractsRoute.label,
      childrens: [],
      path: operationsRoutes.escheatExtractsRoute.path,
      type: "item",
      uid: "escheatExtracts",
    },
    {
      title: operationsRoutes.fidmProcessingRoute.label,
      childrens: [],
      path: operationsRoutes.fidmProcessingRoute.path,
      type: "item",
      uid: "fidm",
    },
    {
      title: operationsRoutes.openExceptionsExtractsRoute.label,
      childrens: [],
      path: operationsRoutes.openExceptionsExtractsRoute.path,
      type: "item",
      uid: "openExceptionsExtracts",
    },
    {
      title: operationsRoutes.taxFilesAndExtractsRoute.label,
      childrens: [],
      path: operationsRoutes.taxFilesAndExtractsRoute.path,
      type: "item",
      uid: "taxFilesAndExtracts",
    },
    {
      title: operationsRoutes.achFedReversalRoute.label,
      childrens: [],
      path: operationsRoutes.achFedReversalRoute.path,
      type: "item",
      uid: "achFedReversal",
    },
    {
      title: operationsRoutes.fdic370Route.label,
      childrens: [],
      path: operationsRoutes.fdic370Route.path,
      type: "item",
      uid: "fdic370",
    },
  ],
  path: "",
  type: "item",
  uid: "operations",
};

export const implementation: any = {
  title: "Implementation",
  childrens: [
    {
      title: extensibilityRoutes.extDashboardRoute.label,
      childrens: [],
      path: extensibilityRoutes.extDashboardRoute.path,
      type: "item",
      uid: "extensions/dashboard",
    },
    {
      title: extensibilityRoutes.projectListRoute.label,
      childrens: [],
      path: extensibilityRoutes.projectListRoute.path,
      type: "item",
      uid: "extensions/project",
    },
    {
      title: extensibilityRoutes.buildsListRoute.label,
      childrens: [],
      path: extensibilityRoutes.buildsListRoute.path,
      type: "item",
      uid: "extensions/build",
    },
    {
      title: extensibilityRoutes.deploymentsListRoute.label,
      childrens: [],
      path: extensibilityRoutes.deploymentsListRoute.path,
      type: "item",
      uid: "extensions/deployments",
    },
    {
      title: extensibilityRoutes.timeshiftsListRoute.label,
      childrens: [],
      path: extensibilityRoutes.timeshiftsListRoute.path,
      type: "item",
      uid: "extensions/timeshifts",
    },
    {
      title: extensibilityRoutes.environmentHealthRoute.label,
      childrens: [],
      path: extensibilityRoutes.environmentHealthRoute.path,
      type: "item",
      uid: "environmentHealth",
    },
    {
      title: extensibilityRoutes.ruleLogRoute.label,
      childrens: [],
      path: extensibilityRoutes.ruleLogRoute.path,
      type: "item",
      uid: "extensions/rulelog",
    },
  ],
  path: "",
  type: "item",
  uid: "implementations",
};

export const resources: any = {
  title: "Resources",
  childrens: [
    {
      title: "Resources Dashboard",
      childrens: [],
      path: dashboardRoute.path,
      type: "item",
      uid: "",
    },
  ],
  path: "",
  type: "item",
  uid: "resources",
};
export const resourcesImplSiteUrl: any = {
  title: "Implementation Site",
  childrens: [],
  path: "",
  type: "item",
  uid: "implementation-site",
};
export const swaggerCoreRoute: any = {
  title: educationRoutes.swaggerCoreRoute.label,
  childrens: [],
  path: educationRoutes.swaggerCoreRoute.path,
  type: "item",
  uid: "swagger-route",
};
export const communications: any = [
  {
    title: educationRoutes.sampleAPIsRoute.label,
    childrens: [],
    path: educationRoutes.sampleAPIsRoute.path,
    type: "item",
    uid: "sampleAPIs",
  },
  {
    title: educationRoutes.schemaOverviewRoute.label,
    childrens: [],
    path: educationRoutes.schemaOverviewRoute.path,
    type: "item",
    uid: "schema/overview",
  },
  {
    title: educationRoutes.schemaAndChoicesRoute.label,
    childrens: [],
    path: educationRoutes.schemaAndChoicesRoute.path,
    type: "item",
    uid: "schema/and-choices",
  },
  {
    title: educationRoutes.releaseRoute.label,
    childrens: [],
    path: educationRoutes.releaseRoute.path,
    type: "item",
    uid: "release",
  },
  {
    title: educationRoutes.releaseCalendarRoute.label,
    childrens: [],
    path: educationRoutes.releaseCalendarRoute.path,
    type: "item",
    uid: "releaseCalendar",
  },
  {
    title: educationRoutes.newsRoute.label,
    childrens: [],
    path: educationRoutes.newsRoute.path,
    type: "item",
    uid: "news",
  },
];

export const archResources: any = [
  {
    title: "Archived Learning Portal",
    childrens: [],
    path: { externalDocsPath },
    type: "item",
    uid: "archived-learning-portal",
  },
  {
    title: "Archived Training",
    childrens: [],
    path: `${externalDocsPath}/training`,
    type: "item",
    uid: "archived-training",
  },
  {
    title: "Archived Documentation",
    childrens: [],
    path: `${externalDocsPath}/documentation`,
    type: "item",
    uid: "documentation",
  },
  {
    title: "Archived Tutorials",
    childrens: [],
    path: `${externalDocsPath}/tutorials`,
    type: "item",
    uid: "tutorials",
  },
  {
    title: "Archived Learning Portal",
    childrens: [],
    path: { externalDocsPath },
    type: "item",
    uid: "learning-portal",
  },
  {
    title: `Archived ${educationRoutes.documentationRoute}`,
    childrens: [],
    path: `${educationRoutes.documentationRoute.path}`,
    type: "item",
    uid: "documentation",
  },
];
export const global: any = {
  title: "Global",
  childrens: [
    {
      title: `${extensibilityRoutes.environmentHealthRoute.label}`,
      childrens: [],
      path: `${extensibilityRoutes.environmentHealthRoute.path}`,
      type: "item",
      uid: "environmentHealth",
    },
  ],
  path: "",
  type: "item",
  uid: "global",
};
export const coreServicing: any = {
  title: "Core Servicing",
  childrens: [],
  path: "",
  type: "item",
  uid: "coreServicing",
};
export const reqService: any = {
  title: "Request Service",
  childrens: [],
  path: "",
  type: "item",
  uid: "reqService",
};
