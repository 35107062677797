import React from 'react'
import clsn from 'classnames'
import { useContext } from 'react'
import { NavContext } from './Nav'
import s from './Nav.module.scss'
import { useSubRoute } from '../../hooks/common'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons'
type Props = {
  id?: string
  key?: string
  fast?: true
  link: string | undefined
  label: string
  icon?: IconDefinition
  external?: true
  target?: string
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
  fromNavGroup?: boolean
  tier: 1 | 2 | 3 | 4
  highlightNavGroup?: boolean
  closeOtherGroupsOnClick?: boolean
}

/**
 * NavItem is an individual link within the Nav.  A NavItem may be route based (internal
 * to the application) or an external link.
 * @param props
 * @returns
 */
export default function NavItem(props: Props) {
  const { currPath, subRoute } = useSubRoute()

  const context = useContext(NavContext)
  const {
    navState,
    active: act,
    parent,
    reactRouterLinkComponent,
    nextRouterLinkComponent,
    dispatch,
  } = context

  const {
    fast,
    icon,
    external,
    onClick,
    fromNavGroup,
    highlightNavGroup,
    closeOtherGroupsOnClick = false,
  } = props

  let tierStyle = ''
  switch (props.tier) {
    case 1:
      tierStyle = s.tier1
      break
    case 2:
      tierStyle = s.tier2
      break
    case 3:
      tierStyle = s.tier3
      break
    case 4:
      tierStyle = s.tier4
      break
  }

  const NextLink = nextRouterLinkComponent
  const ReactLink = reactRouterLinkComponent

  const text = <div className='text-truncate'>{props.label}</div>
  const active =
    currPath.includes('/docs') || currPath.includes('/apis')
      ? subRoute === props.id
      : act === props.id

  const highlight = active && !navState.openGroups[props.tier - 1]

  const router = (() => {
    if (external) return 'none'
    if (NextLink && fast && parent === 'fast') return 'next'
    if (ReactLink && !fast && parent === 'cnsl') return 'react'
    return 'none'
  })()

  function onNavItemClick(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    if (closeOtherGroupsOnClick) {
      dispatch({
        type: 'CLOSE_ALL_GROUPS',
      })
    }
    onClick?.(e)
  }

  const route = (
    <>
      {icon && <FontAwesomeIcon icon={icon} className={s.icon} />}
      {text}
    </>
  )

  switch (router) {
    case 'none':
      return (
        <a
          id={props.id}
          className={clsn(
            fromNavGroup ? s.navGroupItem : s.navItem,
            tierStyle,
            active ? s.active : null,
            fromNavGroup
              ? highlightNavGroup
                ? s.highlight
                : null
              : highlight && !active
                ? s.highlight
                : null
          )}
          href={props.link || undefined}
          target={props.target ?? undefined}
          title={props.label}
          onClick={onNavItemClick}
        >
          {route}
        </a>
      )
    case 'react':
      return (
        <ReactLink
          id={props.id}
          className={clsn(
            fromNavGroup ? s.navGroupItem : s.navItem,
            tierStyle,
            active ? s.active : null,
            fromNavGroup
              ? highlightNavGroup
                ? s.highlight
                : null
              : highlight && !active
                ? s.highlight
                : null
          )}
          data-robots={props.link}
          to={props.link}
          onClick={onNavItemClick}
        >
          {route}
        </ReactLink>
      )
    case 'next':
      return (
        <NextLink
          id={props.id}
          className={clsn(
            fromNavGroup ? s.navGroupItem : s.navItem,
            tierStyle,
            active ? s.active : null,
            fromNavGroup
              ? highlightNavGroup
                ? s.highlight
                : null
              : highlight && !active
                ? s.highlight
                : null
          )}
          data-robots={props.link}
          href={props?.link ?? ''}
          onClick={onNavItemClick}
        >
          {route}
        </NextLink>
      )
  }
}
