import React from 'react'
// import { faStar as faStarSolid } from '@fortawesome/pro-regular-svg-icons'
import { faCheck } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsn from 'classnames'
import s from './OrgRow.module.scss'
import { Organization } from './OrgEnvDropdown'
import { faStar } from '@fortawesome/free-solid-svg-icons'

type Props = {
  onClick?: (orgId: number) => void
  onClickFavOrg?: (orgId: number, isFav: boolean) => void
  isFav: boolean
  org: Organization
  isGrouped?: boolean
  selected?: true
}

export default function OrgRow(props: Props) {
  const { onClick, onClickFavOrg, isFav, isGrouped, org, selected } = props

  return (
    <div
      className={clsn({
        [s.orgContainer]: true,
        [s.selected]: !isGrouped && selected,
      })}
      onClick={() => {
        return org?.id && onClick && onClick(org.id)
      }}
    >
      <div className={s.org}>
        <span className={s.orgSelected}>
          {selected ? <FontAwesomeIcon icon={faCheck} /> : null}
        </span>
        <span className={clsn(s.orgName, selected ? s.selected : null)}>
          {org.name}
        </span>
        <span className={s.orgFavorite}>
          <FontAwesomeIcon
            className={isFav ? s.orgFavoriteYes : s.orgFavoriteNo}
            icon={faStar}
            onClick={(e) => {
              e.stopPropagation()
              return org?.id && onClickFavOrg && onClickFavOrg(org.id, isFav)
            }}
          />
        </span>
      </div>
    </div>
  )
}
